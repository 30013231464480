import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking } from 'react-native';
import { connect } from 'react-redux';
import { Touchable, Theme } from 'ui-m/rn';
import { Hoverable } from 'react-native-web-hover';
import { Breadcrumb, Layout, Menu, Space as AntSpace, Dropdown } from 'antd';
const { Header, Content, Sider, Footer: AntFooter } = Layout;

const Footer = connect((store) => _.pick(store, [
  'i18n',
]))((props) => {
  return (
    <AntFooter style={{ textAlign: 'center', height: 47, paddingTop: 15, paddingBottom: 15, backgroundColor: "transparent" }}>
      <Hoverable>
        {({ hovered }) => (
          <Touchable onPress={() => { Linking.openURL('https://mc.app/?from=supplierSalesDashboard'); }}>
            <Text style={{ color: '#999', fontStyle: 'italic', fontSize: 12, lineHeight: 17, textDecorationLine: hovered ? 'underline' : undefined }}>
              {props.i18n.map.powered_by_mc_app}
            </Text>
          </Touchable>
        )}
      </Hoverable>
    </AntFooter>
  );
});

Footer.height = 47;

export default Footer;
