import React from 'react';
import _ from 'lodash';
import { StyleSheet, View, Text, ScrollView, Image } from 'react-native';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import QRCode from 'qrcode.react';
import { BasePage, DashboardNavigationPage, ContactUs, LightBox } from '../../components';
import { Space, Panel, Touchable, Button, WindowSizeResponsive, Theme } from 'ui-m/rn';
import { createAction, mcRequest, request, copyString, openMC } from '../../utils';
import resources from '../../config/resources';
import { LoadingOutlined, ReloadOutlined } from '../../config/antdIcons';
import { Spin } from 'antd';
const { Content } = Layout;

class Page extends BasePage {

  static defaultProps = {
    ...BasePage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map['cmn.login'];
    },
  }

  state = {
    mcLoginQrCodeInfo: {
      // loading: false,
      // errorMessage: ''
    },
    selectedPlatform: "mobile", // mobile | desktop
  }

  get qrCodeActive() {
    return this.state.mcLoginQrCodeInfo.qrCodeUrl && !this.qrCodeExpired;
  }

  get qrCodeExpired() {
    return this.state.mcLoginQrCodeInfo.expiredTime && this.state.mcLoginQrCodeInfo.expiredTime <= request.getServerTime();
  }

  componentDidMount() {
    super.componentDidMount.apply(this, arguments);
    this.refreshQrCodeInfo();
    const debouncedRefreshQrCode = _.debounce(() => {
      if (!this.state.mcLoginQrCodeInfo.loading && !this.qrCodeActive) {
        this.refreshQrCodeInfo();
      }
    }, 100);
    this._windowResizeHandler = WindowSizeResponsive.addListener(debouncedRefreshQrCode);
    // 定期检查二维码过期（强刷触发渲染就行）
    this.loopCheckQrCodeExpired();
  }

  componentWillUnmount() {
    super.componentWillUnmount.apply(this, arguments);
    clearTimeout(this._loopCheckLoggedInTimeout);
    WindowSizeResponsive.removeListener(this._windowResizeHandler);
    // 取消定期检查二维码过期
    clearTimeout(this._loopCheckQrCodeExpiredTimeout);
  }

  refreshQrCodeInfo = async () => {
    // 只在非手机屏幕上展示扫码登录流程
    if (Theme.isPhoneSizedWindow) {
      return;
    }
    this.setQRCodeLoading(true);
    const { i18n } = this.props;
    const res = await request('/dashboards/login-qr-code');
    this.setQRCodeLoading(false);
    if (res.success) {
      this.setState({
        mcLoginQrCodeInfo: res.data || {},
      });
      this.loopCheckLoggedIn();
    }
    else {
      this.setState({
        mcLoginQrCodeInfo: {
          ...this.state.mcLoginQrCodeInfo,
          errorMessage: res.errorMessage || i18n.map.qr_code_failed_lo_34,
        },
      });
    }
  }

  loopCheckQrCodeExpired = () => {
    clearTimeout(this._loopCheckQrCodeExpiredTimeout);
    this._loopCheckQrCodeExpiredTimeout = setTimeout(() => {
      if (!Theme.isPhoneSizedWindow && !this.state.mcLoginQrCodeInfo.loading && !this.qrCodeActive) {
        this.forceUpdate();
      }
      this.loopCheckQrCodeExpired();
    }, 1000);
  }

  loopCheckLoggedIn = async () => {
    clearTimeout(this._loopCheckLoggedInTimeout);
    this._loopCheckLoggedInTimeout = setTimeout(async () => {
      let res;
      if (this.qrCodeActive) {
        res = await mcRequest(this.state.mcLoginQrCodeInfo.fetchWebTokenUrl?.replace(/^http\:\/\//, 'https://'), {
          method: 'POST',
        });
        if (res.success) {
          this.setQRCodeLoading(true);
          res = await this.props.dispatch(createAction('auth/loginViaMcQrCode')({
            mcKey: res.data.token,
          }));
          if (res.success) {
            // 由 intention model 处理登录后的下一步动作
          }
          else {
            this.setQRCodeLoading(false);
            this.setState({
              mcLoginQrCodeInfo: {
                ...this.state.mcLoginQrCodeInfo,
                errorMessage: res.errorMessage || this.props.i18n.map.failed_to_log_in__33,
              },
            });
          }
        }
        else {
          this.loopCheckLoggedIn();
        }
      }
      else {
        this.setQRCodeExpired();
      }
    }, 3000);
  }

  setQRCodeLoading = (loading) => {
    this.setState({
      mcLoginQrCodeInfo: {
        ...this.state.mcLoginQrCodeInfo,
        loading,
        ...(loading ? { errorMessage: undefined } : {}),
      },
    });
  }

  setQRCodeExpired = () => {
    this.setState({
      mcLoginQrCodeInfo: {
        ...this.state.mcLoginQrCodeInfo,
        loading: false,
        errorMessage: this.props.i18n.map.qr_code_is_expire_35,
      },
    });
  }

  renderQRBox = () => {
    if (Theme.isPhoneSizedWindow) {
      return null;
    }
    const qrCodeSize = 220;
    const { mcLoginQrCodeInfo } = this.state;
    return (
      <View style={{ height: qrCodeSize, width: qrCodeSize, lineHeight: qrCodeSize }}>
        <Spin
          size="large" indicator={mcLoginQrCodeInfo.loading ? <LoadingOutlined spin /> : null}
          spinning={!!mcLoginQrCodeInfo.loading}
        >
          <View style={{ height: qrCodeSize, width: qrCodeSize }}>
            {mcLoginQrCodeInfo.qrCodeUrl && <QRCode value={mcLoginQrCodeInfo.qrCodeUrl} size={qrCodeSize} style={{ opacity: 0.8 }} />}
          </View>
        </Spin>
        {
          !mcLoginQrCodeInfo.loading && (!this.qrCodeActive || mcLoginQrCodeInfo.errorMessage) ? (
            <Touchable onPress={this.refreshQrCodeInfo} style={{
              height: qrCodeSize, width: qrCodeSize, lineHeight: qrCodeSize,
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              position: 'absolute', left: 0, top: 0, background: 'rgba(255, 255, 255, 0.9)',
            }}>
              <ReloadOutlined style={{ fontSize: 26, color: '#0079FA' }} />
              {mcLoginQrCodeInfo.errorMessage ? (
                <Text style={{
                  color: '#333', fontSize: 14, lineHeight: 16, fontWeight: 500, textAlign: 'center',
                  padding: 10,
                }}>
                  {mcLoginQrCodeInfo.errorMessage}
                </Text>
              ) : null}
            </Touchable>
          ) : null
        }
      </View>
    );
  }

  viewInMC = () => {
    const launchParam = window.location.search;
    openMC({ launchParam });
  }

  downloadMC = () => {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
      window.open("https://apps.apple.com/us/app/mc-boss-stock-management/id1425786386");
    } else {
      window.open("https://d.mc.app");
    }
  }

  renderPage = () => {
    const { selectedPlatform } = this.state;
    const isPc = !Theme.isPhoneSizedWindow;
    const headerHeight = isPc ? 70 : 60;
    const i18n = this.props.i18n;
    const shortUrl = this.props.intention?.URLParams?.shortUrl;
    const copyLinkText = shortUrl ? `https://v2.microstore.app/s/${shortUrl}` : window.location.href;

    return (
      <Layout>
        <Space scene="statusBar" style={{ backgroundColor: '#1E2023' }} />
        <DashboardNavigationPage.Header height={headerHeight} center={isPc} />
        <Content style={{ display: 'flex' }} >
          <ScrollView>
            <DashboardNavigationPage.PageContent
              contentStyle={Theme.isPhoneSizedWindow && { backgroundColor: "#fff" }}
              style={{ justifyContent: "center" }}
              siblingsHeightTotal={headerHeight}
              forceDisplayFooter
            >
              <Panel style={[styles.wrap, isPc && styles.pcWrap]}>
                {
                  isPc && (
                    <View style={{ alignItems: "center" }}>
                      {this.renderQRBox()}
                      <View style={[styles.row, { marginTop: 9, paddingHorizontal: 40, alignItems: "center", marginBottom: -15 }]}>
                        <Image style={{ width: 66, height: 54 }} source={resources.mcAndSeller} />
                        <Text style={[styles.title, styles.pcTitle, { paddingHorizontal: 0, marginTop: 3, textAlign: "left", lineHeight: 20 }]}>{i18n.map["stream.login.byMC.scan"]}</Text>
                      </View>
                    </View>
                  )
                }
                {
                  !isPc && (
                    <View style={{ alignItems: "stretch", paddingHorizontal: 15 }}>
                      <View style={styles.platformWrap}>
                        <Touchable
                          style={[styles.platformItem, selectedPlatform === "mobile" && styles.platformItemActive]}
                          onPress={() => this.setState({ selectedPlatform: "mobile" })}
                        >
                          <Image source={resources.loginPhone} style={[styles.platformIcon, { width: 11, height: 18 }]} />
                          <Text style={styles.platformName}>{i18n.map.phone}</Text>
                        </Touchable>
                        <Touchable
                          style={[styles.platformItem, selectedPlatform === "desktop" && styles.platformItemActive]}
                          onPress={() => this.setState({ selectedPlatform: "desktop" })}
                        >
                          <Image source={resources.loginDesktop} style={[styles.platformIcon, { width: 18, height: 16 }]} />
                          <Text style={styles.platformName}>{i18n.map.desktop}</Text>
                        </Touchable>
                      </View>
                      {
                        selectedPlatform === "mobile" && (
                          <>
                            <Image style={{ width: 60, height: 60, alignSelf: "center", marginBottom: 20 }} source={{ uri: resources.mc }} />
                            <Text style={styles.title}>{i18n.map.click_to_access_t_44}</Text>
                            <Text style={[styles.desc, { marginBottom: 40 }]}>{i18n.map["stream.dashboardViewer.sendActivation.link"].replace("%s", "")}</Text>
                            <Button style={{ backgroundColor: "#000" }} primary title={i18n.map.open_with_mc_boss_21} onPress={this.viewInMC} />
                            <Touchable onPress={this.downloadMC} style={{ marginTop: 13 }} >
                              <Text style={[styles.linkBase, isPc ? styles.pcLink : styles.mobileLink]}>{i18n.map.not_installed_yet_35}</Text>
                            </Touchable>
                          </>
                        )
                      }
                      {
                        selectedPlatform === "desktop" && (
                          <>
                            <Image style={{ width: 100, height: 100, alignSelf: "center" }} source={{ uri: resources.pc }} />
                            <Text style={styles.title}>{i18n.map["stream.dashboardLogin.copyLink.title"]}</Text>
                            <Text style={styles.desc}>{i18n.map["stream.dashboardViewer.sendActivation.link"].replace("%s", "")}</Text>
                            <Text style={[styles.linkBase, { marginBottom: 40, textAlign: "left" }]}>{copyLinkText}</Text>
                            <Button primary title={i18n.map["stream.dashboardLogin.copyLink.button"]} onPress={() => copyString(copyLinkText)} />
                          </>
                        )
                      }
                    </View>
                  )
                }

                <View style={[styles.separate, isPc && styles.pcSeparate]}>
                  <View style={[styles.separateLine, isPc && styles.pcSeparateLine]} />
                  <Text style={[styles.separateText, isPc && styles.pcSeparateText]}>{i18n.map["cmn.or"]}</Text>
                  <View style={[styles.separateLine, isPc && styles.pcSeparateLine]} />
                </View>

                <Touchable style={{ marginBottom: isPc ? 16 : 10 }} onPress={ContactUs.show}>
                  <Text style={[styles.linkBase, isPc ? styles.pcLink : styles.mobileLink]}>{i18n.map["stream.noMCAccount"]} {i18n.map["stream.contactUs.signUp"]}</Text>
                </Touchable>
                <Touchable style={[styles.row]} onPress={LightBox.previewDashboard} hitSlop={{ top: 5, bottom: 5 }}>
                  <Image style={[styles.linkIcon, isPc && styles.pcLinkIcon]} source={{ uri: resources.eyeBlue }} />
                  <Text style={[styles.linkBase, isPc ? styles.pcLink : styles.mobileLink, { paddingHorizontal: 0 }]}>{i18n.map["stream.previewFeature"]}</Text>
                </Touchable>

              </Panel>
            </DashboardNavigationPage.PageContent>
          </ScrollView>
        </Content>
      </Layout>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    alignItems: "stretch",
    paddingBottom: 45,
    paddingHorizontal: 0,
    paddingTop: 10,
  },
  pcWrap: {
    alignSelf: "center",
    width: 480,
    borderRadius: 14,
    paddingTop: 52,
    paddingBottom: 52,
    marginBottom: 120, // 上移60
  },

  title: {
    fontSize: 15,
    lineHeight: 18,
    color: "#333",
    fontWeight: "bold",
    paddingHorizontal: 20,
    textAlign: "center",
  },
  pcTitle: {
    lineHeight: 21,
  },
  desc: {
    fontSize: 13,
    lineHeight: 17,
    color: "#666",
    paddingHorizontal: 20,
    marginTop: 8,
    marginBottom: 10,
  },
  linkBase: {
    flexShrink: 1,
    fontSize: 14,
    lineHeight: 16,
    textAlign: "center",
    color: "#0076FF",
    paddingHorizontal: 20,
  },
  mobileLink: {
    lineHeight: 17,
  },
  pcLink: {
    fontSize: 15,
    lineHeight: 18,
  },

  separate: {
    marginTop: 30,
    marginBottom: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  pcSeparate: {
    marginTop: 40,
    marginBottom: 40,
  },
  separateText: {
    fontSize: 13,
    lineHeight: 17,
    color: "#999",
    marginHorizontal: 12.5,
  },
  pcSeparateText: {
    fontSize: 15,
    marginHorizontal: 14.5,
  },
  separateLine: {
    width: 152,
    height: 0.8,
    backgroundColor: "#B8B8B8",
  },
  pcSeparateLine: {
    width: 177,
  },

  row: {
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 20,
  },

  linkIcon: {
    flexShrink: 0,
    marginTop: 1,
    width: 16,
    height: 16,
    marginRight: 4,
  },
  pcLinkIcon: {
    marginTop: 0,
    width: 18,
    height: 18,
  },
  platformWrap: {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 6,
    borderWidth: 2,
    borderColor: "transparent",
    flexDirection: "row",
    alignItems: "stretch",
    marginBottom: 30,
  },
  platformItem: {
    width: "100%",
    flex: 1,
    borderRadius: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  platformItemActive: {
    backgroundColor: "#fff",
    shadowColor: "rgba(0,0,0,0.2)",
    shadowRadius: 2,
  },
  platformIcon: {
    marginRight: 8,
  },
  platformName: {
    fontSize: 15,
    lineHeight: 21,
    fontWeight: "500",
    color: "#000",
    opacity: 0.8,
    paddingVertical: 7.5,
  },
});


export default connect((store) => _.pick(store, [
  'i18n',
  'app',
  'intention',
]))(Page);
