import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking } from 'react-native';
import { connect } from 'react-redux';
import { Touchable, Theme } from 'ui-m/rn';
import { Hoverable } from 'react-native-web-hover';
import { Breadcrumb, Layout, Menu, Space as AntSpace, Dropdown } from 'antd';
const { Header, Content, Sider, Footer } = Layout;
import LogoBar from './LogoBar';
import SwitchLang from './SwitchLang';
import LoggedInAccount from './LoggedInAccount';

export default connect((store) => _.pick(store, [
  'i18n',
]))((props) => {
  const isPc = !Theme.isPhoneSizedWindow;
  const {
    height = isPc ? 70 : 60,
    mode = 'default', // 'default' | 'loggedIn'
    center = false,
  } = props;
  return (
    <Header className="header" style={{
      background: '#1E2023',
      height: height + 'px', lineHeight: height + 'px',
      paddingLeft: isPc ? 24 : 15,
      paddingRight: isPc ? 24 : 15,
    }}>
      {/* <View style={{
        flexDirection: 'row', alignItems: 'center',
        justifyContent: mode === 'loggedIn' ? 'space-between' : 'center',
        height: height + 'px', lineHeight: height + 'px',
      }}>
        <LogoBar/>
        {
          mode === 'loggedIn' ? (
            <AntSpace size={42}>
              <LoggedInAccount height={height}/>
              <SwitchLang height={height} />
            </AntSpace>
          ) : null
        }
      </View> */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: center ? "center" : "space-between",
          height: height + 'px', lineHeight: height + 'px',
        }}
      >
        <View style={{ flex: 1, marginVertical: 11, marginRight: 10 }}>
          <LogoBar />
        </View>
        {!center && (
          <AntSpace size={isPc ? 42 : 20}>
            {mode === "loggedIn" && <LoggedInAccount height={height} />}
            <SwitchLang height={height} style={{ paddingRight: isPc ? 10 : 0 }} />
          </AntSpace>
        )}
      </View>
      {center && (
        <SwitchLang
          height={height}
          style={{
            position: "absolute",
            top: 0,
            right: isPc ? 34 : 15,
          }}
        />
      )}
    </Header>
  );
});
