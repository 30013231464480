import { Clipboard } from 'react-native';
import { Toast, Dialog } from '../components';
import { getState } from "./globalUtils";

export const copyString = async string => {
  if (await Clipboard.isAvailable()) {
    await Clipboard.setString(string);
    const { i18n } = getState();
    Toast.success(i18n.map["cmn.toast.copy_success"]);
  }
};

export const copyDialogConfirm = (options = {}) => {
  const { i18n } = getState();
  const title = options.title;
  const content = options.content;
  const copyContent = options.copyContent || options.content;
  Dialog.show({
    title,
    content,
    leftBtnText: i18n.map["cmn.btn.cancel"],
    rightBtnText: i18n.map["cmn.btn.copy"],
    onLeftClick: () => { },
    onRightClick: () => copyString(copyContent),
    ...options,
  });
};

// 箭头函数可用
export const singleThreadAsyncFunction = fn => {
  let isRuning = false;

  return async (...rest) => {
    if (isRuning) {
      return;
    }
    isRuning = true;
    typeof fn === "function" && await fn(...rest);
    isRuning = false;
  };
};

export const createSharedPromise = () => {
  let _resolve, _reject;
  const promise = new Promise((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });
  return [promise, _resolve, _reject];
};

export const createAsyncQueue = () => {
  let queue = [];
  let current = null;

  const run = async () => {
    if (current || !queue.length) {
      return;
    }

    current = queue[0];
    queue.splice(0, 1);
    try {
      const res = await current.fn();
      current._resolve(res);
    } catch (error) {
      current._reject(error);
    }
    current = null;
    run();
  };

  const push = (fn, id = `${Date.now()}_${Math.random()}`) => {
    const [promise, _resolve, _reject] = createSharedPromise();
    queue = queue.reduce((_queue, i) => {
      i.id === id ? i._resolve() : _queue.push(i);
      return _queue;
    }, []);
    queue.push({ id, fn, promise, _resolve, _reject });
    run();
    return promise;
  };

  const unshift = (fn, id = `${Date.now()}_${Math.random()}`) => {
    const [promise, _resolve, _reject] = createSharedPromise();
    queue = queue.reduce((_queue, i) => {
      i.id === id ? i._resolve() : _queue.push(i);
      return _queue;
    }, []);
    queue.unshift({ id, fn, promise, _resolve, _reject });
    run();
    return promise;
  };

  const setPriority = (id, priority = 0) => {
    priority = +priority;
    const index = queue.findIndex(i => i.id === id);
    if (index === -1 || !(priority >= 0)) {
      return;
    }

    const item = queue[index];
    queue.splice(index, 1);
    if (priority > queue.length) {
      queue.push(item);
    } else {
      queue.splice(priority, 0, item);
    }
  };

  const clear = () => {
    queue.map(i => i._resolve());
    queue = [];
    current?._resolve?.();
    current = null;
  };

  return ({
    push,
    unshift,
    setPriority,
    clear,
  });
};
