import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { StyleSheet, View, FlatList } from 'react-native';
import { NavigationPage, NavigationBar, NetImage, SkeletonBlockSupplier, Empty } from '../../components';
import { Cell, Space } from 'ui-m/rn';
import { navigate } from '../../utils';
import { callNativeAsyncFunc, NativeFuncs } from '../../utils/mcAppJsSDK';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map.third_party_sales_25;
    },
    primary: true,
    renderNavigationLeftView() {
      return (
        <NavigationBar.Button
          icon="close"
          onPress={() => callNativeAsyncFunc(NativeFuncs.SYSTEM.CLOSE)} />
      );
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      salesChannels: null,
    };
  }

  componentDidMount() {
    this.pageFocusUnsubscribe = this.props.navigation.addListener('focus', this.pageWillFocus);
  }

  componentWillUnmount() {
    this.pageFocusUnsubscribe?.();
  }

  pageWillFocus = () => {
    this.updateSalesChannels();
  }

  updateSalesChannels = async () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    const res = await this.props.dispatch({ type: "salesChannel/getSalesChannels" });
    this.setState({
      isLoading: false,
      salesChannels: res.success ? res.data.salesChannels : [],
    });
  };

  handleEditChannel = channel => {
    const { entity } = channel;
    if (
      entity === "MS-Marketplace-API-Beta(TEST)" ||
      entity === "MS-Marketplace-API-Beta" ||
      entity === "Efashion"
    ) {
      navigate("SalesChannelEfashion", { salesChannel: channel });
    }
  }

  renderItem = ({ item, index }) => {
    const isLast = index === this.state.salesChannels?.length - 1;

    return (
      <Cell
        icon={(
          <NetImage
            style={styles.icon}
            url={NetImage.crop(item.smallLogo, 50)}
          />
        )}
        title={item.name}
        onPress={() => this.handleEditChannel(item)}
        showArrow
        underline={!isLast}
      />
    );
  }

  renderPage() {
    const i18n = this.props.i18n;
    const { isLoading, salesChannels } = this.state;

    return (
      <View style={styles.container}>
        {
          (isLoading || !salesChannels) ? (<SkeletonBlockSupplier />) : (
            <FlatList
              style={styles.container}
              contentContainerStyle={styles.contentContainer}
              data={salesChannels}
              renderItem={this.renderItem}
              ListEmptyComponent={(
                <View style={styles.emptyWrap}>
                  <Empty
                    containerStyle={styles.emptyContainer}
                    text={i18n.map.no_enabled_third__37}
                  />
                </View>
              )}
              ListHeaderComponent={(<Space />)}
              ListFooterComponent={(<Space />)}
            />
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    minHeight: "100%",
  },
  emptyWrap: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
  },
  emptyContainer: {
    marginBottom: 108,
  },
  icon: {
    width: 16,
    height: 16,
  },
});

export default connect((store) => _.pick(store, [
  'i18n',
]))(Page);
