import _ from 'lodash';
import qs from 'qs';
import { request, NetInfo, delay, postException } from '../utils';
import { getCurrentShopId, getUserGuid, getLoginId } from '../utils/authority';
import DeviceInfo from 'react-native-device-info';
import packageJson from '../../package.json';
import { appConf, envConf } from '../config';
import { Platform } from 'react-native';
import { getStore } from '../utils/store';
const { daLogTerminalId } = appConf.get();

export async function create({ type, productId, orderId, countryCode, phoneNumber, remark } = {}) {
  const {
    loginSource,
  } = appConf.get();
  const [
    store,
    companyId,
    strangerId,
    accountId,
    connectionType,
    connectionTypeDetail,
  ] = await Promise.all([
    getStore(),
    getCurrentShopId(),
    getUserGuid(),
    getLoginId(),
    NetInfo.getConnectionType(),
    NetInfo.getConnectionTypeDetail(),
  ]);
  const mcAppInfo = store.getState().app?.mcAppInfo;
  // 不包含daLogTerminalId, 追加默认前缀daLogTerminalId_
  const reg = new RegExp("^(.|\n)*" + daLogTerminalId + "(.|\n)*$");
  if ( !(reg).test(type) ) {
    type = `${daLogTerminalId}_${type}`;
  }
  return request(`/dataAnalyses`, {
    method: 'POST',
    data: {
      type,
      productId,
      orderId,
      remark: [remark, mcAppInfo.app_version ? `MC_APP_v${mcAppInfo.app_version}` : ''].filter(_=>_).join(', '),
      companyId,
      strangerId,
      accountId,
      connectionType,
      connectionTypeDetail,
      countryCode,
      phoneNumber,
      visitedSource: loginSource + (Platform.OS === 'web' ? (mcAppInfo.app_version ? '_MC' : '_web') : ''),
      device: Platform.OS === 'web' ? (mcAppInfo.mobile_info?.modelName || undefined) : DeviceInfo.getDeviceId(),
      deviceId: Platform.OS === 'web' ? (mcAppInfo.device_sn || undefined) : DeviceInfo.getUniqueId(),
      osVersion: Platform.OS === 'web' ? (mcAppInfo.mobile_info?.systemVersion || undefined) : (DeviceInfo.getSystemName() + DeviceInfo.getSystemVersion()),
      appVersion: packageJson.version,
    },
  });
}

export async function logPV({ screenName, ...rest } = {}) {
  if (!screenName) {
    console.warn(`missing param "screenName"`);
    return {success: false};
  }
  const params = {
    type: `PV_${daLogTerminalId}_${screenName}`,
    ...rest,
  };
  // 等一会儿再发请求，好让业务请求先发出，因为PV打点一般都在页面打开时进行，避免影响首屏速度
  await delay(100);
  return await create(params);
}

// 分享渠道打点
export async function logShare({ scene = '', shareMethod = 'unknown', ...rest } = {}) {
  if (!scene) {
    console.warn(`missing param "scene"`);
    return {success: false};
  }
  const params = {
    type: `SHARE_${daLogTerminalId}_${scene}`,
    remark: shareMethod,
    ...rest,
  };
  return await create(params);
}

// 针对 react-native-share 的分享渠道打点包装，便于使用：
//
// await Share.open({
// }).then(pipeLogRNShare({scene: 'guideEnter'}))
//
export function pipeLogRNShare({scene} = {}) {
  return function(res){
    try {
      if (res.message && res.message !== 'CANCELED') {
        logShare({
          scene,
          shareMethod: res.message,
        });
      }
    } catch (error) {
      postException(error);
    }
    return res;
  };
}

export async function logClickBtn({ scene = '', btn = '', ...rest } = {}) {
  if (!btn || !scene) {
    console.warn(`missing param "btn" or "scene"`);
    return {success: false};
  }
  const params = {
    type: `CLICK_${daLogTerminalId}_${scene}_${btn}`,
    ...rest,
  };
  return await create(params);
}
