import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { dispatch, getState } from "../../utils/globalUtils";
import { Table, Input, Select, Pagination } from "antd";
import { formatText } from "../../utils";
import _ from "lodash";
import moment from 'moment';
import { useSelector } from "react-redux";
import NetImage from "../NetImage";
import TipIcon from "./components/TipIcon";
import DateRangePicker from "./components/DateRangePicker";
import DateRangePickerMobile from "./components/DateRangePickerMobile";
import Touchable from "../Touchable";
import LightBox from "../LightBox";
import { dataSourceExtraMap, fieldExtraDataMap } from "./config";
import { getCompanyId } from "../../utils/globalUtils";
import { Theme } from "ui-m/rn";
import FormItem from "../FormItem";
import { DownloadOutlined } from '../../config/antdIcons';
import { getRecipientEmail } from "../../utils";
import ScreenLoading from "../ScreenLoading";
import Toast from "../Toast";

const defaultDateRangeMap = {};
const getDefaultDateRangeByDashboardType = dashboardType => {

  return defaultDateRangeMap[dashboardType] || getLastDays().map(i => +i);
};
const setDefaultDateRangeByDashboardType = (dashboardType, range) => {

  defaultDateRangeMap[dashboardType] = range;
};

const getTipByField = key => fieldExtraDataMap?.[key]?.tip;

const getLastDays = (days = 1) => ([
  moment().subtract(days - 1, "days").startOf("day"),
  moment().endOf("day"),
]);

const getYesterday = () => [
  moment().subtract(1, "days").startOf("day"),
  moment().subtract(1, "days").endOf("day"),
];

const checkDateRangeValid = ({ startTime, endTime }, maxDateRangeLength = 90) => {
  if (!startTime || !endTime) {
    return false;
  }
  const now = moment();
  const start = moment(startTime);
  const end = moment(endTime);
  const earliest = moment(endTime).subtract(maxDateRangeLength - 1, "days");

  if (end.isAfter(now, "day")) {
    return false;
  }
  if (start.isAfter(end, "day")) {
    return false;
  }
  if (start.isBefore(earliest, "day")) {
    return false;
  }

  return true;
};

const Summary = props => {
  const i18n = useSelector(state => state.i18n);
  const { columns, statistics, hide } = props;

  return hide ? null : (
    <Table.Summary.Row style={{ backgroundColor: "#FAFAFA" }}>
      {columns.map((item, index) => {
        const key = item.key;
        const value = statistics[key];
        const sortable = item.sortable;
        const type = item.type;
        const showInTotal = item.showInTotal;

        let title;
        let textAlign = "left";

        if (type === "number") {
          title = formatText.num(value);
          textAlign = "right";
        }
        if (type === "currency") {
          title = formatText.amount(value);
          textAlign = "right";
        }
        if (type === "int") {
          title = formatText.int(value);
          textAlign = "right";
        }

        if (!showInTotal) {
          title = "";
        }
        if (!index) {
          title = i18n.map["cmn.total"];
        }

        return (
          <Table.Summary.Cell index={index} key={key}>
            <View
              style={{
                paddingRight: sortable ? 15 : 0,
              }}
            >
              <Text
                style={{
                  flex: 1,
                  fontSize: 14,
                  lineHeight: 18,
                  color: "rgba(0,0,0,.9)",
                  textAlign,
                  fontWeight: "bold",
                }}
              >{title}</Text>
            </View>
          </Table.Summary.Cell>
        );
      })}
    </Table.Summary.Row>
  );
};

const CompositeView = props => {
  const {
    title, subtitle, titleOptions, subtitleOptions,
    style, titleStyle, subtitleStyle,
  } = props;

  return (
    <View style={style}>
      <Text style={titleStyle} {...titleOptions}>{title}</Text>
      {!!subtitle && <Text style={subtitleStyle} {...subtitleOptions}>{subtitle}</Text>}
    </View>
  );
};

const renderTd = params => {
  // 黄色 #F6A103
  // 红色 #E8363B
  // 黑色 rgba(0,0,0,.9)
  // 灰色 rgba(0,0,0,.4)

  const { i18n } = getState();
  const { text, record, index, key, type: filedType, sortable } = params;

  let content = text;

  if (key === "number") {
    content = `#${text}`;
  }
  if (key === "typeName" && record.type === "pay") {
    content = `${text} (${record.paymentName})`;
  }
  if (filedType === "currency") {
    content = formatText.amount(text);
  }
  if (filedType === "int") {
    content = formatText.int(text);
  }
  if (filedType === "number") {
    content = formatText.num(text);
  }
  if (filedType === "date") {
    content = formatText.date(text);
  }
  if (filedType === "datetime") {
    content = formatText.datetime(text);
  }

  let textAlign = ["number", "currency", "int"].includes(filedType) ? "right" : "left";

  let tip;
  let fontWeight = "normal";
  let paddingRight = sortable ? 15 : 0;
  if (key === "arrears" && record.isLatest) {
    tip = "dataSource.overview.arrears.table.td.tip";
    fontWeight = "bold";
  }

  // composite
  if (filedType === "composite" || key === "amount") {
    let title, subtitle;

    if (key === "itemRefName") {
      title = record.itemRef;
      subtitle = record.name;
    }

    if (key === "amount") {
      const type = record.type;
      const balance = Number(text) * (type === "pay" ? -1 : 1);
      title = `${balance > 0 ? "+" : ""}${formatText.amount(balance)}`;
      if (type === "pay") {
        subtitle = (
          balance >= 0
            ? i18n.map["dataSource.supplierBills.amount.receivedARefund"]
            : i18n.map["dataSource.supplierBills.amount.payToSupplier"]
        );
      } else {
        subtitle = (
          balance >= 0
            ? i18n.map["dataSource.supplierBills.amount.purchaseOnCredit"]
            : i18n.map["dataSource.supplierBills.amount.returnTheGoods"]
        );
      }
    }

    return (
      <CompositeView
        style={{ flex: 1 }}
        titleStyle={{ fontSize: 14, lineHeight: 18, opacity: 0.9, textAlign, marginBottom: subtitle ? 2 : 0 }}
        subtitleStyle={{ fontSize: 12, lineHeight: 16, opacity: 0.4, textAlign }}
        title={title}
        subtitle={subtitle}
      />
    );
  }

  // image
  if (filedType === "images") {
    const url = NetImage.crop(text[0], 100);

    return url ? (
      <Touchable onPress={() => LightBox.show(text)}>
        <NetImage
          key={index}
          style={{ width: 50, height: 50 }}
          url={url}
        />
      </Touchable>
    ) : (
      <View style={{ backgroundColor: "#EBEBEB", width: 50, height: 50 }} />
    );
  }

  return (
    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingRight }}>
      {tip && <TipIcon i18nKey={tip} style={{ marginRight: 4 }} />}
      <Text style={{ textAlign, color: "rgba(0,0,0,0.9)", fontWeight, flex: tip ? 0 : 1 }}>{content}</Text>
    </View>
  );
};

const getColumnsByFields = (fields, filter, stream) => fields.map(field => {
  // 黑色 rgba(0,0,0,.9)
  const column = _.cloneDeep(field);
  const key = field.key;
  const type = field.type;
  let title = field?.displayName;

  column.dataIndex = key;
  column.key = key;

  // 排序
  if (field.sortable) {
    column.sorter = true;
    column.sortOrder = (
      filter.sortTypeCode === key
        ? filter.asc ? "ascend" : "descend"
        : null
    );
  }

  // 金额型追加货币符号
  const currencySymbol = stream?.currency?.displayName;
  if (type === "currency" && currencySymbol) {
    title += ` (${currencySymbol})`;
  }
  // 追加提示
  const tip = getTipByField(key);
  // 数字类型 文案居右
  const textAlign = ["number", "currency", "int"].includes(type) ? "right" : "left";
  // 宽度限制
  let minWidth = 50;
  // th
  column.title = (
    <View style={{ flexDirection: "row", alignItems: "center", paddingRight: 5, minWidth }}>
      <Text
        style={{
          flex: 1,
          fontSize: 14,
          lineHeight: 17,
          color: "rgba(0,0,0,.9)",
          marginRight: 3,
          textAlign,
        }}
      >{title}</Text>
      {tip && <TipIcon i18nKey={tip} />}
    </View>
  );

  // td
  column.render = (text, record, index) => renderTd({
    text, record, index, key, type,
    sortable: column.sortable,
  });

  return column;
});

const getMaxDateRangeLength = dashboardType => {

  return ({
    summary: 190,
    sales: 190,
    inventory: 190,
    accountStatement: 560,
  })[dashboardType] || 190;
};

const useFilter = (query, dataSourceKey, dashboardType, maxDateRangeLength) => {
  const defaultControls = _.clone(dataSourceExtraMap[dataSourceKey]?.controls || []);
  const defaultFilter = _.cloneDeep(query);

  const [controls, setControls] = useState(defaultControls);

  controls.forEach(i => {
    if (i === "dateRange") {
      const [startTime, endTime] = getDefaultDateRangeByDashboardType(dashboardType);
      defaultFilter.startTime = startTime;
      defaultFilter.endTime = endTime;
    } else {
      defaultFilter[i] = undefined;
    }
  });

  const [filter, setFilter] = useState(defaultFilter);

  // dateRange需要校验
  const [dateRangeValid, setDateRangeValid] = useState(
    controls.includes("dateRange") ? checkDateRangeValid(filter, maxDateRangeLength) : true
  );

  const setFilterKeys = diff => {
    const newFilter = { ...filter, ...diff };

    if (controls.includes("dateRange")) {
      if (!diff.startTime && !diff.endTime) {
        // 设置dateRange以为的filter时,若dateRange无效,则改为默认值
        const isDateRangeValid = checkDateRangeValid(newFilter, maxDateRangeLength);
        if (!isDateRangeValid) {
          const [start, endTime] = getDefaultDateRangeByDashboardType(dashboardType);
          newFilter.startTime = start;
          newFilter.endTime = endTime;
        }
      }
    }
    const _dateRangeValid = checkDateRangeValid(newFilter, maxDateRangeLength);
    setDateRangeValid(_dateRangeValid);
    if (_dateRangeValid) {
      setDefaultDateRangeByDashboardType(dashboardType, [newFilter.startTime, newFilter.endTime]);
    }
    setFilter(newFilter);
  };

  const resetFilter = () => setFilterKeys(defaultFilter);

  return {
    controls, setControls,
    filter, setFilter, setFilterKeys, resetFilter,
    dateRangeValid, setDateRangeValid,
  };
};

const usePagination = () => {
  const [defaultPagination, _setDefaultPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
    totalRecords: 0,
    current: 1,
    total: 0,
  });
  const setDefaultPagination = params => {
    _setDefaultPagination({
      ...defaultPagination,
      ...params,
    });
  };

  const linkage = (params = {}) => {
    const keys = Object.keys(params);
    if (keys.includes("pageNumber") || keys.includes("current")) {
      params.pageNumber = params.pageNumber || params.current;
      params.current = params.pageNumber;
    }
    if (keys.includes("totalRecords") || keys.includes("total")) {
      params.totalRecords = params.totalRecords || params.total || 0;
      params.total = params.totalRecords;
    }

    return {
      ...pagination,
      ...params,
    };
  };

  const [pagination, setPagination] = useState(defaultPagination);
  const resetPagination = () => setPagination(defaultPagination);

  const linkagePagination = params => setPagination(linkage(params));

  return {
    pagination, setPagination, linkagePagination, resetPagination,
    setDefaultPagination,
  };
};

const useColumns = (selectedFieldKeys, dataSource, filter, stream) => {
  const getFields = () => selectedFieldKeys.map(i => dataSource.fieldConfig.find(ii => ii.key === i));
  const getColumns = (_fields) => getColumnsByFields(_fields, filter, stream);

  const [columns, setColumns] = useState(getColumns(getFields()));

  useEffect(() => {
    setColumns(getColumns(getFields()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, filter]);

  return {
    columns, setColumns,
  };
};

const useTablePanelManager = props => {
  const { panel, stream, streamType, onDataUpdate, onLoadingChange } = props;
  const { query, dataSource, dataSourceKey, dashboardType, selectedFieldKeys } = panel;

  // 利用setQueryTime触发useEffect,执行queryDataSource
  const [queryTime, setQueryTime] = useState(0);
  const queryData = () => setQueryTime(Date.now());

  const [isLoading, setIsLoading] = useState(false);

  const [resultSet, setResultSet] = useState([]);
  const [statistics, setStatistics] = useState({});

  const maxDateRangeLength = getMaxDateRangeLength(dashboardType);

  // 控件 warehouseId, dateRange, keyword
  // 筛选 sortTypeCode, asc
  const {
    controls, setControls,
    filter, setFilter, setFilterKeys, resetFilter,
    dateRangeValid, setDateRangeValid,
  } = useFilter(query, dataSourceKey, dashboardType, maxDateRangeLength);

  // pagination: { pageSize, pageNumber, current, totalRecords, total }
  const {
    pagination, setPagination, linkagePagination, resetPagination,
    setDefaultPagination,
  } = usePagination();

  const {
    fields, setFields, updateFields,
    columns, setColumns, updateColumns,
  } = useColumns(selectedFieldKeys, dataSource, filter, stream);

  const queryDataSource = async () => {
    const companyId = stream?.companyId;
    const supplierId = stream?.supplierId;

    if (!companyId || !supplierId) {
      return;
    }
    if (!dateRangeValid) {
      // 检查时间范围
      return;
    }

    const _queryTime = +queryTime;

    setIsLoading(true);
    onLoadingChange(true);

    const payload = {
      dataSourceKey,
      dashboardType,
      query: {
        ...pagination,
        ...filter,
        companyId,
        supplierId,
      },
      viewMode: streamType === "up" ? "supplier" : "salesChannel",
    };

    const response = await dispatch({ type: "dashboard/queryDataSource", payload });
    handleQueryDateSourceResponse(response, _queryTime);
  };
  const handleQueryDateSourceResponse = async (response, _queryTime) => {
    if (_queryTime !== queryTime) {
      return;
    }
    setIsLoading(false);
    onLoadingChange(false);
    if (response.success) {
      const _resultSet = response.data.resultSet.map(i => ({ ...i, key: i.number || (i.colorId ? `${i.colorId}_${i.goodsId}` : i.goodsId) }));
      const _statistics = response.data.statistics;
      setResultSet(_resultSet);
      setStatistics(_statistics);
      onDataUpdate({ resultSet: _resultSet, statistics: _statistics });
      // pagination
      linkagePagination({
        pageNumber: response.data.currentPage,
        pageSize: response.data.pageSize,
        totalRecords: response.data.totalRecords,
      });
    }
  };

  // 联动更新filter
  const onTableChange = async (_pagination, _filters, _sorter, e) => {
    if (e.action === "sort") {
      resetPagination();
      setFilterKeys({
        sortTypeCode: _sorter.field,
        asc: _sorter.order === "ascend",
      });
    } else {
      linkagePagination({
        pageNumber: _pagination.current,
        pageSize: _pagination.pageSize,
      });
      setFilterKeys({}); // 此处需触发setFilterKeys,联动矫正dateRange
    }

    queryData();
  };

  const onPageNumberChange = pageNumber => {
    linkagePagination({ pageNumber });
    setFilterKeys({}); // 此处需触发setFilterKeys,联动矫正dateRange
    queryData();
  };

  const onPageSizeChange = pageSize => {
    setDefaultPagination({ pageSize });
    linkagePagination({ pageSize, pageNumber: 1 });
    setFilterKeys({});
    queryData();
  };

  const resetPanel = async () => {
    resetPagination();
    resetFilter();

    queryData();
  };

  const exportAsExcel = async () => {
    const companyId = stream?.companyId;
    const supplierId = stream?.supplierId;

    if (!companyId || !supplierId) {
      return;
    }
    if (!dateRangeValid) {
      // 检查时间范围
      return;
    }

    const email = await getRecipientEmail();
    if (!email) {
      return;
    }

    ScreenLoading.show();
    const res = await dispatch({
      type: "dashboard/exportAsExcel", payload: {
        email,
        exportMode: streamType === "up" ? "supplier" : "salesChannel",

        dataSourceKey,
        dashboardType,
        query: {
          ...pagination,
          ...filter,
          companyId,
          supplierId,
        },
      },
    });
    ScreenLoading.hide();
    if (res?.success) {
      Toast.success();
    }
  };

  // 立即触发一次setQueryTime
  useEffect(() => {
    queryDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTime]);

  return ({
    isLoading, setIsLoading,
    resultSet, setResultSet,
    statistics, setStatistics,
    controls, setControls,
    filter, setFilterKeys, setFilter, resetFilter,
    dateRangeValid, setDateRangeValid, maxDateRangeLength,
    fields, setFields,
    columns, setColumns, updateColumns,
    pagination, linkagePagination, resetPagination, onPageNumberChange, onPageSizeChange,
    onTableChange,
    queryDataSource, queryData,
    resetPanel,
    exportAsExcel,
  });
};

const TablePanel = props => {
  const { i18n, dashboard } = useSelector(state => state);
  const {
    refreshPanelEveryChange,
  } = dashboard;

  const { panel, streamType, stream } = props;

  const displayTitle = panel.displayTitle;
  const canExportAsExcel = panel.canExportAsExcel && streamType === "down"; // 仅看下游可用 避免白嫖
  const showTitlePart = !!displayTitle || !!canExportAsExcel;
  let tip;
  if (canExportAsExcel && panel?.dashboardType === "accountStatement") {
    tip = i18n.map["dashboards.export.supplier.billings.note"].replace("%s", stream?.companyName || "");
  }

  const [isInitialized, setIsInitialized] = useState(false);

  const {
    isLoading, setIsLoading,
    resultSet, setResultSet,
    statistics, setStatistics,
    controls, setControls,
    filter, setFilterKeys, setFilter, resetFilter,
    dateRangeValid, setDateRangeValid, maxDateRangeLength,
    fields, setFields,
    columns, setColumns, updateColumns,
    pagination, linkagePagination, resetPagination, onPageNumberChange, onPageSizeChange,
    onTableChange,
    queryData,
    resetPanel,
    exportAsExcel,
  } = useTablePanelManager(props);

  useEffect(() => {
    if (isInitialized) {
      setStatistics({});
      setResultSet([]);
      resetPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream?.supplierId, stream?.companyId]);


  useEffect(() => {
    if (isInitialized) {
      setFilterKeys({});
      queryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPanelEveryChange]);

  // 初始化完毕,避免触发不必要的请求
  useEffect(() => setIsInitialized(true), []);

  const isPhoneSizedWindow = Theme.isPhoneSizedWindow;

  const wrapStyle = {
    marginBottom: 20,
    paddingHorizontal: isPhoneSizedWindow ? 16 : 32,
    paddingTop: isPhoneSizedWindow ? 12 : 24,
    paddingBottom: isPhoneSizedWindow ? 20 : 24,
    backgroundColor: "white",
    borderRadius: isPhoneSizedWindow ? 0 : 4,
  };

  const titleStyle = {
    marginBottom: 17,
    minHeight: 28,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  };

  const titleTextStyle = {
    fontSize: 18,
    lineHeight: 28,
    opacity: 0.9,
    fontWeight: "bold",
  };

  const tipTextStyle = {
    opacity: 0.4,
    marginTop: 5,
  };

  const tableWrapStyle = {
    borderRadius: isPhoneSizedWindow ? 2 : 0,
    borderWidth: isPhoneSizedWindow ? 0.8 : 0,
    borderColor: "#D9D9D9",
  };

  return (
    <View style={wrapStyle}>
      {
        showTitlePart && (
          <View style={titleStyle}>
            <View style={{ flex: 1 }}>
              <Text style={titleTextStyle}>{displayTitle || " "}</Text>
              {!!tip && <Text style={tipTextStyle}>{tip}</Text>}
            </View>
            {
              canExportAsExcel ? (
                <Touchable style={{ flexDirection: "row", alignItems: "center" }} onPress={exportAsExcel} hitSlop={{ top: 8, left: 8, right: 8, bottom: 8 }}>
                  <DownloadOutlined style={{ fontSize: 24, color: "#0076FF" }} />
                  {
                    !isPhoneSizedWindow && <Text style={{ marginLeft: 10, fontSize: 16, color: "#0076FF" }}>{i18n.map["stream.exportToExcel"]}</Text>
                  }
                </Touchable>
              ) : <View />
            }
          </View>
        )
      }
      <FormItem.Group
        style={{
          marginBottom: isPhoneSizedWindow ? 14 : 10,
        }}
      >
        {
          controls.includes("warehouseId") && (
            <FormItem
              key="warehouseId"
              label={i18n.map["stream.dashboard.filter.warehouse"]}
            >
              <Select
                style={{ width: isPhoneSizedWindow ? 250 : 200 }}
                value={filter.warehouseId}
                options={(stream.warehouses || []).map(i => ({ label: i.name, value: i.id }))}
                placeholder={i18n.map["stream.dashboard.filter.warehouse.all"]}
                onChange={warehouseId => {
                  setFilterKeys({ warehouseId });
                  queryData();
                }}
                allowClear
              />
            </FormItem>
          )
        }
        {
          controls.includes("dateRange") && (
            <FormItem
              key="dateRange"
              label={i18n.map["stream.dashboard.filter.dateRange"]}
              validateStatus={dateRangeValid ? undefined : "error"}
            >
              <View style={{ width: 250 }}>
                {
                  isPhoneSizedWindow ? (
                    <DateRangePickerMobile
                      startTime={filter.startTime}
                      endTime={filter.endTime}
                      onRangeChange={({ startTime, endTime }) => {
                        setFilterKeys({ startTime, endTime });
                        queryData();
                      }}
                      maxDateRangeLength={maxDateRangeLength}
                    />
                  ) : (
                    <DateRangePicker
                      startTime={filter.startTime}
                      endTime={filter.endTime}
                      onRangeChange={({ startTime, endTime }) => {
                        setFilterKeys({ startTime, endTime });
                        queryData();
                      }}
                      ranges={{
                        [`${i18n.map["cmn.filter.timeRange.today"]}`]: () => getLastDays(),
                        [`${i18n.map["cmn.label.yesterday"]}`]: () => getYesterday(),
                        [`${i18n.map["cmn.filter.timeRange.7days"]}`]: () => getLastDays(7),
                        [`${i18n.map["cmn.filter.timeRange.30days"]}`]: () => getLastDays(30),
                        [`${i18n.map["cmn.filter.timeRange.90days"]}`]: () => getLastDays(90),
                      }}
                      inputReadOnly
                      allowClear={false}
                    />
                  )
                }
                {!dateRangeValid && <Text style={{ color: "#ff4d4f", fontSize: 13, lineHeight: 18, marginTop: 6 }}>{i18n.map["stream.dashboard.filter.dateRange.invalid.tip.generic"].replace("%s", maxDateRangeLength)}</Text>}
              </View>
            </FormItem>
          )
        }
        {
          controls.includes("keyword") && (
            <FormItem
              key="keyword"
              label={i18n.map["mch.plhd.search"]}
            >
              <Input.Search
                style={{ width: isPhoneSizedWindow ? 250 : 200 }}
                onSearch={value => {
                  resetPagination();
                  setFilterKeys({ keyword: value });
                  queryData();
                }}
                onChange={e => setFilterKeys({ keyword: e.target.value })}
                allowClear
              />
            </FormItem>
          )
        }
      </FormItem.Group>
      {/* table */}
      <View style={tableWrapStyle}>
        <Table
          dataSource={resultSet}
          columns={columns}
          pagination={false}
          onChange={onTableChange}
          sortDirections={["descend", "ascend", "descend"]}
          summary={currentData => (
            <Summary columns={columns} statistics={statistics} hide={!resultSet.length} />
          )}
          loading={isLoading}
          scroll={{ x: true }}
        />
      </View>
      <View style={{ alignItems: "flex-end" }}>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 9,
            marginBottom: -7,
            marginRight: -6,
          }}
        >
          {
            pagination?.total >= 0 && (
              <Text
                style={{
                  fontSize: 14,
                  lineHeight: 16,
                  color: "rgba(0,0,0,0.85)",
                  marginVertical: 7,
                  marginRight: 6,
                }}
              >{i18n.map.total_records}: {pagination.total}</Text>
            )
          }
          <Pagination
            {...pagination}
            style={{
              marginVertical: 7,
              marginRight: 6,
            }}
            showSizeChanger={false}
            onChange={onPageNumberChange}
          />
        </View>
        <Select
          style={{ width: 120, marginTop: 16 }}
          value={pagination.pageSize}
          options={[10, 20, 30, 50].map(i => ({ value: i, label: `${i} / ${i18n.map.page}` }))}
          onChange={onPageSizeChange}
        />
      </View>
    </View>
  );
};

export default TablePanel;
