import _ from 'lodash';
import qs from 'qs';
import moment from 'moment';
import RNLocalize from 'react-native-localize';
import i18nService from '../../services/i18n';
import { langOptions } from '../../config/langOptionConf';
import kvlogService from '../../services/kvlog';
import localize from 'crow/utils/localize';
import { Platform } from 'react-native';

// 无目标语言时的默认语言
const defaultLang = 'en';

// 设备语言
function parseThirdPartyLang(thirdPartyLang){
  if ( ['zh', 'chs'].includes(thirdPartyLang) ) {
    return 'zh_hans';
  }
  return thirdPartyLang;
}
let deviceLang = parseThirdPartyLang(RNLocalize.getLocales()[0].languageCode);
let deviceRegion = RNLocalize.getCountry();
// 如果是 Web，信息从 URL 中载入
if (Platform.OS === 'web') {
  const query = qs.parse(window.location.search.slice(1));
  if (query.lang) {
    deviceLang = parseThirdPartyLang(query.lang);
  }
}

// i18n.map.xxx get() handler
const i18nMapGetHandler = {
  get: function(target, key) {
    return target[key] || '';
  },
};

export default {
  namespace: 'i18n',

  state: {
    // 使用系统语言
    useDeviceLang: undefined,
    // 当前语言，初始化完成前暂时先用默认语言
    currentLang: defaultLang,
    currentRegion: deviceRegion,
    deviceLang,
    deviceRegion,
    // 可用语言清单
    langOptions,
    // [{ name, lang, }]
    // 当前语言的语言包
    map: {
      // key: 'value'
    },

    inited: false,
  },

  reducers: {
    inited(state, {payload}) {
      return {
        ...state,
        inited: true,
      };
    },
    setCurrentLang(state, {payload = {}}) {
      let {
        useDeviceLang = state.useDeviceLang,
        currentLang = state.currentLang,
        map = state.map,
      } = payload;
      // 首次初始化、用传入值修改map，需要包装一下 Proxy，避免使用时遇到空词条时报错 cannot read .replace() of undefined 之类
      if ((!state.inited || payload.map) && typeof Proxy !== 'undefined') {
        map = new Proxy(map, i18nMapGetHandler);
      }
      return {
        ...state,
        useDeviceLang,
        currentLang,
        map,
      };
    },
  },

  effects: {
    *init({ payload = {} }, { call, put, select, fork }) {
      // 给缓存的 map 加上空词条读取兼容的包装，可以避免界面出现 undefined
      yield put({ type: 'setCurrentLang' });
      // 传入的设备语言和地区更优先
      const {
        deviceRegion: _deviceRegion,
        deviceLang: _deviceLang,
      } = payload;
      if (_deviceLang) {
        deviceLang = parseThirdPartyLang(_deviceLang);
      }
      if (_deviceRegion) {
        deviceRegion = _deviceRegion;
      }
      let { useDeviceLang, currentLang } = yield call(i18nService.loadCurrentLangFromLocal);
      const res = yield yield put({ type: 'switchLang', payload: {
        useDeviceLang,
        currentLang,
        async: true,
      } });
      yield put({ type: 'inited' });
      return res;
    },

    // 切换语言 / 更新当前语言包
    *switchLang({ payload = {} }, { call, put, select, fork }) {
      let {
        useDeviceLang,
        currentLang,
        async = false,
      } = payload;
      // 是否使用设备语言，初始默认值
      if ([undefined, null].includes(useDeviceLang)) {
        useDeviceLang = true;
      }
      // 最终语言判断
      if (useDeviceLang && langOptions.find(_=>_.lang === deviceLang)) {
        currentLang = deviceLang; // 优先用设备语言
      }
      else if ([undefined, null].includes(currentLang)) {
        currentLang = defaultLang; // 当前语言，初始默认值
      }
      else if (!langOptions.find(_=>_.lang === currentLang)) {
        currentLang = defaultLang; // 脏数据屏蔽
      }
      const res = yield yield put({ type: 'buildCurrentLang', payload: {
        useDeviceLang,
        currentLang,
        async,
      } });
      if (res.success) {
        // 可降级项，异步存储当前选择语言到本地
        yield fork(function*(){
          yield call(i18nService.saveCurrentLangToLocal, { useDeviceLang, currentLang });
        });
      }
      return res;
    },

    *buildCurrentLang({ payload = {} }, { call, put, select, fork }) {
      let {
        useDeviceLang,
        currentLang,
        noLoop = false,
        async = false,
      } = payload;
      const { currentLang: _currentLang, useDeviceLang: _useDeviceLang } = yield select(({ i18n }) => (i18n));
      if (currentLang === undefined) {
        currentLang = _currentLang;
      }
      if (useDeviceLang === undefined) {
        useDeviceLang = _useDeviceLang;
      }
      let proxyMap, res = {success: true};
      // 异步获取增量多语言，APP 打开场景
      if (!noLoop) {
        if (async) {
          yield fork(function*(){
            yield call(i18nService.incrementalUpdateForLang, currentLang);
            yield put({ type: 'buildCurrentLang', payload: { noLoop: true } });
          });
        }
        // 同步获取增量多语言，切换语言场景
        else {
          res = yield call(i18nService.incrementalUpdateForLang, currentLang);
        }
      }
      // 增量完成后获取的是时间线完整的 map
      const map = yield call(i18nService.getFinalWordMapOfLang, currentLang);
      // 支持直接显示 key
      if (currentLang === 'key') {
        for (const key in map) {
          map[key] = key;
        }
      }
      // 在写入语言前进行本地化处理，因为写入语言会触发 render
      yield yield put({ type: 'initLocalizeRelated', payload: {
        currentLang,
      } });
      yield put({ type: 'setCurrentLang', payload: {
        useDeviceLang,
        currentLang,
        map,
      } });
      return res;
    },
    *initLocalizeRelated({ payload = {} }, { call, put, select, fork }) {
      const { currentLang: defaultCurrentLang, currentRegion } = yield select(({ i18n }) => (i18n));
      const {
        currentLang = defaultCurrentLang,
      } = payload;
      try {
        // 设置本地化组件的语言
        localize.setDefaultOptions({
          locale: currentRegion,
          lang: currentLang,
        });
        localize.setMomentLocale(moment);
        localize.isFinalLocaleValid({ throwError: true });
        // console.log(`localize.toLocaleNumStr() with locale '${localize.getDefaultOptions().finalLocale}' = ${localize.toLocaleNumStr(1234.23)}`);
        // console.log(`moment.locale() with locale '${moment.locale()}' = ${moment().format('L LTS')}`);
      } catch (error) {
        kvlogService.postException(error);
      }
    },
  },
};
