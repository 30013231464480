import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BasePage, ScreenLoading } from '../../components';
import { getNavigation } from '../../utils/navigation';
import { delay } from 'crow/utils/async';

export default class Page extends BasePage {

  async componentDidMount(){
    await getNavigation();
    if (!this.hide) {
      ScreenLoading.show();
    }
  }

  componentWillUnmount(){
    this.hide = true;
    ScreenLoading.hide();
  }

  renderPage = () => {
    return (
      <View />
    );
  }
}
