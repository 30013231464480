import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { View, Text } from "react-native";
import { dispatch } from "../../utils/globalUtils";
import { AutoFitText, Theme } from "ui-m/rn";
import { formatText } from "../../utils";
import TipIcon from "./components/TipIcon";
import { Row, Col, Spin } from "antd";
import { fieldExtraDataMap } from "./config";
import { getCompanyId } from "../../utils/globalUtils";
import { ScrollView } from 'react-native-gesture-handler';

const getTipByField = key => fieldExtraDataMap?.[key]?.tip;

const StatisticBlock = props => {
  const { field, dataSource, statistics, currency, isLoading } = props;
  const fieldConfig = dataSource.fieldConfig.find(i => i.key === field);
  const type = fieldConfig?.type;

  let title = fieldConfig?.displayName;
  let value = statistics?.[field];

  if (type === "number") {
    value = formatText.num(value);
  }
  if (type === "currency") {
    value = formatText.amount(value);
    currency && (title += ` (${currency})`);
  }
  if (type === "int") {
    value = formatText.int(value);
  }

  const tip = getTipByField(field);

  const isPhoneSizedWindow = Theme.isPhoneSizedWindow;

  const defaultFontSize = isPhoneSizedWindow ? 26 : 46;
  const wrapStyle = {
    flex: 1,
    justifyContent: "space-between",
    paddingHorizontal: isPhoneSizedWindow ? 15 : 32,
    paddingVertical: isPhoneSizedWindow ? 15 : 24,
  };
  const titleStyle = {
    color: "rgba(0,0,0,0.6)",
    fontSize: isPhoneSizedWindow ? 13 : 15,
    lineHeight: isPhoneSizedWindow ? 16 : 22,
    marginBottom: isPhoneSizedWindow ? 8 : 16,
    marginRight: tip ? 8 : 0,
  };
  const valueStyle = {
    fontWeight: "bold",
    color: "#333",
    lineHeight: defaultFontSize + 10 + "px",
  };
  const tipStyle = {
    position: "absolute",
    top: isPhoneSizedWindow ? 16 : 28,
    right: isPhoneSizedWindow ? 15 : 24,
  };

  return (
    <View style={{ flex: 1, backgroundColor: "white", borderRadius: 4 }}>
      <View style={{ flex: 1 }}>
        <View style={wrapStyle}>
          <Text style={titleStyle}>{title}</Text>
          <View style={{ width: "100%" }}>
            <AutoFitText defaultFontSize={defaultFontSize} style={valueStyle}>{value || " "}</AutoFitText>
          </View>
        </View>
        {!!tip && (
          <View style={tipStyle}>
            <TipIcon i18nKey={tip} tooltipOptions={{ placement: "bottomRight" }} />
          </View>
        )}
        {isLoading &&
          <View
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255,255,255,.4)",
            }}
          >
            <Spin />
          </View>
        }
      </View>
    </View>
  );
};

const usePagination = () => {
  const defaultPagination = {
    pageNumber: 1,
    pageSize: 10,
    totalRecords: 0,

    // current: 1,
    // total: 0
  };

  const linkage = (params = {}) => {
    const keys = Object.keys(params);
    if (keys.includes("pageNumber") || keys.includes("current")) {
      params.pageNumber = params.pageNumber || params.current;
      params.current = params.pageNumber;
    }
    if (keys.includes("totalRecords") || keys.includes("total")) {
      params.totalRecords = params.totalRecords || params.total || 0;
      params.total = params.totalRecords;
    }

    return {
      ...pagination,
      ...params,
    };
  };

  const [pagination, setPagination] = useState(linkage(defaultPagination));
  const resetPagination = () => setPagination(linkage(defaultPagination));

  const linkagePagination = params => setPagination(linkage(params));

  return {
    pagination, linkagePagination, resetPagination,
  };
};

const useStatisticsPanelManager = props => {
  const { panel, stream, streamType, dataRef, dataRefIsLoading } = props;
  const { dataSourceKey, dashboardType } = panel;

  // 利用setQueryTime触发useEffect,执行queryDataSource
  const [queryTime, setQueryTime] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [statistics, setStatistics] = useState({});

  // pagination: { pageSize, pageNumber, current, totalRecords, total }
  const {
    pagination, linkagePagination, resetPagination,
  } = usePagination();


  const queryDataSource = async () => {
    const companyId = stream?.companyId;
    const supplierId = stream?.supplierId;

    if (!companyId || !supplierId) {
      return;
    }
    const _queryTime = +queryTime;

    setIsLoading(true);

    const payload = {
      dataSourceKey,
      dashboardType,
      query: {
        ...pagination,
        companyId,
        supplierId,

      },
      viewMode: streamType === "up" ? "supplier" : "salesChannel",
    };

    const response = await dispatch({ type: "dashboard/queryDataSource", payload });
    handleQueryDateSourceResponse(response, _queryTime);
  };

  const handleQueryDateSourceResponse = async (response, _queryTime) => {
    if (_queryTime !== queryTime) {
      return;
    }
    setIsLoading(false);
    if (response.success) {
      setStatistics(response.data.resultSet[0]);
      // pagination
      linkagePagination({
        pageNumber: response.data.currentPage,
        pageSize: response.data.pageSize,
        totalRecords: response.data.totalRecords,
      });
    }
  };

  const queryData = () => setQueryTime(Date.now());

  const resetPanel = async () => {
    setStatistics({});
    resetPagination();

    queryData();
  };

  useEffect(() => {
    if (!dataRef) {
      queryDataSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTime]);

  return ({
    isLoading: dataRef ? dataRefIsLoading : isLoading,
    setIsLoading,
    statistics: dataRef ? (dataRef.statistics || {}) : statistics,
    setStatistics,
    pagination, linkagePagination, resetPagination,
    queryDataSource, queryData,
    resetPanel,
  });
};

const StatisticsPanel = props => {
  const { dashboard } = useSelector(state => state);
  const {
    refreshPanelEveryChange,
  } = dashboard;

  const { permission } = useSelector(state => ({
    permission: state.dashboard.permission,
  }));
  const { panel, stream, streamType } = props;

  const selectedFieldKeys = panel.selectedFieldKeys;
  const dataSource = panel.dataSource;

  const [isInitialized, setIsInitialized] = useState(false);

  const {
    isLoading,
    statistics,
    queryData,
    resetPanel,
  } = useStatisticsPanelManager(props);

  // 临时处理
  const notAffected = useRef({
    prevStatistics: null,
  });
  const [r, setR] = useState(0);
  const rerender = () => setR(Date.now());
  useEffect(() => {
    if (!shallowEqual(notAffected.current.prevStatistics, statistics)) {
      rerender();
      notAffected.current.prevStatistics = statistics;
    }
  }, [statistics]);


  useEffect(() => {
    if (isInitialized) {
      resetPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream?.supplierId, stream?.companyId]);

  useEffect(() => {
    if (isInitialized) {
      queryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPanelEveryChange]);

  useEffect(() => setIsInitialized(true), []);

  let _selectedFieldKeys = selectedFieldKeys.filter(i => i !== "estimatedIncome");
  if (
    (streamType === "up" && !permission?.showSupplierDebt) ||
    (streamType === "down" && !permission?.showClientDebt)
  ) {
    _selectedFieldKeys = _selectedFieldKeys.filter(i => i !== "arrears");
  }

  return Theme.isPhoneSizedWindow ? (
    <ScrollView
      horizontal
      contentContainerStyle={{
        paddingHorizontal: 6,
      }}
    >
      {_selectedFieldKeys.map(i => (
        <View key={i} style={{ width: 150, height: 100, marginHorizontal: 6 }}>
          <StatisticBlock
            key={i}
            field={i}
            dataSource={dataSource}
            statistics={statistics}
            currency={stream.currency?.displayName}
            isLoading={isLoading}
          />
        </View>
      ))}
    </ScrollView>
  ) : (
    <Row justify="start" gutter={[24, 24]} align="stretch">
      {_selectedFieldKeys.map(i => (
        <Col key={i} xxl={4} xl={8} lg={8} md={8} sm={8} xs={8} style={{ display: "flex" }}>
          <StatisticBlock
            key={i}
            field={i}
            dataSource={dataSource}
            statistics={statistics}
            currency={stream.currency?.displayName}
            isLoading={isLoading}
          />
        </Col >
      ))}
    </Row>
  );
};

export default StatisticsPanel;
