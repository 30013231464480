
const dataSourceExtraMap = {
  // 总览表
  "goodsInventorySalesOverview": {
    controls: [],
  },
  // 商品库存销量表
  "goodsInventorySales": {
    controls: ["warehouseId", "keyword", "dateRange"],
  },
  // 商品颜色库存销量表
  "goodsColorInventorySales": {
    controls: ["warehouseId", "keyword", "dateRange"],
  },
  // 商品SKU库存销量表
  "goodsSkuInventorySales": {
    controls: ["warehouseId", "keyword", "dateRange"],
  },
  // 欠款明细表
  "supplierBills": {
    controls: ["dateRange"],
  },
};

const fieldExtraDataMap = {
  // 库存销量表
  // 应收账款
  "payable": {
    tip: "dataSource.goodsInventorySales.payable.tip",
  },
  // 库存价值
  "stockValue": {
    tip: "dataSource.goodsInventorySales.stockValue.tip",
  },
  // 预定量
  "reservation": {
    tip: "dataSource.goodsInventorySales.reservation.tip",
  },
  // 预定货值 - 此处缺少

  // 欠款明细表
  // 欠款
  "arrears": {
    tip: "dataSource.overview.arrears.tip",
  },

  // 总览表
  // 应收账款
  "estimatedIncome": {
    tip: "dataSource.overview.estimatedIncome.tip",
  },
  // 预订件数总
  "totalReservation": {
    tip: "dataSource.overview.totalReservation.tip",
  },
};

export {
  dataSourceExtraMap,
  fieldExtraDataMap,
};
