import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, View, ScrollView, TextInput } from 'react-native';
import { connect } from 'react-redux';
import { NavigationPage, Toast, ScreenLoading } from '../../components';
import { createAction } from '../../utils';
import resources from '../../config/resources';
import { Panel, Cell, Title, Space, Tips, InputItem, Button } from 'ui-m/rn';
import localize from 'crow/utils/localize';
import { getNavigation } from '../../utils/navigation';
import DeviceInfo from 'react-native-device-info';
import Clipboard from '@react-native-community/clipboard';
import * as accountService from '../../services/account';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle(){
      return 'Dev tools';
    },
    showBackButton: true,
    backBtnIcon: 'close',
    primary: true,
    // renderNavigationRightView(){
    //   return <NavigationBar.Button icon="filter" onPress={()=>{}}/>
    // },
  };

  static propTypes = {
    ...NavigationPage.propTypes,
    // // 路由入参声明
    // route: PropTypes.shape({
    //   params: PropTypes.exact({
    //     id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     shopId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     siteUrlFull: PropTypes.string.required,
    //     profile: PropTypes.object,
    //   }),
    // }),
  }

  state = {
    loginId: '',
    loginToken: '',
  }

  renderPage = () => {
    const { auth } = this.props;
    const { loginId, loginToken } = this.state;
    return (
      <View style={styles.container}>
        <Space/>
        <Panel autoUnderLine style={{
          paddingHorizontal: 0,
        }}>
          <Panel.PaddingX>
            <View style={{paddingBottom: 0}}>
              <Title level={2} text="Info"/>
            </View>
          </Panel.PaddingX>
          {/* 用户账号登陆 */}
          {/* <Panel.PaddingX>
            <InputItem
              value={ loginId }
              placeholder="loginId | accountId"
              onChangeText={(loginId)=>{
                this.setState({
                  loginId,
                });
              }}
            />
            <InputItem
              value={ loginToken }
              placeholder="loginToken"
              onChangeText={(loginToken)=>{
                this.setState({
                  loginToken,
                });
              }}
            />
            <Space />
            <Button title="submit" onPress={async()=> {
              ScreenLoading.show();
              const res = await accountService.getAccountInfo({
                loginId: loginId,
              });
              if (res.success) {
                await this.props.dispatch(createAction('auth/shadowLogin')({
                  ...res.data,
                  loginId,
                  loginToken,
                  currentShopId: res.data.extra?.lastShopId,
                }));
                this.props.navigation.goBack();
              }
              ScreenLoading.hide();
            }}/>
            <Space />
          </Panel.PaddingX> */}

          <Cell
            title="Device Id"
            after={DeviceInfo.getUniqueId()}
            onPress={()=>{
              Clipboard.setString(`${DeviceInfo.getUniqueId()}`);
              Toast.success(this.props.i18n.map.copied);
            }}
          />
          <Cell
            title="crow/utils/localize.getDefaultOptions()"
            after={JSON.stringify(localize.getDefaultOptions(), null, 2)}
          />
          <Cell
            title="Notification token"
            after={JSON.stringify(auth.pushTokenInfo, null, 2)}
          />
        </Panel>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
});


export default connect((store) => _.pick(store, [
  'i18n',
  'app',
  'auth',
]))(Page);

// 暗号为摩斯电码的 'DEV'（. 为短按，_ 为长按）
// D _ . .
// E .
// V . . . _
// 完整暗号 = _ . . . . . . _
const entryCode = '_......_';
let entryCodeTappingQueue = '';
export async function notifyDevToolsEntryCodeTapped(pressType){
  if (!pressType) {
    throw `pressType should be one of ['shortPress', 'longPress']`;
  }
  const code = ({
    'shortPress': '.',
    'longPress': '_',
  })[pressType];
  entryCodeTappingQueue = (entryCodeTappingQueue + code).slice(-1 * entryCode.length);
  // console.log(entryCodeTappingQueue);
  if (entryCodeTappingQueue === entryCode) {
    (await getNavigation()).navigate('DevTools');
  }
}
