import React, { useState } from "react";
import { View } from "react-native";
import { Theme } from "ui-m/rn";
import StatisticsPanel from "./StatisticsPanel";
import TablePanel from "./TablePanel";

const PanelGroup = props => {
  const {
    panels,
    streamType,
    stream,
  } = props;

  const isPhoneSizedWindow = Theme.isPhoneSizedWindow;
  const [resultMap, setResultMap] = useState({});
  const onDataUpdate = (data, id) => {
    setResultMap({
      ...resultMap,
      [`${id}`]: data,
    });
  };
  const [isLoaingMap, setIsLoaingMap] = useState({});
  const onLoadingChange = (isLoading, id) => {
    setIsLoaingMap({
      ...isLoaingMap,
      [`${id}`]: isLoading,
    });
  };

  return (
    <View>
      {
        panels.map((i, ii) => (
          <View
            key={`${i.id}`}
            style={{ marginBottom: isPhoneSizedWindow ? 12 : 24 }}
          >
            {
              i.type === "statistics" && (
                <StatisticsPanel
                  panel={i}
                  streamType={streamType}
                  stream={stream}
                  dataRef={i.dataRefId ? (resultMap[i.dataRefId] || {}) : null}
                  dataRefIsLoading={i.dataRefId ? isLoaingMap[i.dataRefId] : null}
                />
              )
            }
            {
              i.type === "table" && (
                <TablePanel
                  panel={i}
                  streamType={streamType}
                  stream={stream}
                  onDataUpdate={data => onDataUpdate(data, i.id)}
                  onLoadingChange={isLoading => onLoadingChange(isLoading, i.id)}
                />
              )
            }
          </View>
        ))
      }
    </View>
  );
};

export default PanelGroup;
