import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavigationButton from './NavigationButton';

// import { navigateBack } from '../../utils';


class BackButton extends React.PureComponent {
  static propTypes = {
    // React-Navigation
    navigation: PropTypes.object,
    // 类型
    type: PropTypes.string,
    // icon 自定义
    icon: PropTypes.any,
  }

  static defaultProps = {
    type: '',
    icon: 'back',
  }

  render(){
    return <NavigationButton icon={this.props.icon} type={this.props.type} onPress={()=>{
      this.props.navigation.goBack();
    }}/>;
  }
}

export default connect(null)(BackButton);
