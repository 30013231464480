import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import { Touchable } from 'ui-m/rn';
import { Hoverable } from 'react-native-web-hover';
import { Breadcrumb, Layout, Menu, Space as AntSpace, Dropdown } from 'antd';
const { Header, Content, Sider, Footer } = Layout;

const menuWalker = function (parent, processFn) {
  parent.forEach((value, index) => {
    processFn(value, index, parent);
    if (Array.isArray(value.children)) {
      parent.children = menuWalker(value.children, processFn);
    }
  });
  return parent;
};

export default connect((store) => _.pick(store, [
  'i18n',
  'menu',
]))((props) => {
  const { menu, i18n, dispatch, style, onClick } = props;
  const { selectedKeys, menuTree } = menu;
  const dataMap = {};
  const finalMenuTree = menuWalker(_.cloneDeep(menuTree), (value, index, parent) => {
    parent[index] = {
      ..._.omit(value, ['labelI18nKey']),
      key: value.labelI18nKey, // TODO 换套key
      label: i18n.map[value.labelI18nKey],
      title: i18n.map[value.labelI18nKey],
    };
    dataMap[parent[index].key] = value.data;
  });

  const defaultOnClick = e => {
    dispatch({ type: "menu/onClick", payload: { ...e, data: dataMap[e.key] } });
  };

  const _onClick = e => {
    return (
      typeof onClick === "function"
        ? onClick(e, defaultOnClick)
        : defaultOnClick(e)
    );
  };

  const _style = StyleSheet.flatten([{
    height: '100%',
    borderRightWidth: 1,
    borderColor: "rgba(0,0,0,0.06)",
  }, style]);

  return (
    <Menu
      mode="inline"
      theme="light"
      selectedKeys={selectedKeys}
      // defaultSelectedKeys={['dashboard.summary.title']}
      defaultOpenKeys={['stream.dashboard.Menu']}
      style={_style}
      items={finalMenuTree}
      onClick={_onClick}
    />
  );
});
