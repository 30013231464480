import React, { useState } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { Modal, Tooltip } from "antd";
import { Teaset } from "ui-m/rn";
const { Theme, Overlay } = Teaset;
import Touchable from "./Touchable";
import { resources } from "../config";
import { useSelector } from "react-redux";
import { dispatch, getState } from "../utils/globalUtils";
import ScreenLoading from "./ScreenLoading";
import { formatInternationalForWhatsApp } from "../utils";

const showContactUs = contacts => {
  const id = Overlay.show(
    <Overlay.View
      style={{ alignItems: "center", paddingTop: 100 }}
      overlayOpacity={0} // 避免和antd的Modal的Mask重叠
    >
      <ContactUsView
        close={() => Overlay.hide(id)}
        contacts={contacts}
      />
    </Overlay.View>
  );
};

const ContactUsView = props => {
  const i18n = useSelector(state => state.i18n);
  const { close, contacts } = props;
  const [open, setOpen] = useState(true);
  const isPc = !Theme.isPhoneSizedWindow;

  return (
    <Modal
      open={open}
      afterClose={close}
      footer={null}
      centered
      onCancel={() => setOpen(false)}
      style={styles.container}
      width={isPc ? 400 : 270}
      bodyStyle={{ padding: 0 }}
      closable={false}
    >
      <View style={{ flex: 1 }}>
        <Text style={[styles.title, isPc && styles.pcTitle]}>{i18n.map["mch.btn.contact_support_staff"]}</Text>
        {contacts.map(((i, ii) => (
          <View style={[styles.row, isPc && styles.pcRow]} key={ii}>
            <Image style={styles.icon} source={{ uri: i.icon }} />
            <a
              className="link"
              style={{
                ...(isPc ? styles.pcText : styles.text),
                lineHeight: `${isPc ? 21 : 18}px`,
              }}
              href={i.href}
              target="_blank"
            >{i.content}</a>
            { // 二维码
              !Theme.isPhoneSizedWindow && !!i.qrcode && (
                <Tooltip
                  placement="right"
                  color="white"
                  overlayInnerStyle={styles.qrcodeWrap}
                  title={<View style={styles.qrcode} />}
                >
                  <Image style={styles.qrcodeIcon} source={{ uri: resources.qrcodeSmall }} />
                </Tooltip>
              )
            }
          </View>
        )))}
        {
          isPc ? (
            <Touchable style={styles.pcClose} onPress={() => setOpen(false)}>
              <Image style={styles.pcCloseIcon} source={{ uri: resources.closeSolidCircle }} />
            </Touchable>
          ) : (
            <>
              <View style={styles.line} />
              <Touchable style={styles.close} onPress={() => setOpen(false)}>
                <Text style={styles.closeText}>{i18n.map["cmn.btn.cancel"]}</Text>
              </Touchable>
            </>
          )
        }
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({

  container: {
    borderRadius: 14,
    overflow: "hidden",
  },
  title: {
    fontSize: 17,
    lineHeight: 22,
    color: "#333",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 16,
    paddingHorizontal: 30,
  },
  pcTitle: {
    fontSize: 18,
    textAlign: "left",
    marginTop: 24,
    marginBottom: 28,
  },
  row: {
    flexDirection: "row",
    marginBottom: 20,
    paddingHorizontal: 30,
  },
  pcRow: {
    marginBottom: 28,
  },
  icon: {
    width: 22,
    height: 22,
    marginRight: 15,
  },
  text: {
    fontSize: 16,
    color: "#333",
    marginVertical: 2,
  },
  pcText: {
    color: "#333",
    fontSize: 17,
    marginVertical: 0.5,
  },
  qrcodeIcon: {
    width: 16,
    height: 16,
    marginLeft: 6,
    marginTop: 3,
  },
  qrcodeWrap: {
    padding: 12.5,
    borderRadius: 8,
  },
  qrcode: {
    backgroundColor: "lightgray",
    width: 137.5,
    height: 137.5,
  },

  close: {
    paddingTop: 9.5,
    paddingBottom: 12,
    paddingHorizontal: 30,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 4,
  },
  line: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: "rgba(32,27,44,.2)",
  },
  closeText: {
    fontSize: 17,
    lineHeight: 22,
    color: "#0076FF",
    textAlign: "center",
  },

  pcClose: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  pcCloseIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
});

const contactConfigs = {
  phoneNumber: {
    icon: resources.telephoneSmall,
    getHref: tel => (`tel:${tel}`),
  },
  email: {
    icon: resources.emailSmall,
    getHref: email => (`mailto:${email}`),
  },
  whatsappAccount: {
    icon: resources.whatsappSmall,
    getHref: phone => (`https://wa.me/${phone}`),
    format: formatInternationalForWhatsApp,
  },
  // wechat: {
  //   icon: resources.wechatSmall
  // },
  // telegram: {
  //   icon: resources.telegramSmall
  // }
};

const switchContacts = contacts => contacts.map(i => {
  const contactConfig = contactConfigs[i.type];

  const contact = {
    content: contactConfig?.format?.(i.content) || i.content,
    icon: contactConfig?.icon,
    href: contactConfig?.getHref?.(i.content),
  };

  return contact;
});

const show = async () => {
  const { global: { merchantContacts } } = getState();
  if (merchantContacts.length) {
    return showContactUs(switchContacts(merchantContacts));
  } else {
    ScreenLoading.show();
    const res = await dispatch({ type: "global/getMerchantContacts" });
    ScreenLoading.hide();
    if (res.success) {
      showContactUs(switchContacts(res.data.merchantContacts));
    }
  }
};

export default {
  show,
};
