import React from 'react';

import { TouchableOpacity } from 'react-native';

export default class Touchable extends React.PureComponent {

  static activeOpacity = 0.6

  render (){
    const {disabled, ...rest} = this.props;
    if ( disabled ) {delete rest.onPress;}
    return <TouchableOpacity activeOpacity={disabled ? 1 : Touchable.activeOpacity} {...rest} />;
  }
}
