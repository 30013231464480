import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { NavigationPage, Toast, ScreenLoading } from '../../components';
import { createAction, handleRequestError } from '../../utils';
import resources from '../../config/resources';
import { Panel, Cell, Title, Space, Tips, InputItem, Button } from 'ui-m/rn';
import localize from 'crow/utils/localize';
import { getNavigation } from '../../utils/navigation';
import DeviceInfo from 'react-native-device-info';
import Clipboard from '@react-native-community/clipboard';
import * as accountService from '../../services/account';
import { request, mcRequest } from '../../utils';
import { ConfigProvider, DatePicker, message, Space as AntSpace, Table, Tag } from 'antd';


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <AntSpace size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </AntSpace>
    ),
  },
];
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];


import { appConf } from '../../config/index';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle(){
      return 'Framework Status';
    },
    showBackButton: true,
    backBtnIcon: 'close',
    primary: true,
  };

  static propTypes = {
    ...NavigationPage.propTypes,
    // // 路由入参声明
    // route: PropTypes.shape({
    //   params: PropTypes.exact({
    //     id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     shopId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     siteUrlFull: PropTypes.string.required,
    //     profile: PropTypes.object,
    //   }),
    // }),
  }

  state = {
    demoRequestResultMap: {},
  }

  componentDidMount(){
    this.sendAllDemoRequest();
  }

  sendAllDemoRequest = ()=>{
    ['ms', 'mc'].forEach((type)=>{
      [true, false].forEach(async (needLogin)=>{
        [true, false].forEach(async (success)=>{
          this.sendDemoRequest({type, needLogin, success});
        });
      });
    });
  }

  sendDemoRequest({type = 'ms', needLogin, success = true} = {}){
    const key = `${type}-${needLogin}-${success}`;
    function parseRes(res){
      if (res.success) {
        if (typeof res.data === 'object') {
          res.data = {
            [Object.keys(res.data)[0]]: '...',
            '...': '...',
          };
        }
        return JSON.stringify(_.pick(res, ['success', 'data']), null);
      }
      else {
        return JSON.stringify(_.pick(res, ['success', 'status', 'errorCode', 'errorMessage']), null, 1);
      }
    }
    const caseFn = ({
      'ms-false-true': async ()=>{
        const res = await request('/all/countries');
        this.setState({
          demoRequestResultMap: {
            ...this.state.demoRequestResultMap,
            ...{[key]: parseRes(res)},
          },
        });
      },
      'ms-false-false': async ()=>{
        const res = await request('/all/xxxxxx');
        this.setState({
          demoRequestResultMap: {
            ...this.state.demoRequestResultMap,
            ...{[key]: parseRes(res)},
          },
        });
      },
      'ms-true-false': async ()=>{
        const res = await request('/accounts/1?mcKey=1');
        this.setState({
          demoRequestResultMap: {
            ...this.state.demoRequestResultMap,
            ...{[key]: parseRes(res)},
          },
        });
      },
      'mc-true-false': async ()=>{
        const res = await mcRequest('/user/get_company_info?key=1');
        this.setState({
          demoRequestResultMap: {
            ...this.state.demoRequestResultMap,
            ...{[key]: parseRes(res)},
          },
        });
      },
      'mc-true-true': async ()=>{
        const res = await mcRequest('/user/get_company_info');
        this.setState({
          demoRequestResultMap: {
            ...this.state.demoRequestResultMap,
            ...{[key]: parseRes(res)},
          },
        });
      },
    })[key];
    if (caseFn) {
      caseFn();
    }
  }

  demoLoading = ()=>{
    ScreenLoading.show();
    setTimeout(()=>{
      ScreenLoading.hide();
    }, 1000);
  }

  demoToast = ({success} = {})=>{
    if (success === undefined) {
      Toast('Test toast long long long long long long long long long long text');
    }
    else if (success) {
      Toast.success();
    }
    else if (!success) {
      Toast.fail('Test toast failed');
    }
  }

  renderPage = () => {
    const { auth } = this.props;
    const { demoRequestResultMap, date } = this.state;
    const handleChange = value => {
      message.info(`您选择的日期是: ${value ? value.format('YYYY年MM月DD日') : '未选择'}`);
      this.setState({date: value});
    };
    return (
      <ScrollView>
        <Space/>

        <Panel style={{ paddingHorizontal: 0 }}>
          <Panel.PaddingX>
            <View style={{paddingBottom: 0}}>
              <Title level={2} text="Config"/>
            </View>
          </Panel.PaddingX>
          <Cell
            title="Version Type"
            afterTitle={appConf.versionType}
          />
          <Cell
            title="Main API Base"
            afterTitle={appConf.apiBase}
          />
          <Cell
            title="MC API Base"
            afterTitle={appConf.mcApiBase}
          />
        </Panel>
        <Space/>

        <Panel style={{ paddingHorizontal: 0 }}>
          <Panel.PaddingX>
            <View style={{paddingBottom: 0}}>
              <Title level={2} text="Theme"/>
            </View>
          </Panel.PaddingX>
          <Cell
            title="Dimensions.get('window').width"
            afterTitle={Dimensions.get('window').width}
          />
          <Cell
            title="Dimensions.get('window').height"
            afterTitle={Dimensions.get('window').height}
          />
          <Panel.PaddingX>
            <Button primary onPress={()=>{ this.demoLoading(); }}>Show Loading</Button>
            <Space/>
            <Button onPress={()=>{ this.demoToast({success: true}); }}>Show Toast Succeed</Button>
            <Space/>
            <Button danger onPress={()=>{ this.demoToast({success: false}); }}>Show Toast Failed</Button>
            <Space/>
            <Button link onPress={()=>{ this.demoToast(); }}>Show Toast text</Button>
          </Panel.PaddingX>
        </Panel>
        <Space/>

        <Panel style={{ paddingHorizontal: 0 }}>
          <Panel.PaddingX>
            <View style={{paddingBottom: 0}}>
              <Title level={2} text="Request"/>
            </View>
          </Panel.PaddingX>
          <Cell
            title="MicroStore demo request success"
            afterTitle={demoRequestResultMap['ms-false-true']}
          />
          <Cell
            title="MicroStore demo request failed"
            afterTitle={demoRequestResultMap['ms-false-false']}
          />
          <Cell
            title="MicroStore demo request 401"
            afterTitle={demoRequestResultMap['ms-true-false']}
          />
          <Cell
            title="MC demo request success"
            afterTitle={demoRequestResultMap['mc-true-true']}
          />
          <Cell
            title="MC demo request 6011 (401)"
            afterTitle={demoRequestResultMap['mc-true-false']}
          />
          <Cell
            title="Handle MS request 401"
            onPress={async ()=>{
              await this.props.dispatch(createAction('auth/setLoginInfo')({mcKey: '1'}));
              handleRequestError(await request('/accounts/1?mcKey=1'));
            }}
          />
          <Cell
            title="Handle MC request 6011 (401)"
            onPress={async ()=>{
              await this.props.dispatch(createAction('auth/setLoginInfo')({mcKey: '1'}));
              handleRequestError(await mcRequest('/user/get_company_info?key=1'));
            }}
          />
        </Panel>
        <Space/>

        <Panel style={{ paddingHorizontal: 0 }}>
          <Panel.PaddingX>
            <View style={{paddingBottom: 0}}>
              <Title level={2} text="Big Screen Web Components (Ant Design)"/>
            </View>
          </Panel.PaddingX>
          {
            Dimensions.get('window').width >= 1024 ? (
              <Panel.PaddingX>
                <DatePicker onChange={handleChange} />
                <div style={{ marginTop: 16 }}> Current date: {date ? date.format('YYYY/MM/DD') : 'Unselected'} </div>
                <Table columns={columns} dataSource={data} />
              </Panel.PaddingX>
            ) : (
              <Cell
                title="Will diplay AntD components here if the screen width >= 1024"
              />
            )
          }
        </Panel>
        <Space/>

        <Space scene="pageFooter" />


        {/* 用户账号登陆 */}
        {/* <Panel.PaddingX>
            <InputItem
              value={ loginId }
              placeholder="loginId | accountId"
              onChangeText={(loginId)=>{
                this.setState({
                  loginId,
                });
              }}
            />
            <InputItem
              value={ loginToken }
              placeholder="loginToken"
              onChangeText={(loginToken)=>{
                this.setState({
                  loginToken,
                });
              }}
            />
            <Space />
            <Button title="submit" onPress={async()=> {
              ScreenLoading.show();
              const res = await accountService.getAccountInfo({
                loginId: loginId,
              });
              if (res.success) {
                await this.props.dispatch(createAction('auth/shadowLogin')({
                  ...res.data,
                  loginId,
                  loginToken,
                  currentShopId: res.data.extra?.lastShopId,
                }));
                this.props.navigation.goBack();
              }
              ScreenLoading.hide();
            }}/>
            <Space />
          </Panel.PaddingX> */}

        {/* <Cell
            title="Device Id"
            after={DeviceInfo.getUniqueId()}
            onPress={()=>{
              Clipboard.setString(`${DeviceInfo.getUniqueId()}`);
              Toast.success(this.props.i18n.map.copied);
            }}
          />
          <Cell
            title="crow/utils/localize.getDefaultOptions()"
            after={JSON.stringify(localize.getDefaultOptions(), null, 2)}
          />
          <Cell
            title="Notification token"
            after={JSON.stringify(auth.pushTokenInfo, null, 2)}
          />
        </Panel> */}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
});


export default connect((store) => _.pick(store, [
  'i18n',
  'app',
  'auth',
]))(Page);

// 暗号为摩斯电码的 'DEV'（. 为短按，_ 为长按）
// D _ . .
// E .
// V . . . _
// 完整暗号 = _ . . . . . . _
const entryCode = '_......_';
let entryCodeTappingQueue = '';
export async function notifyDevToolsEntryCodeTapped(pressType){
  if (!pressType) {
    throw `pressType should be one of ['shortPress', 'longPress']`;
  }
  const code = ({
    'shortPress': '.',
    'longPress': '_',
  })[pressType];
  entryCodeTappingQueue = (entryCodeTappingQueue + code).slice(-1 * entryCode.length);
  // console.log(entryCodeTappingQueue);
  if (entryCodeTappingQueue === entryCode) {
    (await getNavigation()).navigate('DevTools');
  }
}
