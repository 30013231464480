
import imgCrop from 'crow/utils/img-crop';

// import { Platform } from 'react-native';
// import CameraRoll from '@react-native-community/cameraroll';
// const RNFS = require('react-native-fs');
// import Toast from '../components/Toast';
// import Permission from './permissions';

// 图片裁剪
const showImageWithCut = ({ url, width }) => {
  if (!url || !url.length) { return ''; }
  // 去掉黑端裁剪参数
  const urlOrign = url.replace(/(user_(\d+)\/)_(\d+)_(\d+)_/,($0,$1)=>$1);
  return `${urlOrign}${urlOrign.includes('?') ? '&' : '?'}x-oss-process=image/resize,w_${width}`;
};

// 获取国旗图片（先使用微店铺显示图片）
const getCountryImageUrl = (isoCode) => imgCrop.cropByUrl(`https://cdn.microstore.app/country_flag_image/${isoCode}.png`, 'image/resize,m_lfit,w_50,h_50');

// 获取视频第一帧图片
const getOssVideoImg = (videoUrl, width) => `${videoUrl}?x-oss-process=video/snapshot,t_0,f_jpg,w_${width},m_fast,ar_auto`;

// 保存图片到本地 (启用时先引入依赖 react-native-fs)
// const saveToAlbum = ({ i18n, url }) => {
//   Permission.check({
//     type: 'SAVE',
//     successCallBack: () => {
//       ModalIndicator.show();

//       if (Platform.OS === 'ios') {
//         CameraRoll.saveToCameraRoll(url).then(() => {
//           ModalIndicator.hide();
//           Toast.success({
//             text: i18n.map['cmn.toast.save_success'],
//             duration: 2000,
//           });
//         }).catch((err) => {
//           ModalIndicator.hide();
//           Toast.fail({
//             text: i18n.map['cmn.toast.save_failed'],
//             duration: 2000,
//           });
//           console.warn(err.toString());
//         });
//       }

//       if (Platform.OS === 'android') {
//         const storeLocation = `${RNFS.DocumentDirectoryPath}`;
//         let pathName = new Date().getTime() + "xg.png";
//         let downloadDest = `${storeLocation}/${pathName}`;
//         const ret = RNFS.downloadFile({fromUrl:url,toFile:downloadDest});
//         ret.promise.then(res => {
//           if (res && res.statusCode === 200){
//             var promise = CameraRoll.saveToCameraRoll("file://" + downloadDest);
//             promise.then(function(result) {
//               ModalIndicator.hide();
//               Toast.success({
//                 text: i18n.map['cmn.toast.save_success'],
//                 duration: 2000,
//               });
//             }).catch(function(error) {
//               ModalIndicator.hide();
//               Toast.fail({
//                 text: i18n.map['cmn.toast.save_failed'],
//                 duration: 2000,
//               });
//             });
//           }
//         });
//       }
//     },
//     errorCallBack: () => {
//       ModalIndicator.hide();
//     },
//     i18n,
//   });
// };

export {
  showImageWithCut,
  getCountryImageUrl,
  getOssVideoImg,
  // saveToAlbum,
};
