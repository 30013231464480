import { Clipboard } from "react-native";
import { useSelector } from 'react-redux';
import * as authority from './authority';
export { authority };

export { default as request } from './request';
export { default as mcRequest } from './mcRequest';
export { handleRequestErrorInModel, handleRequestError } from './handleRequestError';
export { default as Storage } from './storage';
export { default as Permission } from './permission';
export { default as NetInfo } from './netInfo';
export { creatGuid } from './uuid';
import { parsePhoneNumber } from 'libphonenumber-js';

import { Toast } from "../components";
import { getState } from "./globalUtils";

export * from './image';

export { getCustomerDefaultAvatorColor } from './avatorColor';

export const delay = time => new Promise(resolve => setTimeout(resolve, time));

export const createAction = type => payload => ({ type, payload });

export const buildInI18nGet = function (i18n, object, key = '') {
  const i18nKey = object[`${key}I18n`];
  return (i18nKey && i18n.map[i18nKey]) ? i18n.map[i18nKey] : object[key];
};

import kvLogService from '../services/kvlog';
export const postException = kvLogService.postException;

export const testRequest = body => fetch("/testRequest", {
  method: "POST",
  body: JSON.stringify(body),
});

export const formatInternationalForWhatsApp = internationalNumber => {
  internationalNumber = `${internationalNumber}`;
  try {
    // whatsapp补上 + 号
    const number = `${internationalNumber.indexOf("+") === 0 ? "" : "+"}${internationalNumber}`;
    const formatedNumber = parsePhoneNumber(number).formatInternational();
    return formatedNumber || internationalNumber;
  } catch {
    return internationalNumber;
  }
};

export const openMC = (params = {}) => {
  const {
    scheme = "ASMCAdmin2",
    url = window.location.origin,
    launchParam = "",
  } = params;

  const schemeURL = `${scheme}://msStreamH5?param=` + encodeURIComponent(JSON.stringify({
    mode: "remote",
    url,
    launchParam,
    appParam: [{ "localKey": "key", "asKey": "mcKey" }, { "localKey": "lang", "asKey": "lang" }],
  }));

  window.location.href = schemeURL;
};

export * from "./dashboard";
export * from "./navigation";
export * from "./url";
export * from "./formatText";
export * from "./share";
export * from "./time";
export * from "./other";
