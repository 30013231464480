import _ from 'lodash';
import qs from 'qs';
import { Platform, StatusBar } from 'react-native';
import { createAction } from '../../utils';
import { delay, handleRequestErrorInModel, authority } from '../../utils';
import * as mcAccountService from '../../services/mcAccount';
import { Theme } from 'ui-m/rn';

export default {
  namespace: 'auth',
  state: {
    mcKey: null,
    // 登录账号信息
    isValid: false,    // 识别 mcKey是否有效
    companyInfo: {},
    staffInfo: {},
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    setLoginInfo(state, { payload }) {
      let { mcKey } = payload;
      return {
        ...state,
        mcKey,
        isValid: true,
      };
    },
    setLoginInvalid(state, { payload }) {
      return {
        ...state,
        mcKey: null,
        isValid: false,
      };
    },
    clearLoginInfo(state, { payload }) {
      return {
        ...state,
        mcKey: null,
        isValid: false,
        companyInfo: {},
        staffInfo: {},
      };
    },
  },
  effects: {
    // 在 models/app.js 中被调度
    *init({payload = {}}, { call, put, select, take, fork }) {
      const {
        company_id,
        staff_id,
      } = payload;
      // 传入的ID直接提早写入（只在没有ID时才写入），打点之类的可以直接用上
      const { companyInfo = {}, staffInfo = {} } = yield select(({auth})=>auth);
      if (company_id && company_id !== companyInfo.company_id) {
        yield put(createAction('setState')({
          companyInfo: {
            id: company_id,
          },
        }));
      }
      if (staff_id && staff_id !== staffInfo.staff_id) {
        yield put(createAction('setState')({
          staffInfo: {
            id: staff_id,
          },
        }));
      }
      // 在MC黑端中，mcKey从URL中载入
      if (Platform.OS === 'web' && Theme.isWebInAPP) {
        // ?mcKey= 或者 ?key=
        const query = qs.parse(window.location.search.slice(1));
        const mcKey = query.mcKey || query.key;
        if (mcKey) {
          yield put(createAction('setLoginInfo')({ mcKey }));
        }
      }
    },
    // 在 models/app.js 中被调度
    *checkActive({payload = {}}, { call, put, select, fork }) {
      const { mcKey } = yield select(({auth})=>auth);
      const { sync = false } = payload;
      let res;
      // 没有 mcKey 时直接打开
      if (!mcKey) {
        res = {
          success: false,
          status: 401,
        };
        yield handleRequestErrorInModel(res, null, {mute: true});
        return res;
      }
      // 有登录记录时
      else {
        // 检查登录态是否有效，并更新用户信息
        function* updateAccountInfo(){
          res = yield yield put(createAction('updateAccountInfo')());
          if (res.success) {
            // 确认已有效登录，抛出生命周期事件供需要的地方 watch
            // 加 auth 是因为似乎 call 有 bug
            yield put(createAction('auth/lifeCycle_authEnsureValid')());
          }
          else {
            yield handleRequestErrorInModel(res);
          }
        }
        if (sync) {
          yield call(updateAccountInfo);
        }
        else {
          yield fork(updateAccountInfo);
        }
      }
      return res;
    },
    // *ensureValidLoggedIn({payload}, { call, put, select, fork }) {
    // },
    // *login({payload: { _beforeFinishedCallback, ...rest} = {}}, { call, put, select, fork }) {
    //   const { currentShopId, isValid } = yield select(({auth})=>auth);
    //   // 首次登陆时延迟进店
    //   if (!currentShopId || !isValid) {
    //     yield put(createAction('setState')({delayLoginIntoHome: true}));
    //   }
    //   const res = yield call(accountService.login, {
    //     ...rest,
    //     // 用于关联 StrangerId 和账号
    //     additionalProperties: {
    //       strangerId: yield call(authority.getUserGuid),
    //     },
    //   });
    //   if (res.success) {
    //     // 登陆成功打点
    //     yield fork(function*(){
    //       yield call(daService.create, {type: 'loggedIn'});
    //     });
    //     yield put(createAction('setLoginInfo')(res.data));
    //     if (typeof _beforeFinishedCallback === 'function') {
    //       const result = yield yield call(_beforeFinishedCallback);
    //       if (result === false) {
    //         return { success: false };
    //       }
    //     }
    //     // 确认已有效登录
    //     yield yield put(createAction('ensureValidLoggedIn')());
    //   }
    //   yield put(createAction('setState')({delayLoginIntoHome: false}));

    //   return res;
    // },
    // *getLoginInfoFromMS({payload}, { call, put, select, fork }) {
    //   const { mcKey } = payload;
    //   if (!mcKey) {
    //     // 微店铺拒接授权
    //     Toast.fail();
    //     return;
    //   }
    //   ScreenLoading.show();
    //   const res = yield call(accountService.msLogin, {
    //     ...payload,
    //   });
    //   if (res.success) {
    //     // 登陆成功打点
    //     yield fork(function*(){
    //       yield call(daService.create, {type: 'loggedIn'});
    //     });

    //     if (!res.data.extra?.lastShopId) {
    //       ScreenLoading.hide();
    //       // 路由切换成登陆建店状态，避免登陆但无店状态访问别的页面
    //       yield put.resolve(createAction('setState')({delayLoginIntoHome: true}));
    //       yield put.resolve(createAction('setLoginInfo')(res.data));
    //       // 没有店铺需要先创建店铺
    //       const navigation = yield yield call(getNavigation);
    //       navigation.navigate('CreateShop', {
    //         scene: 'loginViaMSWithNoShop',
    //       });
    //     }
    //     else {
    //       // 避免isValid切换后直接进店
    //       yield put(createAction('setState')({delayLoginIntoHome: true}));
    //       yield put.resolve(createAction('setLoginInfo')(res.data));
    //       // 有店直接登陆进店。阻塞式更新店铺信息后才进店
    //       yield put.resolve(createAction('ensureValidLoggedIn')());
    //       yield put(createAction('setState')({delayLoginIntoHome: false}));
    //       ScreenLoading.hide();
    //       yield put(createAction('doAfterLoginSuccess')());
    //       yield put(createAction('intention/setState')({sourceMSVersion: undefined}));
    //     }
    //   }
    //   else {
    //     ScreenLoading.hide();
    //     yield handleRequestErrorInModel(res, null, res.errorCode == '000002' ? {showFailIcon: true} : {});
    //   }
    // },
    // *loginViaMS({payload: { _beforeFinishedCallback, ...rest} = {}}, { call, put, select }) {
    //   // 确认已有效登录
    //   yield put.resolve(createAction('ensureValidLoggedIn')());
    //   yield put.resolve(createAction('setState')({delayLoginIntoHome: false}));

    //   yield put(createAction('doAfterLoginSuccess')());
    //   // 登陆进店成功后去除auth/canPriorityMSLogin
    //   yield put(createAction('intention/setState')({sourceMSVersion: undefined}));
    // },
    *loginViaMcQrCode({payload: { mcKey, ...rest} = {}}, { call, put, select }) {
      yield put(createAction('setLoginInfo')({mcKey}));
      return yield yield put(createAction('checkActive')({
        sync: true,
      }));
    },
    // *doAfterLoginSuccess({payload}, { call, put, select, fork }) {
    //   const navigation = yield call(getNavigation);
    //   navigation.dispatch(
    //     CommonActions.reset({
    //       index: 0,
    //       routes: [
    //         {name: 'MainStack', screen: 'MainTab'},
    //       ],
    //     })
    //   );
    // },
    *logout({payload}, { call, put, select }) {
      yield put(createAction('clearLoginInfo')());
      yield handleRequestErrorInModel({
        success: false,
        status: 401,
      }, null, {mute: true});
    },

    *updateAccountInfo({payload}, { call, put, select, fork }) {
      let [comRes, staffRes] = yield [
        call(mcAccountService.getCompanyInfo),
        call(mcAccountService.getStaffInfo),
      ];
      if (comRes.success) {
        yield put(createAction('setState')({
          companyInfo: comRes.data.info || {},
        }));
      }
      if (staffRes.success) {
        yield put(createAction('setState')({
          staffInfo: staffRes.data.data || {},
        }));
      }
      return [comRes, staffRes].filter(_=>!_.success)[0] || comRes;
    },
  },
  subscriptions: {
    async setup({ dispatch }) {
    },
  },
};
