import { CommonActions } from "@react-navigation/native";
import qs from "qs";
import { Platform } from "react-native";
import { Theme } from "ui-m/rn";
import { getNavigation, parseNavigateShortcut } from "../../utils";

// 定位：决定 APP 在被唤起后前往哪个页面、带有意向参数唤起/聚焦后进行何种动作
// 可能性：
// 1. 未登录 => 前往登录
// 2. 已登录 => 前往默认首页 => 正常使用
// 3. 已登录 => 前往默认首页 => 登录态失效
// 4. 看是否有意向弹出额外页面，分几种情况：
//   4.1 打开APP时获取意向，某些意向不用登录就直接处理（包括跳转页面）
//   4.2 打开APP时获取意向，某些意向需要等待登录态确定有效后才去处理
//   （本轮降级：4.3 重新聚焦APP时获取意向，H5 暂时不用考虑）

// 黑端、蓝端内嵌页面
// 黑端上下游信息同步: ?mcKey={mcKey}&lang={lang} 或者 ?navigate=SupplyChainStack&mcKey={mcKey}&lang={lang}
// 蓝端上下游信息同步 ?navigate=DashboardReports&mcKey={mcKey}&lang={lang}
// 黑端三方销售渠道: ?intention=%7B%22action%22%3A%22thirdPartySalesChannelManagement%22%7D&mcKey={mcKey}&lang={lang}

export default {
  namespace: 'intention',
  state: {
    // 本次打开/聚焦的信息
    openType: 'launch',
    URLOpen: null,
    URLParams: {},
    notificationOpen: null,
    // 捕获到的意向会快照在这里
    catchedIntention: null,
    // {
    //   openType: 'launch',
    //   URLOpen: null,
    //   URLParams: {}
    //   notificationOpen: null,
    //   intentionParams: {}
    // }
  },
  reducers: {
    _setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {

    *handleIntention({ payload = {} }, { call, put, select, take, fork, all, race }) {
      // shouldResetToDefaultScreen = false 时, intetntion/init 不会验证登陆态, 若需要员工或公司信息, 需要确保 auth/checkActive 成功
      let shouldResetToDefaultScreen = true;
      const catchedIntention = yield select(({ intention }) => intention.catchedIntention);
      let navigation = yield yield call(getNavigation);
      // 测试环境专用意向集成页面
      // http://localhost:3000/?debug
      // https://test-ms-stream.jinjie.tech/?debug
      if (
        [
          "localhost",
          "test-ms-stream.jinjie.tech",
          "192.168.1.85",
        ].includes(window.location.hostname) &&
        window.location.search.indexOf("debug") > -1
      ) {
        yield put({ type: "auth/setLoginInfo", payload: { mcKey: 1 } }); // 假mcKey, 避免去登陆页
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{ name: 'IntentionLauncher' }],
          })
        );
        return false;
      }
      // 有意向参数的情况
      if (catchedIntention) {
        const {
          openType,
          URLOpen,
          URLParams,
          notificationOpen,
          intentionParams,
        } = catchedIntention;
        /*
          # 此处用来放置所有意向处理代码。使用指南：

          1. 如果需要等待登录成功后再执行，需要在另一个 effect 中进行等待（避免阻塞APP跳转页面）

            yield put(createAction('_demoWaitUntilLoggedIn')());

            *_demoWaitUntilLoggedIn(action, { call, put, select, take, fork, all, race }) {
              yield take('auth/lifeCycle_authEnsureValid');
              // ... 这里写需要确保已登录才能执行的代码（登录后，或者已登录重新打开）
            },

          2. 如果需要自行决定前往哪个页面，可将 shouldResetToDefaultScreen 设为 false 避免自动跳转
          shouldResetToDefaultScreen = false
        */

        // 绿端单商品导入黑端
        // [测试方法] 小屏幕+浏览器打开此格式可模拟 http://localhost:3000/?intention=%7B%22action%22%3A%22importSingleProductFromMS%22%2C%22tempGoodsId%22%3A601899546714%2C%22companyName%22%3A%22Something%20Cool%22%2C%22itemRef%22%3A%22FIXED-REF%22%2C%22coverImage%22%3A%22https%3A%2F%2Ftest-ms-cdn.jinjie.tech%2F1011%2FMSH5_7ce57725708b84c0f70ea290318c65d4.jpg%22%7D&mcKey=1_53b70fa9e6cf5511ce6c3b8a0ecc77d7&lang=en
        //          （参数来自测试环境单品导入流程抓包）
        /*
          // 复制到控制台
          const mcKey = "1_a72880be82621ab2b3003b611423afe0"; // required
          const intention = {
            "action": "importSingleProductFromMS", // required
            "tempGoodsId": 602450132780, // required
            "companyName": "2812 测试店铺",
            "itemRef": "202407081635",
            "coverImage": "https://test-ms-cdn.jinjie.tech/2812/MS_5f3329d519a5f3e5539f92a36cef8dca.jpg",
          };
          const url = `http://localhost:3000/?intention=${encodeURIComponent(JSON.stringify(intention))}&mcKey=${mcKey}&lang=en`;
          location.href = url;
        */
        if (intentionParams.action === "importSingleProductFromMS") {
          shouldResetToDefaultScreen = false;
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: 'ImportProductFromMS', params: { catchedIntention } }],
            })
          );
        }
        if (intentionParams.action === "invite") {
          yield put({ type: "dashboard/handleActivateDashboard", payload: intentionParams });
        }
        /*
          // 复制到控制台
          let mcKey = "5_32f88802234e7e69bf25a22e8be355c9"; // required
          let intention = {
            "action": "thirdPartySalesChannelManagement", // required
          };
          let url = `http://localhost:3000/?intention=${encodeURIComponent(JSON.stringify(intention))}&mcKey=${mcKey}&lang=en`;
          console.log("🚀 ~ url:", url);
          location.href = url;
        */
        if (intentionParams.action === "thirdPartySalesChannelManagement") {
          const res = yield yield put({ type: 'auth/checkActive', payload: { sync: true } });
          if (res.success) {
            shouldResetToDefaultScreen = false;
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: 'SalesChannelStack', screen: "SalesChannelList", params: { catchedIntention } }],
              })
            );
          }
        }
      }
      return shouldResetToDefaultScreen;
    },

    *init(action, { call, put, select, take, fork, all, race }) {
      if (Platform.OS === 'web') {
        const URLOpen = window.location.href;
        yield put({
          type: '_setOpenInfoWhenLaunchedOrFocused',
          payload: {
            openType: 'launch',
            URLOpen,
          },
        });
        // 处理初始页面跳转
        const { auth } = yield select(({ auth, intention }) => ({ auth, intention }));
        // 先处理意向，拿到返回值判断是否由处理者自行完成页面路由
        const shouldResetToDefaultScreen = yield yield put({ type: 'handleIntention' });
        // 未登录或登录失效
        if (!auth.isValid) {
          // 若在 MC APP 内，停留在开屏页面，会有个登录失效弹框
          if (Theme.isWebInAPP) {
            // 停留在开屏页并调用 auth/checkActive 触发重新登录弹框
            yield put({ type: 'auth/checkActive' });
          }
          // 若不在 MC APP 内（无论大小屏），前往供货商报表登录页，等待登录成功后，决定跳转到哪个页面
          else {
            const navigation = yield yield call(getNavigation);
            navigation.dispatch(CommonActions.reset({
              index: 0,
              routes: [{ name: 'DashboardLogin' }],
            }));
            // 登录成功后会触发 auth/lifeCycle_authEnsureValid
            yield take('auth/lifeCycle_authEnsureValid');
            if (shouldResetToDefaultScreen) {
              yield put({ type: 'resetToDefaultScreenOfLoggedIn' });
            }
          }
        }
        // 已登录，直接前往登录后的页面
        else if (shouldResetToDefaultScreen) {
          yield put({ type: 'resetToDefaultScreenOfLoggedIn' });
          // 检查登录态、获取账号信息（可能会触发登录态失效，回到登录页）
          yield yield put({ type: 'auth/checkActive', payload: { sync: true } });
        }
      }
      // 开始监听重新登录事件
      yield put({ type: '_watchReLoginAndResetToDefaultHome' });
    },

    *resetToDefaultScreenOfLoggedIn({ payload = {} }, { call, put, select, take, fork, all, race }) {
      // 优先处理快捷导航入参，已处理就跳过其他逻辑
      const handled = yield yield put({ type: '_handleNavigateShortcut' });
      const navigation = yield yield call(getNavigation);
      if (handled) {
        return;
      }
      // 已登录
      // 若在 MC APP 内
      if (Theme.isWebInAPP) {
        // [测试] 浏览器打开此格式可模拟 http://localhost:3000/?key=1_53b70fa9e6cf5511ce6c3b8a0ecc77d7&lang=en&defaultLang=en
        // 登录后默认前往「我的上下游首页」
        navigation.dispatch(CommonActions.reset({
          index: 0,
          routes: [{ name: 'SupplyChainStack', screen: 'SupplyChain' }],
        }));
      }
      // 若不在 MC APP 内，手机屏幕下，前往登录页提示电脑扫码登录
      // else if (Theme.isPhoneSizedWindow) {
      //   // [测试] 小屏幕+浏览器打开此格式可模拟 http://localhost:3000/
      //   navigation.dispatch(CommonActions.reset({
      //     index: 0,
      //     routes: [{ name: 'DashboardLogin' }],
      //   }));
      // }
      // 若不在 MC APP 内，非手机屏幕下（桌面、pad），登录后默认进入报表页
      else {
        // [测试] 大屏幕+浏览器打开此格式可模拟 http://localhost:3000/
        navigation.dispatch(CommonActions.reset({
          index: 0,
          routes: [{ name: 'DashboardReports' }],
        }));
      }
    },

    *_watchReLoginAndResetToDefaultHome({ payload = {} }, { call, put, select, take, fork, all, race }) {
      while (true) {
        yield take('auth/lifeCycle_authEnsureValid');
        yield put({ type: 'resetToDefaultScreenOfLoggedIn' });
      }
    },

    *_setOpenInfoWhenLaunchedOrFocused({ payload = {} }, { call, put, select, take, fork, all, race }) {
      let {
        openType = 'launch',
        URLOpen = null,
        notificationOpen = null,
      } = payload;
      let URLParams = {};
      let catchedIntention = null;
      let intentionParams = null;
      // Compute URLParams
      if (typeof URLOpen === 'string') {
        URLParams = qs.parse(URLOpen.replace(/^.+?(\?|$)/, ''));
        if (URLParams.intention) {
          try {
            intentionParams = JSON.parse(URLParams.intention);
          } catch (error) {
            console.warn(error);
          }
        }
      }
      const openInfo = {
        openType,
        URLOpen,
        URLParams,
        notificationOpen,
      };
      // 识别是否是带意向的打开
      if (intentionParams) {
        catchedIntention = {
          ...JSON.parse(JSON.stringify(openInfo)),
          intentionParams,
        };
      }
      // 写入 state
      yield put({
        type: '_setState',
        payload: {
          ...openInfo,
          catchedIntention,
        },
      });
    },
    /*
      快捷导航入参
      * 用法1：
      ?navigate=SupplyChainStack
      * 用法2：
      ?navigate=encodeURIComponent(JSON.stringify([{name: 'SupplyChainStack', screen: 'SupplyChain'}]))
      ?navigate=%5B%22SupplyChain%22%2C%7B%22screen%22%3A%22B%22%7D%5D
      * 是否需要登录看页面内是否有调用到需要登录的接口，会被自动处理
    */
    *_handleNavigateShortcut({ payload = {} }, { call, put, select, take, fork, all, race }) {
      const navigation = yield yield call(getNavigation);
      const { intention } = yield select(({ auth, intention }) => ({ auth, intention }));
      const routes = parseNavigateShortcut(intention.URLParams?.navigate);
      if (routes) {
        try {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes,
            })
          );
          return true;
        } catch (error) { }
      }
      return false;
    },
  },
};
