import React, { createRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { StyleSheet, View, VirtualizedList, Text, Image } from 'react-native';
import { NavigationPage, Dialog, TouchableWithPosition, Touchable, Empty, Info, Menu, SkeletonBlockSupplier, ScreenLoading, Toast, DateRangePicker, MCSearchBox } from '../../components';
import { Button, LoadingMore, Theme } from "ui-m/rn";
import { CommonActions } from '@react-navigation/native';
import { formatTimeToMD, copyDialogConfirm, getRecipientEmail, singleThreadAsyncFunction, createAsyncQueue } from "../../utils";
import resources from "../../config/resources";
import moment from 'moment';
import { getShortLink } from "../../services/shortUrl";
import { appConf } from '../../config';
import * as daService from '../../services/dataAnalyses';
import SupplierCard from './components/SupplierCard';
import { Dropdown } from 'antd';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map["stream.dashboardViewer.title"];
    },
    renderNavigationRightView() {
      return (
        <View style={{ flexDirection: "row" }} >
          <TouchableWithPosition onPress={this.showMoreMenu}>
            <Image style={{ width: 44, height: 44 }} source={{ uri: resources.navMoreWhiteMC }} />
          </TouchableWithPosition>
          {
            this.props.dashboard.permission.isAdmin && (
              <Touchable onPress={this.handleSelectSupplier}>
                <Image style={{ width: 44, height: 44 }} source={{ uri: resources.navAddWhiteMC }} />
              </Touchable>
            )
          }
        </View>
      );
    },
    primary: true,
    showBackButton: true,
  };

  get dashboardViewer() {
    return this.props.dashboardViewer;
  }

  get noPermission() {
    return this.props.dashboardViewer.noPermission;
  }

  get canLoadMore() {
    const { list: { hasMore }, isListReloading, isListLoadingMore } = this.dashboardViewer;
    return (hasMore && !isListReloading && !isListLoadingMore);
  }

  get sortText() {
    const { sortTypeCode, asc } = this.props.dashboardViewer.list.filter;
    const sortOption = this.sortOptions.find(i => i.value.sortTypeCode === sortTypeCode && i.value.asc === asc);
    return sortOption ? this.props.i18n.map[sortOption.i18nKey] : "";
  }

  get keyword() {
    return this.props.dashboardViewer.list.filter.keyword;
  }

  // 复写 Navigation renderNavigationBar, 用 searchBox 覆盖
  renderNavigationBar = () => {
    const {
      title,
      showBackButton,
      backBtnIcon,
      renderNavigationTitle,
      renderNavigationLeftView,
      renderNavigationRightView,
      statusBarStyle,
      isInModalStack,
      modalPresentation,
      primary,
      hideBottomBorder,
    } = this.props;

    return (
      <View style={{ position: "absolute", top: 0, left: 0, right: 0 }}>
        <NavigationPage.NavigationBar {...{
          navigation: this.props.navigation, // 实现页面切换用
          title: renderNavigationTitle === undefined ? title : renderNavigationTitle.call(this),
          showBackButton,
          backBtnIcon,
          hideBottomBorder,
          leftView: (showBackButton || renderNavigationLeftView === undefined) ? undefined : renderNavigationLeftView.call(this),
          rightView: renderNavigationRightView === undefined ? undefined : renderNavigationRightView.call(this),
          primary,
          statusBarStyle,
          isInModalStack,
          modalPresentation,
          ...this.props.navigationBarProps,
        }} />
        {
          this.state.isSearchMode && (
            <View
              style={{
                position: "absolute",
                top: Theme.statusBarHeight,
                left: 0,
                right: 0,
                height: 44,
              }}
            >
              <MCSearchBox
                value={this.keyword}
                onChangeText={async val => {
                  await this.setKeyword(val || "");
                  this.refreshWithDebounce();
                }}
                onCancel={async () => {
                  await this.setKeyword("");
                  this.refresh();
                  this.setState({
                    isSearchMode: false,
                    isResultEmpty: false,
                    isSearchResultEmpty: false,
                  });
                }}
                onSubmitEditing={() => this.refresh()}
                autoFocus
              />
            </View>
          )
        }
      </View>
    );
  }

  constructor(props) {
    super(props);

    this.sortOptions = [
      {
        i18nKey: "last_added",
        value: { sortTypeCode: "created_date", asc: false },
      },
      {
        i18nKey: "earliest_added",
        value: { sortTypeCode: "created_date", asc: true },
      },
      {
        i18nKey: "mch.list.order.client.name",
        value: { sortTypeCode: "supplierName", asc: true },
      },
      {
        i18nKey: "mch.list.order.client.rvs_name",
        value: { sortTypeCode: "supplierName", asc: false },
      },
    ];
    this.state = {
      timeRange: 7,
      viewableSupplierIds: [],
      isSearchMode: false,
      isResultEmpty: false,
      isSearchResultEmpty: false,
    };

    this._refs = {};
    this.asyncQueue = createAsyncQueue();
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    await this.setKeyword("");
    this.refresh();
  }

  componentWillUnmount() {
    this.removeNavigationListener();
    this.asyncQueue.clear();
  }

  removeNavigationListener = () => {
    if (this._removeNavigationListener) {
      this._removeNavigationListener();
      this._removeNavigationListener = null;
    }
  }

  handleSelectSupplier = async () => {
    const { navigation, route } = this.props;
    this._removeNavigationListener = navigation.addListener("focus", (e) => {
      this.removeNavigationListener();

      const supplier = this.props.route.params.supplier;
      supplier && this.addDashboardViewer(supplier);
    });

    navigation.dispatch(CommonActions.setParams({ supplier: null }));
    navigation.navigate("SelectSupplier", {
      sourceKey: route.key,
    });
  }

  addDashboardViewer = async supplier => {
    ScreenLoading.show();
    const res = await this.props.dispatch({ type: "dashboardViewer/addViewer", payload: supplier.id });
    await this.refresh();
    ScreenLoading.hide();

    if (res.success) {
      Toast.success();
      this.handleSendInviteLink(res.data);
    }
  }

  handleDeleteDashboardViewer = async supplier => {
    const i18n = this.props.i18n;
    return Dialog.show({
      title: i18n.map["cmn.alert.title.confirmation"],
      content: i18n.map["cmn.removeThisRecord"],
      leftBtnText: i18n.map["cmn.btn.cancel"],
      rightBtnText: i18n.map["rt.btn.remove"],
      rightBtnStyle: { color: "#F44336" },
      onLeftClick: () => { },
      onRightClick: async () => this.deleteDashboardViewer(supplier),
    });
  }

  deleteDashboardViewer = async supplier => {
    ScreenLoading.show();
    const res = await this.props.dispatch({ type: "dashboardViewer/deleteViewer", payload: supplier.id });
    await this.refresh();
    ScreenLoading.hide();
    res.success && Toast.success();
  }

  showMoreMenu = (position) => {
    const i18n = this.props.i18n;
    Menu.show(
      position,
      [
        {
          icon: resources.menuSearch,
          title: i18n.map["mch.plhd.search"],
          onPress: () => {
            this.setState({
              isSearchMode: true,
              isResultEmpty: false,
              isSearchResultEmpty: false,
            });
          },
        },
        {
          icon: resources.menuExport,
          title: i18n.map["stream.exportToExcel"],
          onPress: this.handleExportToExcel,
        },
      ],
      { align: 'end', showArrow: true, shadow: true, paddingCorner: 18 }
    );
  }

  // 导出供货商业绩总览
  handleExportToExcel = async () => {
    const email = await getRecipientEmail();
    if (!email) {
      return;
    }

    ScreenLoading.show();
    const [startTime, endTime] = this.getExportToExcelTimeRange();
    const res = await this.props.dispatch({
      type: "dashboardViewer/exportToExcel",
      payload: { email, startTime, endTime },
    });
    ScreenLoading.hide();
    if (res.success) {
      Toast.success();
    }
    daService.create({ type: "DashboardViewer_exportToExcel" }); // 打点
  }

  getExportToExcelTimeRange = () => {
    const timeRange = this.state.timeRange;

    if (Array.isArray(timeRange)) {
      return timeRange;
    }

    let startTime;
    const endTime = moment().endOf("day");
    if (typeof timeRange === "number" && Math.floor(timeRange) > 0) {
      const days = Math.floor(timeRange);
      startTime = moment().subtract(days - 1, "days").startOf("day");
    }
    // 发送报表时, 若时间范围为本周或本月, 则结束时间设为今天结束
    else if (timeRange === "day") {
      startTime = moment().startOf("day");
    } else if (timeRange === "week") {
      startTime = moment().isoWeekday(1).startOf("day");
    } else if (timeRange === "month") {
      startTime = moment().startOf("month").startOf("day");
    }

    return [+startTime, +endTime];
  }

  // 发送邀请链接
  handleSendInviteLink = async supplier => {
    const i18n = this.props.i18n;
    const intention = { action: 'invite', code: supplier.invitationCode };
    let url = `${appConf.webEntryURL}?navigate=DashboardReports&intention=${encodeURIComponent(JSON.stringify(intention))}`;
    ScreenLoading.show();
    const shortUrlResponse = await getShortLink({ url });
    ScreenLoading.hide();
    if (shortUrlResponse.success) {
      url = shortUrlResponse.data.url;
    }
    const content = `${i18n.map["stream.dashboardViewer.sendActivation.link"]}: \n${url}`;

    return copyDialogConfirm({
      title: i18n.map["stream.dashboardViewer.sendLink.title"],
      copyContent: content,
      content: (
        <View>
          <Text>{i18n.map["stream.dashboardViewer.sendActivation.link"]}: </Text>
          <Text style={{ color: "#A0A0A0", textAlign: "left", wordBreak: "break-all" }}>{url}</Text>
        </View>
      ),
    });
  }

  handlePreviewUpstreamDashboard = singleThreadAsyncFunction(async supplier => {
    await this.props.dispatch({ type: "dashboard/clearCurrentPreview" });
    this.props.navigation.navigate("DashboardReports", {
      fromOtherPage: true,
      dashboardInitParams: {
        currentStreamType: "up",
        upstreamId: supplier?.supplierId,
      },
    });
  })

  onViewableItemsChanged = ({ viewableItems }) => {
    const viewableSupplierIds = viewableItems.filter(i => i.isViewable).map(i => i.item.supplierId);
    this.setState({ viewableSupplierIds });
    for (const supplierId of viewableSupplierIds) {
      if (this._refs[supplierId]?.current?.isLoading?.()) {
        this.asyncQueue.setPriority(supplierId);
      }
    }
  }

  changeTimeRange = timeRange => {
    if (timeRange) {
      return this.setState({ timeRange });
    }

    // 固定日期范围
    DateRangePicker.show({
      dateRange: Array.isArray(this.state.timeRange) ? this.state.timeRange : [new Date(), new Date()],
      maxDateRangeLength: 365,
      onConfirm: times => {
        this.setState({
          timeRange: [
            +moment(times[0]).startOf("day"),
            +moment(times[1]).endOf("day"),
          ],
        });
      },
    });
  }

  handleRequestOverview = payload => {
    return this.asyncQueue.unshift(async () => {
      const res = await this.props.dispatch({
        type: "dashboardViewer/getSupplierOverview",
        payload,
      });
      return res.success ? res.data : null;
    }, payload.supplierId);
  }

  setKeyword = keyword => {
    return this.props.dispatch({ type: "dashboardViewer/setFilter", payload: { keyword } });
  }

  onSortOptionChange = async sortOption => {
    await this.props.dispatch({ type: "dashboardViewer/setSortOption", payload: sortOption });
    this.refresh();
  }

  refreshWithDebounce = _.debounce(async () => {
    const keyword = this.keyword;
    this.prevRequestKeyword = keyword;
    await this.props.dispatch({ type: "dashboardViewer/reloadList" });
    if (this.dashboardViewer.list.resultSet.length === 0) {
      const currentKeyword = this.keyword;
      if (keyword === currentKeyword) {
        this.setState({ isSearchResultEmpty: true });
      }
    }
    if (!keyword) {
      this.setState({ isResultEmpty: this.dashboardViewer.list.resultSet.length === 0 });
    }
  }, 1000)

  refresh = () => {
    this.refreshWithDebounce();
    this.refreshWithDebounce.flush();
  }

  renderPage() {
    const { i18n, dashboard: { permission } } = this.props;
    const { viewableSupplierIds, timeRange, isSearchMode, isResultEmpty, isSearchResultEmpty } = this.state;
    const noPermission = this.noPermission;

    const { isListReloading, isListLoadingMore, list } = this.dashboardViewer;
    const wrapStyle = { flex: 1 };
    // 无keyword的列表结果的空态, 背景为白色 (注意 清除keyword 到 请求结果返回 的间隙)
    if (!isSearchMode && isResultEmpty) {
      console.log(1);
      wrapStyle.backgroundColor = "white";
    }

    let pickDateTitle = i18n.map["cmn.pickDate"];
    if (Array.isArray(timeRange)) {
      pickDateTitle = `${formatTimeToMD(timeRange[0])} ~ ${formatTimeToMD(timeRange[1])}`;
    }

    const supplierIds = list.resultSet.map(i => `${i.supplierId}`);
    Object.keys(this._refs).map(supplierId => {
      if (!supplierIds.includes(supplierId)) {
        // 去除已不存在的ref引用
        this._refs[supplierId] = null;
      }
    });
    supplierIds.forEach(supplierId => {
      if (!this._refs[supplierId]) {
        this._refs[supplierId] = createRef();
      }
    });

    return (
      <View style={{ flex: 1 }} >
        <View style={styles.timeRangeWrap}>
          <Touchable style={{ marginHorizontal: 15, flex: 1 }} onPress={() => this.changeTimeRange()}>
            <Text style={[styles.timeRangeText, Array.isArray(timeRange) && styles.hover]}>{pickDateTitle}</Text>
          </Touchable>
          <View style={{ flexDirection: "row", alignItems: "center", flexShrink: 0 }}>
            <Touchable onPress={() => this.changeTimeRange(7)} style={styles.timeRangeItem}>
              <Text style={[styles.timeRangeText, timeRange === 7 && styles.hover]}>{i18n.map["cmn.days"].replace("%s", 7)}</Text>
            </Touchable>
            <Touchable onPress={() => this.changeTimeRange(15)} style={styles.timeRangeItem}>
              <Text style={[styles.timeRangeText, timeRange === 15 && styles.hover]}>{i18n.map["cmn.days"].replace("%s", 15)}</Text>
            </Touchable>
            <Touchable onPress={() => this.changeTimeRange(30)} style={styles.timeRangeItem}>
              <Text style={[styles.timeRangeText, timeRange === 30 && styles.hover]}>{i18n.map["cmn.days"].replace("%s", 30)}</Text>
            </Touchable>
          </View>
        </View>
        <View style={styles.sorterWrap}>
          <Dropdown
            placement="bottom"
            trigger={['click']}
            menu={{
              items: this.sortOptions.map((i, ii) => ({
                key: `dashboardViewerSorterOption${ii}`, // 确保key里包含dashboardViewerSorterOption, 此处利用属性选择器改写指定项样式
                label: (
                  <a onClick={() => this.onSortOptionChange(i.value)}>
                    {
                      ii > 0 && (
                        <View style={styles.sortOptionDivider} />
                      )
                    }
                    <View style={styles.sortOptionWrap}>
                      <Text
                        style={[
                          styles.sorterText,
                          i18n.map[i.i18nKey] === this.sortText && styles.sorterTextHighlight,
                        ]}
                      >{i18n.map[i.i18nKey]}</Text>
                    </View>
                  </a>
                ),
              })),
            }}
          >
            <View style={styles.sorter} >
              <Text style={styles.sorterText}>{this.sortText}</Text>
              <View style={styles.sorterIconWrap}>
                <Image
                  source={resources.mcSortArrow}
                  style={{ width: 7, height: 12 }}
                />
              </View>
            </View>
          </Dropdown>
        </View>
        {
          // 目前还不会被看见
          noPermission ? (
            <View style={styles.noPermissionWrap}>
              <Empty.Lock text={i18n.map.only_the_mc_stor_121} />
            </View>
          ) : (
            isListReloading ? (<SkeletonBlockSupplier />) : (
              <VirtualizedList
                style={wrapStyle}
                keyboardDismissMode="on-drag"
                data={list.resultSet}
                initialNumToRender={20}
                keyExtractor={i => `${i.id}`}
                getItemCount={() => list.resultSet.length}
                getItem={(data, index) => ({ ...data[index], _index: index })}
                onViewableItemsChanged={this.onViewableItemsChanged}
                renderItem={({ item }) => (
                  <View style={{ marginTop: 10, marginHorizontal: 10 }}>
                    <SupplierCard
                      ref={this._refs[item.supplierId]}
                      supplier={item}
                      timeRange={timeRange}
                      handleDeleteDashboardViewer={this.handleDeleteDashboardViewer}
                      handleSendInviteLink={this.handleSendInviteLink}
                      handlePreviewUpstreamDashboard={this.handlePreviewUpstreamDashboard}
                      canUpdateOverview={viewableSupplierIds.includes(item.supplierId)}
                      requestOverview={this.handleRequestOverview}
                    />
                  </View>
                )}
                ListEmptyComponent={(
                  <View style={styles.emptyWrap}>
                    {
                      isSearchMode && isSearchResultEmpty && (
                        <Empty containerStyle={styles.emptyContainer} />
                      )
                    }
                    {
                      !isSearchMode && isResultEmpty && (
                        <>
                          <Empty
                            containerStyle={styles.emptyContainer}
                            text={i18n.map["stream.dashboardViewer.empty.title"]}
                          />
                          {
                            permission.isAdmin && (
                              <>
                                <Info containerStyle={{ marginBottom: 10 }} text={i18n.map["stream.dashboardViewer.empty.info"]} />
                                <Button primary onPress={this.handleSelectSupplier}>{i18n.map["stream.dashboardViewer.grantAccess"]}</Button>
                                <Touchable
                                  style={styles.linkWrap}
                                  hitSlop={{ top: 3, bottom: 3, left: 6, right: 6 }}
                                  onPress={this.handlePreviewUpstreamDashboard}
                                >
                                  <Text style={styles.link}>{i18n.map["stream.dashboard.preiew"]}</Text>
                                </Touchable>
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </View>
                )}
                ListFooterComponent={(
                  !list.resultSet.length ? null : (
                    list.hasMore ? (
                      <LoadingMore loading={list.hasMore} title={i18n.map["mch.toast.loading"]} />
                    ) : (
                      <LoadingMore loading={false} title={i18n.map["cmn.no_more_content"]} />
                    )
                  )
                )}
                onEndReached={() => {
                  this.canLoadMore && this.props.dispatch({ type: "dashboardViewer/loadMore" });
                }}
                onEndReachedThreshold={0.1}
              />
            )
          )
        }
      </View>
    );
  }
}

const styles = StyleSheet.create({
  timeRangeWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 4,
    paddingBottom: 10,
    backgroundColor: "#1E2023",
  },
  timeRangeItem: {
    marginRight: 24,
    minWidth: 54,
  },
  timeRangeText: {
    fontSize: 13,
    lineHeight: 18,
    color: "#979797",
  },
  hover: {
    color: "#FFB43A",
  },
  sorterWrap: {
    backgroundColor: "#1E2023",
    paddingHorizontal: 10,
    paddingTop: 4,
    paddingBottom: 12,
    alignItems: "stretch",
  },
  sorter: {
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    borderRadius: 4,
    backgroundColor: "#fff",
  },
  sorterIconWrap: {
    width: 35,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    borderRightWidth: 0.6,
    borderColor: "#1F2327",
  },
  sortOptionWrap: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
  },
  sortOptionDivider: {
    width: "100%",
    height: 0.5,
    backgroundColor: "#D5D5D5",
  },
  sorterText: {
    color: "#333333",
    fontSize: 13,
    lineHeight: 19,
  },
  sorterTextHighlight: {
    color: "#0076FF",
  },
  emptyWrap: {
    paddingTop: 68,
    paddingHorizontal: 15,
  },
  emptyContainer: {
    marginBottom: 108,
    alignSelf: "center",
  },
  linkWrap: {
    marginTop: 20,
    alignItems: "center",
  },
  link: {
    fontSize: 14,
    lineHeight: 17,
    color: "#0076FF",
    textAlign: "center",
  },
  noPermissionWrap: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 240,
  },
});

export default connect((store) => _.pick(store, [
  'i18n',
  'dashboardViewer',
  'dashboard',
]))(Page);
