import React from 'react';
import _ from 'lodash';
import { StyleSheet, View, Text, Image } from 'react-native';
import { connect } from 'react-redux';
import { DashboardNavigationPage, NavigationBar, DashboardPanel, Empty, Touchable, TouchableWithPosition, Menu, FormItem, ScreenLoading } from '../../components';
import { copyDialogConfirm, createNavigateShortcut } from '../../utils';
import resources from '../../config/resources';
import { Select, Spin } from 'antd';
import { ScrollView } from 'react-native-gesture-handler';
import { Theme } from "ui-m/rn";
import { getCompanyId } from '../../utils/globalUtils';
import * as daService from '../../services/dataAnalyses';
import { callNativeAsyncFunc, NativeFuncs } from "../../utils/mcAppJsSDK";
import { getShortLink } from "../../services/shortUrl";

class Page extends DashboardNavigationPage {

  static defaultProps = {
    ...DashboardNavigationPage.defaultProps,
    navigationBarProps: {
      statusBarInsets: true,
    },
    documentTitle() {
      return this.props.dashboard.currentDashboard.displayTitle;
    },
    renderNavigationTitle() {
      return this.props.i18n.map["stream.dashboard.pc.title"];
    },
    renderNavigationLeftView() {
      const fromOtherPage = this.props.route.params?.fromOtherPage;

      return fromOtherPage ? (
        <NavigationBar.BackButton icon="back" navigation={this.props.navigation} />
      ) : (
        <NavigationBar.Button icon="close" onPress={() => {
          callNativeAsyncFunc(NativeFuncs.SYSTEM.CLOSE);
        }} />
      );
    },
    renderNavigationRightView() {

      return (
        <View style={{ flexDirection: "row" }} >
          <TouchableWithPosition onPress={this.showMoreMenu}>
            <Image style={{ width: 40, height: 40 }} source={{ uri: resources.navMoreWhite }} />
          </TouchableWithPosition>
        </View>
      );
    },
    renderHeaderTitle() {
      const { dispatch, dashboard } = this.props;
      const currentDashboardTitle = dashboard.currentDashboard.displayTitle;
      return currentDashboardTitle ? (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ fontSize: 15, lineHeight: 22, opacity: 0.9 }}>{currentDashboardTitle}</Text>
          <Touchable onPress={() => dispatch({ type: "dashboard/refreshPanels" })}><Image style={{ width: 30, height: 30 }} source={{ uri: resources.refreshCircle }} /></Touchable>
        </View>
      ) : null;
    },
    renderHeaderRightView() {
      const { i18n, dispatch, dashboard, dashboardSupplier } = this.props;
      const {
        currentStreamType,

        isLoadingDownstreams,
        downstreams,
        currentDownstream,

        currentUpstream,

        permission,
      } = dashboard;

      const {
        list: { hasMore, filter },
        isListReloading,
        isListLoadingMore,
      } = dashboardSupplier;

      const isDashboardSupplierCanLoadMore = (hasMore && !isListReloading && !isListLoadingMore);
      const allowViewUpstreams = permission.allowViewUpstreams;
      const allowViewDownstreams = permission.allowViewDownstreams;
      const streamTypeOptions = [
        allowViewUpstreams && { label: i18n.map["stream.dashboard.viewMySuppliers"], value: "up" },
        allowViewDownstreams && { label: i18n.map["stream.dashboard.viewMineAtSalesChannels"], value: "down" },
      ].filter(i => i);

      return (
        <View
          style={{
            paddingBottom: Theme.isPhoneSizedWindow ? 13 : 0,
            backgroundColor: "#fff",
          }}
        >
          <FormItem.Group
            style={Theme.isPhoneSizedWindow && { paddingHorizontal: 12 }}
            getLayoutConfig={params => {
              return (
                params.isPhoneSizedWindow ? {
                  direction: "column",
                  align: "right",
                  gap: 10,
                } : {
                  direction: "row",
                  align: "right",
                  gap: 10,
                }
              );
            }}
          >
            {
              !!streamTypeOptions.length && (
                <FormItem label={i18n.map.viewpoint} labelStyle={{ opacity: 0.8 }} >
                  <Select
                    style={{ width: 200 }}
                    value={currentStreamType}
                    options={streamTypeOptions}
                    onChange={(v, o) => dispatch({ type: "dashboard/changeCurrentStreamType", payload: v })}
                  />
                </FormItem>
              )
            }
            {
              currentStreamType === "up" && allowViewUpstreams && (
                <FormItem label={i18n.map["stream.currentSupplier"]} labelStyle={{ opacity: 0.8 }} >
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    searchValue={filter.keyword}
                    onSearch={async v => {
                      await dispatch({ type: "dashboardSupplier/setFilter", payload: { keyword: v } });
                      this.reloadDashboardSupplier();
                    }}
                    value={currentUpstream.supplierName || " "} // options里没有对应的项,如果label会显示value的值,供货商名称不会重复,此处使用name
                    // options={dashboardSupplier.list.resultSet.map(i => ({
                    //   label: i.supplierName,
                    //   value: i.supplierName || " ",
                    //   data: i,
                    // }))}
                    onChange={(v, o) => {
                      dispatch({ type: "dashboard/setCurrentUpstream", payload: o.data });
                    }}
                    onPopupScroll={({ target }) => {
                      const bottomDistance = target.scrollHeight - target.scrollTop - target.offsetHeight;
                      (bottomDistance < 50) && isDashboardSupplierCanLoadMore && dispatch({ type: "dashboardSupplier/loadMore" });
                    }}
                    filterOption={false}
                    loading={isListLoadingMore || isListReloading}
                  >
                    {
                      dashboardSupplier.list.resultSet.map(i => (
                        <Select.Option key={i.supplierName} value={i.supplierName} data={i}>
                          <View style={{ flexDirection: "row", alignItems: "center", height: 30 }}>
                            <Text style={{ fontSize: 14, lineHeight: 22 }}>{i.supplierName}</Text>
                            {i.activeStatus === "activated" && <Image style={{ width: 16, height: 16, marginLeft: 4 }} source={{ uri: resources.dashboardActivated }} />}
                            {i.activeStatus === "pending" && <Image style={{ width: 16, height: 16, marginLeft: 4 }} source={{ uri: resources.dashboardPending }} />}
                          </View>
                        </Select.Option>
                      ))
                    }
                  </Select>
                </FormItem>
              )
            }
            {
              currentStreamType === "down" && allowViewDownstreams && (
                <FormItem label={i18n.map["stream.currentDownstreamStore"]} labelStyle={{ opacity: 0.8 }}>
                  <Select
                    style={{ width: 200 }}
                    value={currentDownstream.id}
                    options={downstreams.map(i => ({ label: i.companyName, value: i.id, data: i }))}
                    placeholder={i18n.map["stream.dashboard.noStore"]}
                    onChange={(v, o) => dispatch({ type: "dashboard/setCurrentDownstream", payload: o.data })}
                    loading={isLoadingDownstreams}
                  />
                </FormItem>
              )
            }
          </FormItem.Group>
        </View>
      );
    },
  };

  static propTypes = {
    ...DashboardNavigationPage.propTypes,
    // // 路由入参声明
    // route: PropTypes.shape({
    //   params: PropTypes.exact({
    //     id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     shopId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     siteUrlFull: PropTypes.string.required,
    //     profile: PropTypes.object,
    //   }),
    // }),
  }

  componentDidMount() {
    super.componentDidMount();
    daService.create({ type: "DashboardReports_viewed" });
    if (getCompanyId()) {
      this.props.dispatch({ type: "dashboardSupplier/reloadList" });
      const dashboardInitParams = this.props.route.params?.dashboardInitParams;
      if (dashboardInitParams) {
        this.props.dispatch({
          type: "dashboard/init",
          payload: {
            smartMode: false,
            ...dashboardInitParams,
          },
        });
      } else {
        this.props.dispatch({ type: "dashboard/init" });
      }
    }
  }

  // noPermission | noStore | normal | null
  get contentType() {
    const { dashboard, dashboardSupplier } = this.props;
    const {
      currentDashboard,

      currentStreamType,

      isLoadedDownstreams,
      downstreams,
      currentDownstream,

      currentUpstream,

      permission,
    } = dashboard;

    const {
      list: { resultSet },
      isListReloading,
      isListLoadingMore,
    } = dashboardSupplier;

    if (JSON.stringify(permission) === "{}") {
      return null;
    }

    if (currentStreamType === "up") {
      if (!permission.allowViewUpstreams) {
        return "noPermission";
      }

      if ((!isListReloading && !isListLoadingMore) && !resultSet.length) {
        return "upEmpty";
      }

      if (currentUpstream.supplierId && currentDashboard.id) {
        return "normal";
      }

    } else {
      if (!permission.allowViewDownstreams) {
        return "noPermission";
      }

      if (isLoadedDownstreams && !downstreams.length) {
        return "downEmpty";
      }

      if (currentDownstream.id && currentDashboard.id) {
        return "normal";
      }

    }

    return null;
  }

  reloadDashboardSupplier = _.debounce(() => {
    this.props.dispatch({ type: "dashboardSupplier/reloadList" });
  }, 300)

  showMoreMenu = position => {
    Menu.show(
      position,
      [
        {
          // icon: resources.ashbin,
          title: this.props.i18n.map.open_on_pc,
          onPress: this.openOnPC,
        },
      ],
      { align: 'end', showArrow: true, shadow: true }
    );
  }

  openOnPC = async () => {
    const { dashboard } = this.props;
    const {
      currentDashboard,
      currentStreamType,
      currentUpstream,
      currentDownstream,
    } = dashboard;

    let up, view, report;
    up = currentStreamType === "up" ? 1 : 0;
    view = up ? currentUpstream?.supplierId : currentDownstream?.id;
    report = currentDashboard?.id;

    let url = `${window.location.origin}?navigate=${createNavigateShortcut("DashboardReports")}&up=${up}&view=${view}&report=${report}`;
    ScreenLoading.show();
    const shortUrlResponse = await getShortLink({ url });
    ScreenLoading.hide();

    if (shortUrlResponse.success) {
      url = shortUrlResponse.data.url;
    }

    // url 换行有问题, 得确保 wordBreak: "break-all"
    copyDialogConfirm({
      title: this.props.i18n.map["stream.dashboardLogin.copyLink.title"],
      contentStyle: { textAlign: "left", wordBreak: "break-all" },
      content: url,
    });
  }

  renderPage = () => {
    const { i18n, dashboard } = this.props;
    const {
      isLoadingDashboards,
      currentDashboard,
      currentStreamType,
      currentUpstream,
      currentDownstream,
    } = dashboard;

    const panels = currentDashboard.panels || [];

    const contentType = this.contentType;
    const isPhoneSizedWindow = Theme.isPhoneSizedWindow;

    return (
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ minHeight: "100%" }} >
        {isPhoneSizedWindow && (
          <View style={{ marginBottom: 12 }}>{this.props.renderHeaderRightView.call(this)}</View>
        )}
        <Spin spinning={isLoadingDashboards}>
          <DashboardNavigationPage.PageContent
            style={{ width: "100%" }}
            siblingsHeightTotal={Theme.isPhoneSizedWindow ? 116 : 126}
            forceDisplayFooter
          >
            {contentType === "noPermission" && (
              <View style={styles.centerWrap}>
                <Empty.Lock text={i18n.map.only_the_mc_stor_121} />
              </View>
            )}
            {contentType === "upEmpty" && (
              <View style={styles.centerWrap}>
                <Empty text={i18n.map["stream.dashboardViewer.empty.title"]} />
              </View>
            )}
            {contentType === "downEmpty" && (
              <View style={styles.centerWrap}>
                <Empty text={i18n.map["stream.dashboard.empty"]} />
              </View>
            )}
            {contentType === "normal" && (
              <DashboardPanel.Group
                key={`${currentDashboard.id}`}
                panels={panels}
                streamType={currentStreamType}
                stream={currentStreamType === "up" ? currentUpstream : currentDownstream}
              />
            )}
          </DashboardNavigationPage.PageContent>
        </Spin>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  centerWrap: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 240,
  },
});

export default connect((store) => _.pick(store, [
  'i18n',
  'app',
  'dashboard',
  'dashboardSupplier',
]))(Page);
