import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking } from 'react-native';
import { Breadcrumb, Layout, Space as AntSpace, Dropdown } from 'antd';
import { GlobalOutlined } from '../../config/antdIcons';
import { connect } from 'react-redux';
import langOptionConf from '../../config/langOptionConf.js';
import { createAction } from '../../utils';
import resources from '../../config/resources.js';

export default connect((store) => _.pick(store, [
  'i18n',
  'menu',
]))((props)=>{
  const { dispatch, i18n, height, style } = props;
  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: langOptionConf.langOptions.map(_=>({
          key: _.lang,
          label: <a onClick={()=>{
            dispatch(createAction('i18n/switchLang')({
              useDeviceLang: false,
              currentLang: _.lang,
            }));
          }}>
            <Text>{_.name}</Text>
          </a>,
        })),
        selectable: true,
        defaultSelectedKeys: [i18n.currentLang],
      }}
    >
      <View style={[{justifyContent: 'center', height: height, lineHeight: height}, style]}>
        {/* <GlobalOutlined style={{fontSize: '18px', color: '#fff'}} /> */}
        <Image source={resources.dashboardLang} style={{ width: 24, height: 24 }} />
      </View>
    </Dropdown>
  );
});
