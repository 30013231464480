export { default as global } from './global';
// 供货商列表
export { default as supplier } from './supplier';
export { default as dashboardSupplier } from './dashboardSupplier'; // 报表查看上有供货商列表
// 允许看报表的供货商
export { default as dashboardViewer } from './dashboardViewer';
// 报表
export { default as dashboard } from './dashboard';
// 偏好设置
export { default as preference } from "./preference";
// 销售渠道
export { default as salesChannel } from "./salesChannel";
