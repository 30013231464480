import React from 'react';
import { View, StyleSheet, AppState, StatusBar, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { Teaset } from 'ui-m/rn';
const { Theme, NavigationBar: TeaNavigationBar } = Teaset;

import {Provider} from './context';
import NavigationButton from './NavigationButton';
import BackButton from './BackButton';

StatusBar.setBarStyle('dark-content');
// 监听 APP 重新聚焦动作
AppState.addEventListener('change', async (nextAppState) => {
  if ( nextAppState === 'active' ) {
    StatusBar.setBarStyle('dark-content');
  }
});

export default class NavigationBar extends React.PureComponent {

  static Button = NavigationButton

  static BackButton = BackButton

  static propTypes = {
    // React-Navigation
    navigation: PropTypes.object,
    // 类型
    primary: PropTypes.bool,
    normal: PropTypes.bool,
    // 配置
    showBackButton: PropTypes.bool,
    backBtnIcon: PropTypes.any,
    hideBottomBorder: PropTypes.bool,
  }

  static defaultProps = {
    primary: false,
    normal: true,
    title: undefined,
    leftView: undefined,
    rightView: undefined,
    showBackButton: false,
    hideBottomBorder: false,
    style: undefined,
    titleStyle: undefined,
  }

  static MODAL_BAR_TOP_SPACE = 14

  render (){
    const {
      navigation,
      primary,
      normal,
      title,
      leftView,
      rightView,
      showBackButton,
      backBtnIcon,
      statusBarStyle,
      hideBottomBorder,
      isInModalStack,
      modalPresentation,
      style,
      titleStyle,
      ...rest
    } = this.props;
    // 类型
    let type;
    if ( primary ) {
      type = 'primary';
    } else {
      type = 'normal';
    }

    const MODAL_BAR_TOP_SPACE = this.constructor.MODAL_BAR_TOP_SPACE;
    const autoBackBtnIcon = modalPresentation ? 'close' : 'back';
    const finalBackBtnIcon = backBtnIcon === undefined ? autoBackBtnIcon : backBtnIcon;

    return <Provider value={{
      type,
    }}>
      <TeaNavigationBar
        type="ios"
        title={title}
        statusBarStyle={typeof statusBarStyle === 'function' ? statusBarStyle(this.props) : (statusBarStyle || (primary ? 'dark-content' : 'dark-content'))}
        statusBarInsets={!isInModalStack}
        statusBarColor={primary ? '#1E2023' : '#FFFFFF'}
        style={[
          styles.style,
          styles[`style_type_${type}`],
          styles[`style_type_hideBottomBorder_${hideBottomBorder}`],
          isInModalStack ? { paddingTop: MODAL_BAR_TOP_SPACE, height: Theme.navBarContentHeight + MODAL_BAR_TOP_SPACE } : null,
          style,
        ]}
        titleStyle={[
          styles.titleStyle,
          styles[`titleStyle_type_${type}`],
          styles[`titleStyle_type_${type}_hasLeftView_${!!(leftView || showBackButton)}`],
          isInModalStack ? { top: MODAL_BAR_TOP_SPACE } : null,
          titleStyle,
        ]}
        leftView={<View style={styles.leftView}>
          {leftView || (showBackButton && <BackButton icon={finalBackBtnIcon} navigation={navigation}/>)}
        </View>}
        rightView={<View style={styles.rightView}>
          {rightView}
        </View>}
        {...rest}
      />
    </Provider>;
  }
}


const styles = StyleSheet.create({
  // style: {
  // },
  style_type_normal: {
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 0,
    borderBottomColor: 'rgba(0,0,0,0.15)',
  },
  style_type_primary: {
    backgroundColor: '#1E2023',
    borderBottomWidth: 0,
  },
  style_type_hideBottomBorder_true: {
    borderBottomWidth: 0,
  },

  titleStyle: {
    fontWeight: 500,
    paddingTop: 0,
    letterSpacing: 1,
  },
  titleStyle_type_normal: {
    color: '#221503',
    fontSize: 16,
    lineHeight: 20,
    marginTop: -3,
  },
  titleStyle_type_primary: {
    color: '#fff',
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'center',
    marginTop: -3,
  },
  titleStyle_type_primary_hasLeftView_false: {
    paddingLeft: 12,
  },

  rightView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 8,
  },
  leftView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 8,
  },
});
