import localize from 'crow/utils/localize';
import moment from 'moment';

export const toLocaleNumStr = (amount, currency, options = {}) => {
  options = Object.assign({ style: 'currency', currency: currency } ,options);
  return localize.toLocaleNumStr(amount, options);
};

// 数字型
// 舍去末尾0, 再本地化
const formatNumber = numOrStr => {
  return localize.toLocaleNumStr(numOrStr, {
    trailingZeroDisplay: "stripIfInteger",
  });
};

// 金额型 无货币符号
// 保留两位小数, 再本地化
const formatAmount = numOrStr => {
  // options = Object.assign({ currency } ,options);
  return localize.toLocaleNumStr(numOrStr);
};

// 整数型
// 只保留整数部分, 再本地化
const formatInteger = numOrStr => {
  return localize.toLocaleNumStr(Math.floor(numOrStr), {
    minimumFractionDigits: 0,
  });
};

// 日期型
const formatDate = timestamp => {
  return moment(timestamp).format("L");
};

// 日期时间型
const formatDatetime = timestamp => {
  return moment(timestamp).format("L LTS");
};

export const formatText = {
  amount: formatAmount,
  int: formatInteger,
  num: formatNumber,
  date: formatDate,
  datetime: formatDatetime,
};
