import React, { useState } from "react";
import { View, Text } from "react-native";
import { getState, dispatch, getMcKey } from "./globalUtils";
import { Modal, Button } from "antd";
import { Teaset } from 'ui-m/rn';
const { Overlay } = Teaset;
import { ContactUs, ScreenLoading, Dialog } from "../components";
import { replaceSearchKey } from "./url";
import { useSelector } from "react-redux";
import { CloseCircleOutlined } from "../config/antdIcons";
import Storage from "./storage";

let invitationCode = null;

// promise风格
export const activateDashboard = params => new Promise(async resolve => {
  const { code } = params;
  if (!getMcKey()) {
    resolve("未登录");
    return;
  }
  if (!code) {
    resolve("无code");
    return;
  }
  if (invitationCode === code) {
    resolve("接受中");
    return;
  }

  // 先存下，避免多次触发
  invitationCode = code;
  // await new Promise(r => setTimeout(r, 1000));
  const res = await dispatch({ type: "dashboard/activateDashboard", payload: code });
  // const res = { success: true };
  // const res = { success: false };
  // const res = { success: false, errorCode: "380004" };
  // const res = { success: false, errorCode: "380005" };
  // const res = {
  //   success: !Math.floor(Math.random() * 4),
  //   errorCode: Math.floor(Math.random() * 2) ? "380004" : "",
  // };

  resolve(res);

  // 380002: 已激活此下游 不提示
  // 380004: 数量达上限
  // 380005: 此链接已被激活 不提示
  if (res.success || +res.errorCode === 380005) {
    replaceSearchKey("intention"); // 去除url中的code

    // 换成刚激活的下游
    res.success && referDashboardViewer(res.data);
  }

  if (res.success) {
    return showSuccess(res, params);
  }
  if (+res.errorCode === 380002 || +res.errorCode === 380005) {
    return;
  }
  if (+res.errorCode === 380004) {
    return showLimit(res, params);
  }
  return showFail(res, params);
});

const referDashboardViewer = async viewer => {
  ScreenLoading.show();
  await dispatch({
    type: "dashboard/updateDownstreams",
  });
  await dispatch({
    type: "dashboard/setCurrentDownstream",
    payload: viewer,
  });
  ScreenLoading.hide();
  dispatch({
    type: "dashboard/setState",
    payload: {
      currentStreamType: "down",
    },
  });
};

const showSuccess = (res, params) => {
  const { i18n } = getState();

  Modal.success({
    title: i18n.map["stream.activateDashboard.success.title"].replace("%s", res?.data?.companyName),
    onOk: () => {

    },
  });
};

const showFail = (res, params) => {
  const { i18n } = getState();
  Modal.error({
    title: i18n.map["stream.activateDashboard.fail.title"].replace("%s", res?.errorInfo?.companyName),
    content: res.errorMessage || i18n.map["stream.activateDashboard.fail.content.default"],
    okCancel: true, // error没有cancel按钮,文档未提到,可能会废弃
    okText: i18n.map["cmn.btn.retry"],
    cancelText: i18n.map["cmn.btn.close"],
    onOk: () => {
      invitationCode = null;
      activateDashboard(params);
    },
  });
};

export const showLimit = (res, params) => {

  const id = Overlay.show(
    <Overlay.View
      style={{ alignItems: "center", paddingTop: 100 }}
      overlayOpacity={0} // 避免和antd的Modal的Mask重叠
    >
      <LimitModal
        res={res}
        params={params}
        close={() => Overlay.hide(id)}
      />
    </Overlay.View>
  );
};

const LimitModal = props => {
  const i18n = useSelector(state => state.i18n);
  const { res, params, close } = props;
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      open={open}
      afterClose={close} // 手动关闭Model后,再触发关闭Overlay,避免丢失关闭动画
      onCancel={() => setOpen(false)}
      footer={(
        <View style={{ flexDirection: "row", justifyContent: "flex-end", flexWrap: "wrap", marginBottom: -8 }}>
          <View style={{ marginLeft: 8, marginBottom: 8 }}>
            <Button
              key="close"
              onClick={() => setOpen(false)}
            >{i18n.map["cmn.btn.close"]}</Button>
          </View>
          <View style={{ marginLeft: 8, marginBottom: 8 }}>
            <Button
              key="retry"
              loading={isLoading}
              onClick={async () => {
                invitationCode = null;
                setIsLoading(true);
                await activateDashboard(params);
                setOpen(false);
              }}
            >{i18n.map["cmn.btn.retry"]}</Button>
          </View>
          <View style={{ marginLeft: 8, marginBottom: 8 }}>
            <Button
              key="contact"
              type="primary"
              onClick={ContactUs.show}
            >{i18n.map["mch.btn.contact_support_staff"]}</Button>
          </View>
        </View>
      )}
    >
      <View style={{ flexDirection: "row", paddingTop: 12, paddingHorizontal: 12 }}>
        <CloseCircleOutlined style={{ color: "#ff4d4f", fontSize: 22, marginRight: 16 }} />
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 16, color: "rgba(0,0,0,.85)", lineHeight: 22.4 }}>{i18n.map["stream.activateDashboard.fail.title"].replace("%s", res?.errorInfo?.companyName)}</Text>
          <Text style={{ marginTop: 8, color: "#ff4d4f", fontSize: 14, lineHeight: 22 }}>{res.errorMessage || i18n.map["stream.activateDashboard.fail.content.default"]}</Text>
        </View>
      </View>
    </Modal>
  );
};


export const getRecipientEmail = (params = {}) => new Promise(async resolve => {
  const { i18n } = getState();
  const storageKey = "exportToExcel_RecipientEmail";
  let mail = params?.mail || await Storage.get(storageKey) || "";

  Dialog.show({
    type: "prompt",
    title: i18n.map["stream.exportToExcel"],
    content: i18n.map["stream.exportToExcel.tip"],
    leftBtnText: i18n.map["cmn.btn.cancel"],
    rightBtnText: i18n.map["cmn.btn.confirm"],
    placeholder: i18n.map["stream.recipientEmail"],
    inputStyle: {
      backgroundColor: "rgba(51,51,51,0.06)",
      height: 30,
      borderRadius: 3.5,
    },
    hideInputBottomBorder: true,
    inputValue: mail,
    handelInput: val => mail = val,
    disabledRightBtnOnPromptEmpty: true,
    onRightClick: () => {
      if (mail) {
        Storage.set(storageKey, mail);
      }
      resolve(mail);
    },
    onLeftClick: () => resolve(),
  });
});
