import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ScrollView } from 'react-native';
import { NavigationPage, LabelBlock, NavigationBar } from '../../components';
import { singleThreadAsyncFunction } from '../../utils';
import { Cell } from "ui-m/rn";
import { callNativeAsyncFunc, NativeFuncs } from '../../utils/mcAppJsSDK';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map["stream.supplyChain.title"];
    },
    primary: true,
    renderNavigationLeftView() {
      return <NavigationBar.Button icon="close" onPress={() => {
        callNativeAsyncFunc(NativeFuncs.SYSTEM.CLOSE);
      }} />;
    },
  };

  static propTypes = {
    ...NavigationPage.propTypes,
    // // 路由入参声明
    // route: PropTypes.shape({
    //   params: PropTypes.exact({
    //     id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     shopId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
    //     siteUrlFull: PropTypes.string.required,
    //     profile: PropTypes.object,
    //   }),
    // }),
  }

  get activatedViewerCount() {
    return this.props.dashboardViewer.activatedViewerCount;
  }

  get activatedDownstreamCount() {
    return this.props.dashboard.downstreams.length;
  }

  componentDidMount() {
    this.props.dispatch({ type: "dashboardViewer/getActivatedViewerCount" });
    this.props.dispatch({ type: "dashboard/updateDownstreams" });
  }

  handlePreviewDownstreamDashboard = singleThreadAsyncFunction(async () => {
    await this.props.dispatch({ type: "dashboard/clearCurrentPreview" });
    this.props.navigation.navigate("DashboardReports", {
      fromOtherPage: true,
      dashboardInitParams: {
        currentStreamType: "down",
      },
    });
  })

  renderPage() {
    const i18n = this.props.i18n;

    return (
      <ScrollView >
        <LabelBlock type="desc" text={i18n.map["stream.supplyChain.mySuppliers.label"]} />
        <Cell
          title={i18n.map["stream.supplyChain.mySuppliers.title"]}
          footer={i18n.map["stream.supplyChain.mySuppliers.subtitle"]}
          afterTitle={this.activatedViewerCount || " "}
          showArrow
          onPress={() => this.props.navigation.navigate("DashboardViewer")}
          underline={false}
        />
        <LabelBlock type="desc" text={i18n.map["stream.supplyChain.mySalesChannels.label"]} />
        <Cell
          title={i18n.map["stream.supplyChain.mySalesChannels.title"]}
          footer={i18n.map["stream.supplyChain.mySalesChannels.subtitle"]}
          afterTitle={this.activatedDownstreamCount || " "}
          showArrow
          onPress={this.handlePreviewDownstreamDashboard}
          underline={false}
        />
      </ScrollView>
    );
  }
}

export default connect((store) => _.pick(store, [
  'i18n',
  'dashboardViewer',
  'dashboard',
  'intention',
]))(Page);
