import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, StyleSheet, Platform } from 'react-native';
import { Row, Teaset, Theme } from 'ui-m/rn';
import DashboardNavigationBar from '../NavigationBar/DashboardNavigationBar';
import { Breadcrumb, Layout, Space as AntSpace, Dropdown, Drawer } from 'antd';
import { connect } from 'react-redux';
import { Touchable, ThemelizeComponent, WindowSizeResponsive } from 'ui-m/rn';
import { Link } from '@react-navigation/native';
import { Hoverable } from 'react-native-web-hover';
import langOptionConf from '../../config/langOptionConf.js';
import { createAction, navigate } from '../../utils';
import LogoBar from './LogoBar';
import Footer from './Footer';
import PageContent from './PageContent';
import Menu from './Menu';
import Header from './Header';
import BasePage from '../BasePage';
import NavigationBar from "../NavigationBar";
const { Content, Sider } = Layout;
import resources from '../../config/resources.js';

let scrollBarWidth;
function getScrollBarWidth() {
  if (scrollBarWidth === undefined) {
    if (Platform.OS === 'web') {
      const scroll = document.createElement("div");
      const scrollIn = document.createElement("div");
      scroll.appendChild(scrollIn);
      scroll.style.width = "100px";
      scroll.style.height = "50px";
      scroll.style.overflow = "scroll";
      scroll.style.marginLeft = "-100000px";
      document.body.appendChild(scroll);
      const scrollInWidth = scrollIn.offsetWidth;
      const scrollWidth = scroll.offsetWidth;
      const tmp = setTimeout(() => {
        document.body.removeChild(scroll);
        clearTimeout(tmp);
      }, 10);
      scrollBarWidth = scrollWidth - scrollInWidth;
    }
    else {
      scrollBarWidth = 0;
    }
  }
  return scrollBarWidth;
}

class PageHeader extends ThemelizeComponent {
  render() {
    const {
      i18n,
      title,
      renderHeaderTitle,
      renderHeaderLeftView,
      renderHeaderRightView,
      _pageCtx,
      onMenuButtonPress,
    } = this.props;
    const styles = this.styles;
    const isPc = !Theme.isPhoneSizedWindow;
    BasePage.renderDocumentTitle(_pageCtx);
    const wrapStyle = [
      styles.container,
      {
        paddingRight: getScrollBarWidth() + 24,
        paddingHorizontal: isPc ? 24 : 8,
        paddingVertical: 11.8,
        borderColor: isPc ? '#f0f2f5' : "#fff",
      },
    ];
    return (
      <View style={wrapStyle}>
        <View style={styles.leftViewContainer}>
          {!isPc && (
            <Touchable
              onPress={onMenuButtonPress}
              style={{
                width: 30,
                height: 30,
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 0.8,
                borderColor: "#C1C1C1",
                borderRadius: 6,
                marginRight: 10,
              }}
            >
              <Image source={resources.dashboardMenu} style={{ width: 24, height: 24 }} />
            </Touchable>
          )}
          {typeof renderHeaderLeftView === 'function' ? renderHeaderLeftView.call(_pageCtx) : (
            <Row>
              <Text style={styles.crumb}>
                {i18n.map['stream.dashboard.Menu']}
                <Text style={styles.crumbSpliter}>/</Text>
              </Text>
              <Text style={styles.title}>
                {typeof renderHeaderTitle === 'function' ? renderHeaderTitle.call(_pageCtx) : title}
              </Text>
            </Row>
          )}
        </View>
        {
          isPc && (
            <View style={styles.rightViewContainer}>
              {typeof renderHeaderRightView === 'function' ? renderHeaderRightView.call(_pageCtx) : null}
            </View>
          )
        }
      </View>
    );
  }

  static defaultStyles = {
    container: {
      backgroundColor: '#fff',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderColor: '#f0f2f5',
    },
    crumb: {
      color: '#999',
      fontSize: 14,
      lineHeight: 18,
    },
    crumbSpliter: {
      marginHorizontal: 8,
    },
    title: {
      color: '#333',
      fontSize: 15,
      lineHeight: 18,
      fontWeight: 500,
    },
    leftViewContainer: {
      paddingRight: 26,
      flexDirection: "row",
    },
    rightViewContainer: {
      paddingLeft: 26,
      flexShrink: 1,
    },
  }
}

class _DashboardNavigationPage extends ThemelizeComponent {

  static defaultProps = {
    ...Teaset.NavigationPage.defaultProps,
    navigationBarProps: {},
  }

  renderNavigationBar = () => {
    const {
      title,
      showBackButton,
      backBtnIcon,
      renderNavigationTitle,
      renderNavigationLeftView,
      renderNavigationRightView,
      statusBarStyle,
      isInModalStack,
      modalPresentation,
      primary,
      hideBottomBorder,
      _pageCtx,
    } = this.props;

    const isWebInAPP = Theme.isWebInAPP;
    const navigationBarHeight = Theme.navBarContentHeight + Theme.statusBarHeight;
    const headerStyle = StyleSheet.flatten([
      { background: '#1E2023', padding: 0, height: "auto" },
      isWebInAPP && { height: navigationBarHeight }, // app内需要指定高度
    ]);

    return (
      <Layout.Header style={headerStyle}>
        {
          isWebInAPP ? (
            <NavigationBar {...{
              navigation: this.props.navigation, // 实现页面切换用
              title: renderNavigationTitle === undefined ? title : renderNavigationTitle.call(_pageCtx),
              showBackButton,
              backBtnIcon,
              hideBottomBorder,
              leftView: (showBackButton || renderNavigationLeftView === undefined) ? undefined : renderNavigationLeftView.call(_pageCtx),
              rightView: renderNavigationRightView === undefined ? undefined : renderNavigationRightView.call(_pageCtx),
              primary,
              statusBarStyle,
              isInModalStack,
              modalPresentation,
              ...this.props.navigationBarProps,
            }} />
          ) : (
            <Header mode="loggedIn" isPhoneSizedWindow={Theme.isPhoneSizedWindow} />
          )
        }
      </Layout.Header>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this._backHandle);
  }

  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this._backHandle);
  }

  _backHandle = () => {
    if (this.props?.isGestureBackEnabled === false) {
      // REF: https://reactnative.dev/docs/backhandler
      return true;
    }
  }

  render() {
    const {
      // header
      i18n,
      title,
      renderHeaderTitle,
      renderHeaderLeftView,
      renderHeaderRightView,

      _pageCtx,
      __pageState,
      __pageProps,
    } = this.props;
    const isPhoneSizedWindow = Theme.isPhoneSizedWindow;
    const isPc = !isPhoneSizedWindow;

    return (
      <Layout>
        {/* 顶部黑的区域, app里为 Teaset NavigationBar实现, 浏览器里另外实现 */}
        {this.renderNavigationBar()}
        <Layout>
          {isPc && (
            <Sider width={257} className="site-layout-background">
              <Menu />
            </Sider>
          )}
          <Layout style={{ position: "relative" }}>
            {/* headerLeftView */}
            <PageHeader
              i18n={i18n}
              title={title}
              renderHeaderTitle={renderHeaderTitle}
              renderHeaderLeftView={renderHeaderLeftView}
              renderHeaderRightView={renderHeaderRightView}
              _pageCtx={_pageCtx}
              __pageState={__pageState}
              __pageProps={__pageProps}
              onMenuButtonPress={() => this.setState({ open: true })}
            />
            <Content style={{ display: "flex" }}>
              {this.props._renderPage.call(this)}
            </Content>
            {
              !isPc && (
                <Drawer
                  key="menu"
                  placement="left"
                  closable={false}
                  open={this.state.open}
                  onClose={() => this.setState({ open: false })}
                  width={257}
                  bodyStyle={{ padding: 0 }}
                  getContainer={false}
                >
                  <Menu
                    onClick={(e, defaultOnClick) => {
                      this.setState({ open: false });
                      setTimeout(() => defaultOnClick(e), 20);
                    }}
                  />
                </Drawer>
              )
            }
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const DashboardNavigationPage = connect((store) => _.pick(store, [
  'i18n',
  'auth',
]))(_DashboardNavigationPage);

class ResizeDashboardNavigationPage extends WindowSizeResponsive {

  static Header = Header
  static PageContent = PageContent
  static Menu = Menu

  static LogoBar = LogoBar
  static Footer = Footer

  static defaultProps = {
    ..._DashboardNavigationPage.defaultProps,
    primary: true,
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    // __pageState={this.state} __pageProps={this.props} 的作用是当页面 state 变化时触发这两个组件的 render
    return (<DashboardNavigationPage {...this.props} _renderPage={this.renderPage} _pageCtx={this} __pageState={this.state} __pageProps={this.props} isPhoneSizedWindow={Theme.isPhoneSizedWindow} />);
  }
}

export default ResizeDashboardNavigationPage;
