import React, { useRef } from "react";
import { View } from "react-native";
import Touchable from "./Touchable";

const TouchableWithPosition = props => {
  const { onPress, style, ...rest } = props;
  const ref = useRef(null);

  return (
    <View
      ref={ref}
      style={style}
    >
      <Touchable
        {...rest}
        onPress={() =>
          ref.current.measureInWindow(
            (x, y, width, height) =>
              onPress({ x, y, width, height })
          )
        }
      />
    </View>
  );
};

// class TouchableWithPosition extends React.Component {

//   render() {
//     const { onPress, style, ...rest } = this.props;

//     return (
//       <View
//         style={style}
//         ref={ref => this._touchable = ref}
//       >
//         <Touchable
//           {...rest}
//           onPress={() => this._touchable.measureInWindow(onPress)}
//         />
//       </View>
//     );
//   }
// }

export default TouchableWithPosition;
