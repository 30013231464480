import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { StyleSheet, View, VirtualizedList } from 'react-native';
import { NavigationPage, Empty, SkeletonBlockSupplier } from '../../components';
import { Cell, SearchBox, LoadingMore } from "ui-m/rn";
import { CommonActions } from '@react-navigation/native';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map["stream.selectASupplier.title"];
    },
    primary: true,
    showBackButton: true,
  };

  get supplier() {
    return this.props.supplier;
  }

  get canLoadMore() {
    const { list: { hasMore }, isListReloading, isListLoadingMore } = this.supplier;
    return (hasMore && !isListReloading && !isListLoadingMore);
  }

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    await this.props.dispatch({ type: "supplier/setFilter", payload: { keyword: "" } });
    await this.props.dispatch({ type: "supplier/reloadList" });
    this.setState({ isLoaded: true });
  }

  onKeywordChange = async keyword => {
    await this.props.dispatch({ type: "supplier/setFilter", payload: { keyword } });
    this.refresh();
  }

  refresh = _.debounce(() => {
    this.props.dispatch({ type: "supplier/reloadList" });
  }, 300)

  selectSupplier = supplier => {
    const { navigation, route } = this.props;
    navigation.dispatch({
      ...CommonActions.setParams({ supplier }),
      source: route.params.sourceKey,
    });
    navigation.goBack();
  }

  renderPage() {
    const { i18n } = this.props;
    const { isLoaded } = this.state;
    const supplier = this.supplier;

    return (
      <View style={{ flex: 1 }}>
        <SearchBox
          value={supplier.list.filter.keyword}
          onChangeText={this.onKeywordChange}
          onSubmitEditing={this.refresh}
        // underLine={false}
        />
        <VirtualizedList
          style={{ flex: 1 }}
          data={supplier.list.resultSet}
          initialNumToRender={40}
          keyExtractor={i => i.id}
          getItemCount={() => supplier.list.resultSet.length}
          getItem={(data, index) => ({ ...data[index], _index: index })}
          renderItem={({ item, index, separators }) => (
            <Cell
              title={item.name}
              onPress={() => this.selectSupplier(item)}
              underline={(index + 1) !== supplier.list.resultSet.length}
            />
          )}
          ListEmptyComponent={(
            isLoaded ? (
              <View style={styles.emptyWrap}>
                <Empty />
              </View>
            ) : <SkeletonBlockSupplier />
          )}
          ListFooterComponent={(
            !supplier.list.resultSet.length ? null : (
              supplier.list.hasMore ? (
                <LoadingMore loading={supplier.list.hasMore} title={i18n.map["mch.toast.loading"]} />
              ) : (
                <LoadingMore loading={false} title={i18n.map["cmn.no_more_content"]} />
              )
            )
          )}
          onEndReached={() => {
            this.canLoadMore && this.props.dispatch({ type: "supplier/loadMore" });
          }}
          onEndReachedThreshold={0.1}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({

  emptyWrap: {
    paddingTop: 68,
    paddingHorizontal: 15,
    alignItems: "center",
  },
});

export default connect((store) => _.pick(store, [
  'i18n',
  'supplier',
]))(Page);
