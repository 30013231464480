import { request } from "../utils";
import { getCompanyId } from "../utils/globalUtils";
import qs from "qs";

export const getSalesChannels = (params = {}) => request(`/companies/${getCompanyId()}/sales-channels?${qs.stringify(params)}`);

export const patchSingle = ({ entity, ...data }) => request(`/companies/${getCompanyId()}/sales-channels/${entity}`, {
  method: "PATCH",
  data,
});

// 取消授权
export const deleteSingle = ({ entity }) => request(`/companies/${getCompanyId()}/sales-channels/${entity}`, {
  method: "DELETE",
});
