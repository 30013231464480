import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, View, Text } from 'react-native';
import { NavigationPage, NetImage, LabelBlock, Dialog, PullCellPickerView, ScreenLoading, Toast, NavigationBar } from '../../components';
import { CellGroup, Cell, Input, Button, Space } from 'ui-m/rn';
import moment from 'moment';
import { navigateBack } from '../../utils';


class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.state.name;
    },
    primary: true,
    showBackButton: true,
    renderNavigationRightView() {
      return (
        <NavigationBar.Button
          text={this.props.i18n.map["cmn.btn.save"]}
          textStyle={{ color: "#fff" }}
          onPress={this.handleSave}
        />
      );
    },
  };

  constructor(props) {
    super(props);

    const salesChannel = this.props.route.params?.salesChannel || {};
    this.state = {
      ...salesChannel,
      priceIncreaseRate: this.formatPriceIncreaseRate(salesChannel.priceIncreaseRate),
    };
  }

  replaceEntityAbbr = text => text?.replace?.(/%s1/g, "Efashion")

  getPricingStrategyItems = () => {
    const i18n = this.props.i18n;
    return [
      {
        key: "doNotSync",
        title: i18n.map.do_not_sync_price,
        subtitle: this.replaceEntityAbbr(i18n.map.mc_s_prices_will_112),
      },
      {
        key: "increaseRateByHighestPrice",
        title: i18n.map.highest_price_tie_37,
        subtitle: this.replaceEntityAbbr(i18n.map.use_the_highest__124),
      },
    ];
  }

  handleSelectPricingStrategy = () => {
    PullCellPickerView.open({
      items: this.getPricingStrategyItems(),
      selected: this.state.priceStrategy,
      onSelect: priceStrategy => this.setState({ priceStrategy }),
    });
  }

  formatPriceIncreaseRate = rate => {
    rate = +rate;
    if (!(rate > 0)) {
      return 0;
    }
    // 两位小数
    return +rate.toFixed(2);
  }

  handleSave = async () => {
    const { entity, priceStrategy, priceIncreaseRate } = this.state;
    const payload = { entity, priceStrategy };
    if (priceStrategy === "increaseRateByHighestPrice") {
      payload.priceIncreaseRate = this.formatPriceIncreaseRate(priceIncreaseRate);
    }
    ScreenLoading.show();
    const res = await this.props.dispatch({ type: "salesChannel/patchSingle", payload });
    ScreenLoading.hide();
    if (res.success) {
      Toast.success();
      navigateBack();
    }
  }

  handleCancelAuthorization = () => {
    const i18n = this.props.i18n;
    Dialog.show({
      title: i18n.map["cmn.tips"],
      content: i18n.map.after_canceling__234.replace("%s1", "Efashion").replace("%s2", this.state.name),
      leftBtnText: i18n.map["cmn.btn.cancel"],
      rightBtnText: i18n.map["cmn.btn.confirm"],
      rightBtnStyle: { color: "#F44336" },
      onRightClick: async () => {
        ScreenLoading.show();
        const res = await this.props.dispatch({
          type: "salesChannel/deleteSingle",
          payload: {
            entity: this.state.entity,
          },
        });
        ScreenLoading.hide();
        if (res.success) {
          Toast.success();
          navigateBack();
        }
      },
    });
  }

  renderPage() {
    const { i18n } = this.props;
    const {
      name,
      bigLogo,
      authorizedBy: {
        staffName,
        staffPhone,
        authorizedTime,
      } = {},
      priceStrategy,
      priceIncreaseRate,
    } = this.state;

    const authorizedByAfter = [
      staffPhone,
      staffName && `(${staffName})`,
    ].filter(i => i).join(" ") || "-";

    const authorizedAtAfter = authorizedTime ? moment(authorizedTime).format("L LT") : "-";

    return (
      <ScrollView style={styles.container} >
        <NetImage
          style={[styles.logo, { width: 177, height: 60 }]}
          url={NetImage.crop(bigLogo, 400)}
          resizeMode="contain"
        />
        <LabelBlock
          type="desc"
          containerStyle={{ paddingTop: 0, paddingBottom: 15 }}
          text={i18n.map["mch.shopInfo.channels.relevantNotes"].replace("%s", name)}
        />
        <CellGroup>
          <Cell title={i18n.map["cmn.authorizedBy"]} after={authorizedByAfter} />
          <Cell title={i18n.map["cmn.authorizedAt"]} after={authorizedAtAfter} />
        </CellGroup>
        <LabelBlock type="desc" text={i18n.map.product_pricing_s_24} />
        <Cell
          title={i18n.map.pricing_strategy}
          afterTitle={this.getPricingStrategyItems()?.find?.(i => i.key === priceStrategy)?.title}
          footer={(
            priceStrategy === "doNotSync" ? (this.replaceEntityAbbr(i18n.map.this_setting_wil_140)) :
              priceStrategy === "increaseRateByHighestPrice" ? (
                <View style={{ flexShrink: 1 }}>
                  <Input
                    label={i18n.map.price_increase_rate}
                    value={priceIncreaseRate}
                    left="+"
                    right="%"
                    keyboardType="decimal-pad"
                    onChangeText={val => {
                      if (!isNaN(+val)) {
                        this.setState({ priceIncreaseRate: val });
                      }
                    }}
                    onBlur={() => {
                      this.setState(state => ({
                        priceIncreaseRate: this.formatPriceIncreaseRate(state.priceIncreaseRate),
                      }));
                    }}
                  />
                  <Text style={styles.inputTip}>{this.replaceEntityAbbr(i18n.map.the_default_sync_243)}</Text>
                </View>
              ) : null
          )}
          onPress={this.handleSelectPricingStrategy}
          underline={false}
          showArrow
        />
        <View style={{ alignSelf: "center", marginTop: 40 }}>
          <Button
            primary
            style={{ width: 335, backgroundColor: "#F04C4C" }}
            textStyle={{ fontWeight: "bold", color: "#fff" }}
            onPress={this.handleCancelAuthorization}
          >
            {i18n.map.cancel_authorization}
          </Button>
        </View>
        <Space height={100} />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  logo: {
    alignSelf: "center",
    marginTop: 30,
    marginBottom: 17,
  },
  inputTip: {
    fontSize: 12,
    lineHeight: 16,
    color: "#787878",
    marginTop: 6,
  },
});

export default connect((store) => _.pick(store, [
  'i18n',
]))(Page);
