import React, {useState, useEffect} from 'react';
import { DatePicker } from "antd";
import moment from 'moment';

// 切换语言,本地化不生效
const DateRangePicker = props => {
  const { startTime, endTime, onRangeChange } = props;

  const [value, setValue] = useState(
    startTime && endTime
      ? [moment(startTime), moment(endTime)]
      : null
  );

  useEffect(() => {
    setValue(
      startTime && endTime
        ? [moment(startTime), moment(endTime)]
        : null
    );
  }, [startTime, endTime]);

  return (
    <DatePicker.RangePicker
      {...props}
      value={value}
      onCalendarChange={(dates, dateStrings, info) => (
        onRangeChange({
          startTime: dates?.[0] ? +dates[0].startOf("day") : undefined,
          endTime: dates?.[1] ? +dates[1].endOf("day") : undefined,
        })
      )}
      format="DD/MM/YYYY"
      allowClear
      allowEmpty={[true, true]}
      disabledDate={curr => curr > moment().endOf("day")}
    />
  );
};

export default DateRangePicker;
