

let getState = () => ({});
let dispatch = async () => null;
const getMcKey = () => getState()?.auth?.mcKey;
const getCompanyId = () => getState()?.auth?.companyInfo?.id;

const initGlobalUtils = store => {
  getState = store.getState;
  dispatch = store.dispatch;
};

export {
  getState,
  dispatch,
  initGlobalUtils,

  getMcKey,
  getCompanyId,
};
