import React from 'react';
import _ from 'lodash';
import qs from 'qs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet, Text, View, Image, Switch } from 'react-native';
import { NavigationPage, NavigationBar, ScreenLoading, Progress, NetImage, Dialog, Touchable, Toast } from '../../components';
import { Button, Theme, Cell, CellGroup, Space } from 'ui-m/rn';
import { handleRequestError, mcRequest, request, navigate, navigateBack } from '../../utils';
import { callNativeAsyncFunc, NativeFuncs } from '../../utils/mcAppJsSDK';
import resources from '../../config/resources';
import * as daService from '../../services/dataAnalyses';

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return this.props.i18n.map.preference;
    },
    primary: true,
    renderNavigationLeftView() {
      return (
        <NavigationBar.Button
          icon="back"
          onPress={navigateBack}
        />
      );
    },
    renderNavigationRightView() {
      return (
        <NavigationBar.Button
          text={this.props.i18n.map["cmn.btn.save"]}
          textStyle={{ color: "#fff" }}
          onPress={this.save}
        />
      );
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      settings: props.preference.settings || {},
    };
  }

  save = async () => {
    ScreenLoading.show();
    const res = await this.props.dispatch({
      type: "preference/savePreference",
      payload: _.pick(this.state.settings, "importUnitAndPackageContent"),
    });
    ScreenLoading.hide();
    if (res.success) {
      Toast.success();
      navigateBack();
    }
  }

  renderSwitchItem = key => {
    const { i18n } = this.props;
    const value = this.state.settings?.[key];

    return (
      <Cell
        title={i18n.map.import_unit_and_p_31}
        formType="switch"
        value={value}
        onChange={val => {
          this.setState({
            settings: {
              ...(this.state.settings || {}),
              [`${key}`]: val,
            },
          });
        }}
      />
    );
  }

  renderPage() {

    return (
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        <Space />
        <CellGroup>
          {this.renderSwitchItem("importUnitAndPackageContent")}
        </CellGroup>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    minHeight: "100%",
    alignItems: "stretch",
  },

});

export default connect((store) => _.pick(store, [
  'i18n',
  'app',
  "preference",
]))(Page);
