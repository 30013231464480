import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking } from 'react-native';
import { Breadcrumb, Layout, Space as AntSpace, Dropdown, Modal } from 'antd';
import { LogoutOutlined, ExclamationCircleOutlined } from '../../config/antdIcons';
import { connect } from 'react-redux';
import langOptionConf from '../../config/langOptionConf.js';
import { createAction } from '../../utils';
import { getNavigation } from '../../utils/navigation.js';
import { CommonActions } from '@react-navigation/native';
import { resources } from '../../config';
import { Touchable, Theme } from 'ui-m/rn';
import TouchableWithPosition from "../TouchableWithPosition.js";
import Menu from "../Menu.js";

export default connect((store) => _.pick(store, [
  'i18n',
  'auth',
]))((props) => {
  const { dispatch, i18n, auth, height } = props;
  const isPhoneSizedWindow = Theme.isPhoneSizedWindow;
  const handleLogout = () => {
    Modal.confirm({
      title: i18n.map['cmn.dialog.desc.sure_to_log_out'],
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      okText: i18n.map['cmn.btn.confirm'],
      okType: 'danger',
      cancelText: i18n.map['cmn.btn.cancel'],
      async onOk() {
        await dispatch(createAction('auth/logout')({}));
      },
    });
  };
  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: [
          {
            icon: <LogoutOutlined />,
            key: 'logout',
            label: (
              <a onClick={() => handleLogout()}>
                <Text>{i18n.map['cmn.label.log.out']}</Text>
              </a>
            ),
          },
        ],
      }}
    >
      {
        isPhoneSizedWindow ? (
          <Image source={resources.dashboardPersonal} style={{width: 24, height: 24}} />
        ) : (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ justifyContent: 'center', height: height, lineHeight: height, cursor: 'default', alignItems: "flex-end" }}>
              <Text style={{ color: '#fff', fontSize: 14, lineHeight: 20, marginBottom: 2 }}>{auth.companyInfo.name} ({auth.staffInfo.account})</Text>
              <Text style={{ color: '#B0B0B0', fontSize: 13, lineHeight: 18 }}>{auth.staffInfo.account_phone}</Text>
            </View>
            <Image style={{ width: 16, height: 16, marginLeft: 13 }} source={{ uri: resources.arrowWhiteDown }} />
          </View>
        )
      }
    </Dropdown >
  );
});
