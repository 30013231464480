import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking } from 'react-native';
import { connect } from 'react-redux';

export default connect((store) => _.pick(store, [
  'i18n',
]))((props) => {
  return (
    <View style={{
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      background: '#1E2023',
    }}>
      <Image
        source={{ uri: require('../../assets/app/black.png') }}
        style={{ width: 28, height: 27, marginRight: 15 }} // 图片宽高比例是30:29
      />
      <Text style={{ color: '#fff', fontSize: 16, lineHeight: 22, fontWeight: "bold" }}>{props.i18n.map["stream.dashboard.pc.title"]}</Text>
    </View>
  );
});
