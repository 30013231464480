import React from "react";
import { Text } from "react-native";
import moment from 'moment';
import Touchable from "../../Touchable";
import DateRangePicker from "../../DateRangePicker";

const DateRangePickerMobile = props => {
  const { startTime, endTime, onRangeChange, maxDateRangeLength = 90 } = props;

  const handleSelect = () => {
    DateRangePicker.show({
      dateRange: [startTime, endTime],
      maxDateRangeLength: maxDateRangeLength,
      onConfirm: times => {
        onRangeChange?.({
          startTime: +moment(times[0]).startOf("day"),
          endTime: +moment(times[1]).endOf("day"),
        });
      },
    });
  };

  return (
    <Touchable onPress={handleSelect}>
      <Text style={{
        color: "#0076FF",
        fontSize: 14,
        lineHeight: 20,
        paddingVertical: 6,
      }}>{`${moment(startTime).format("DD/MM/YYYY")} ~ ${moment(endTime).format("DD/MM/YYYY")}`}</Text>
    </Touchable>
  );
};



export default DateRangePickerMobile;
