import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ScrollView, StyleSheet } from 'react-native';
import { NavigationPage } from '../../components';
import { openMC } from '../../utils';
import { CellGroup, Cell } from "ui-m/rn";

class Page extends NavigationPage {

  static defaultProps = {
    ...NavigationPage.defaultProps,
    renderNavigationTitle() {
      return "Intention Launcher";
    },
    primary: true,
  };

  renderPage() {

    return (
      <ScrollView style={styles.container}>
        <CellGroup>
          <Cell
            title="Supply Chain"
            onPress={() => {
              openMC({
                launchParam: `time=${Date.now()}&navigate=SupplyChainStack`,
              });
            }}
            showArrow
          />
          <Cell
            title="Third-party sales channel"
            onPress={() => {
              openMC({
                launchParam: `time=${Date.now()}&intention=${encodeURIComponent(JSON.stringify({ action: "thirdPartySalesChannelManagement" }))}`,
              });
            }}
            showArrow
          />
        </CellGroup>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

});

export default connect((store) => _.pick(store, [
  'i18n',
]))(Page);
