import React from "react";
import { connect } from "react-redux";
import { StyleSheet, View, Text } from "react-native";
import _ from "lodash";
import Calendar from 'react-calendar';
import { Teaset } from "ui-m/rn";
import Touchable from "./Touchable";
import moment from 'moment';
import { Toast } from ".";

const { Drawer } = Teaset;

class DateRangePickerView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dateRange: props.dateRange, // null | Date[]
      maxDate: props.maxDate,
      maxDateRangeLength: props.maxDateRangeLength,
    };
  }

  get isOutOfDateRange() {
    const { maxDateRangeLength, dateRange } = this.state;
    if (maxDateRangeLength > 0) {
      const start = moment(dateRange[0]).startOf("day");
      const end = moment(dateRange[1] || dateRange[0]).endOf("day");
      return end.diff(start, "day", true) > maxDateRangeLength;
    }
    return false;
  }

  onChange = dateRange => {
    this.setState({ dateRange });
    this.props.onChange?.(dateRange);
  }

  handleConfirm = () => {
    if (this.isOutOfDateRange) {
      return Toast(this.props.i18n.map["stream.dashboard.filter.dateRange.invalid.tip.generic"].replace("%s", this.state.maxDateRangeLength));
    }
    this.props.onConfirm?.(this.state.dateRange);
  }

  handleCancel = () => this.props.onCancel?.(this.state.dateRange)

  render() {
    const i18n = this.props.i18n;
    const { dateRange, maxDate } = this.state;

    return (
      <View style={styles.wrap}>
        <View style={styles.head}>
          <View style={styles.titleWrap}>
            <View style={styles.titleLimit}>
              <Text style={styles.title}>{i18n.map["cmn.selectDateRange"]}</Text>
            </View>
            <View style={styles.btns}>
              <Touchable style={styles.leftBtn} onPress={this.handleCancel} hitSlop={{ top: 10, bottom: 10 }}>
                <Text style={styles.btnText}>{i18n.map["cmn.btn.cancel"]}</Text>
              </Touchable>
              <Touchable style={styles.rightBtn} onPress={this.handleConfirm} hitSlop={{ top: 10, bottom: 10 }}>
                <Text style={styles.btnText}>{i18n.map["cmn.btn.confirm"]}</Text>
              </Touchable>
            </View>
          </View>
        </View>
        <Calendar
          className="_c_react-calendar"
          tileClassName="_c_react-calendar-tile"
          value={dateRange}
          onChange={this.onChange}
          returnValue="range"
          selectRange
          allowPartialRange
          maxDate={maxDate}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    minHeight: 510,
    backgroundColor: "#fff",
    paddingTop: 5,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: "hidden",
  },
  head: {
    paddingHorizontal: 18,
    paddingTop: 11.5,
    paddingBottom: 21.5,
  },
  titleWrap: {
    flex: 1,
    alignItems: "center",
  },
  titleLimit: {
    maxWidth: 200,
  },
  title: {
    color: "#333333",
    fontSize: 17,
    lineHeight: 21,
    fontWeight: "600",
    textAlign: "center",
  },
  btns: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  leftBtn: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    justifyContent: "center",
  },
  rightBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
  },
  btnText: {
    color: "#0076FF",
    fontSize: 15,
    lineHeight: 21,
  },
});

const DateRangePicker = connect((store) => _.pick(store, [
  'i18n',
]))(DateRangePickerView);

const convertToDate = (v, _d = new Date()) => {
  let d;

  if (v instanceof Date) {
    d = v;
  } else if (typeof v === "string" || typeof v === "number") {
    d = new Date(v);
  }

  return +d ? d : _d;
};

const show = params => {
  const { dateRange: _dateRange, onChange, onConfirm, onCancel, maxDateRangeLength } = params;

  let dateRange = (
    Array.isArray(_dateRange)
      ? _dateRange.map(i => convertToDate(i, null))
      : convertToDate(_dateRange, null)
  );

  const drawer = Drawer.open(
    <DateRangePicker
      dateRange={dateRange}
      maxDate={new Date()}
      maxDateRangeLength={maxDateRangeLength}
      onChange={e => {
        dateRange = [e[0], e[1] || e[0]];
        onChange?.(dateRange);
      }}
      onConfirm={() => {
        drawer.close();
        onConfirm?.(dateRange);
      }}
      onCancel={() => {
        drawer.close();
        onCancel?.(dateRange);
      }}
    />, "bottom", "none",
    {
      containerStyle: {
        backgroundColor: "transparent",
      },
      onCloseRequest: () => {
        drawer.close();
        onCancel?.(dateRange);
      },
    }
  );
};

DateRangePicker.show = show;

export default DateRangePicker;
