import { request, mcRequest } from "../utils";
import { getCompanyId, getMcKey } from "../utils/globalUtils";

export const activateDashboard = invitationCode => request(`/dashboard-viewers/activate`, {
  method: "POST",
  data: {
    invitationCode,
  },
});

// 下游
export const getDownstreams = () => request(`/upstream-companies/${getCompanyId()}/dashboard-viewers`);
// export const getDownstream = downstreamId => request(`/upstream-companies/${downstreamId}/dashboard-viewers`);

// 报表
export const getDashboards = () => request(`/companies/${getCompanyId()}/dashboards`);
export const getDashboard = dashboardId => request(`/upstream-companies/${getCompanyId()}/dashboards/${dashboardId}`);

// 数据源
// companyId, supplierId 来自 dashboardViewer
export const queryDataSource = data => request(`/data-sources/query`, {
  method: "POST",
  data,
});

// 补充公司信息
export const getMSCompanyInfo = companyId => request(`/dashboards/company-info/${companyId || getCompanyId()}`);

// 导出报表
export const exportAsExcel = data => request(`/data-sources/export`, {
  method: "POST",
  data,
});
