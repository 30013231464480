import qs from 'qs';
import { request, authority } from '../utils';

const { getLoginId } = authority;

export const login = async (params) => request('/logins', {
  method: 'POST',
  data: params,
});

export const logout = async (params) => request('/logins', {
  method: 'Delete',
  data: params,
});

export const getAuthKey = async (params) => request('/oauth2/keys', {
  method: 'POST',
  data: params,
});

export const getAccountInfo = async ({loginId = ''}) => request(`/accounts/${loginId || await getLoginId()}`, {
  method: 'GET',
});

export const patchAccountInfo = async ({...rest}) => request(`/accounts/${await getLoginId()}`, {
  method: 'PATCH',
  data: rest,
});

export const changePhone = async (data) => request(`/accounts/${await getLoginId()}/phoneNumber`, {
  method: 'PATCH',
  data,
});

//删除账号
export const accountdeletion = async ({id, ...rest}) => request(`/accounts/${id}`, {
  method: 'DELETE',
  data: rest,
}, {bodyWithData: true}); //delete data 放在body中

//取消账号删除
export const cancelAccountdeletion = async ({id}) => request(`/accounts/${id}/accountDeletingTasks`, {
  method: 'DELETE',
});

// 提交或创建邀请记录
export const putInvitations = async (data) => request(`/supplier/invitations`, {
  method: 'PUT',
  data,
});

// 获取账号邀请记录
export const getInvitations = async (data) => request(`/supplier/invitations?${qs.stringify({
  accountId: await getLoginId(),
  ...data,
})}`);

// 删除邀请记录
export const deleteInvitations = async ({id}) => request(`/supplier/invitations/${id}`, {
  method: 'DELETE',
});

// 设置推送 token
export const updatePushToken = async (params) => request(`/notification/tokens`, {
  method: 'PUT',
  data: {
    token: params.token,
    platform: params.platform,
    channel: params.channel,
  },
});

// 微店铺授权登陆
export const msLogin = async ({loginToken, ...rest}) => request(`/accounts/msLoginToken/${loginToken}`, {
  method: 'POST',
  data: rest,
});
