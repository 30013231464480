import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getNavigation, getLastRootState } from './navigation';
import { getStore, select } from './store';
import { getMcKey } from './authority';
import {Toast, Dialog} from '../components';
import { Theme } from 'ui-m/rn';
import ScreenLoading from '../components/ScreenLoading';
import kvlogService from '../services/kvlog';
import _ from 'lodash';
import { CommonActions } from '@react-navigation/native';
import { message } from 'antd';


let is401Handling = false;
export async function handleRequestError(res, defaultErrorMessage = '', options = {}){
  // 先隐藏全局 Loading 省去各处的处理，为了后面弹出 Toast，并且防止 Loading 由于 Toast 冲突而一直未关闭。
  ScreenLoading.hide();
  const {
    toastOptions = {},
    mute = false,
    showFailIcon = false,
  } = options;
  const {
    status,
    errorMessage,
    errorDetail,
    errorCode,
    errorType,
    request,
  } = res;
  function _Toast(textOrOptions, toastOptions) {
    if (mute) {return;}

    if (!showFailIcon) {
      Toast(textOrOptions, toastOptions);
    }
    else {
      Toast.fail(textOrOptions, toastOptions);
    }
  }
  const i18n = await select(({i18n})=>i18n || {map: {}});
  // 401 请求跳转至登录
  if ( status == 401 || errorCode == 6011 ) {
    // !is401Handling 避免重复处理
    // !isRequestTokenExpired 避免旧Token请求晚回导致反复弹出
    const requestToken = /(\?|\&)(mcKey|key)\=(.+?)(\&|$)/.test(res.request?.url) && RegExp.$3;
    // const navigation = await getNavigation();
    // const isAlreadyInLoginScreen = navigation.getCurrentRoute()?.name === 'Login';
    const currentToken = await getMcKey();
    const isRequestTokenExpired = requestToken && currentToken !== requestToken;
    if (!is401Handling && !isRequestTokenExpired ) {
      // # 401处理开始
      is401Handling = true;
      try {
        // 标记登录态过期
        (await getStore()).dispatch({ type: 'auth/setLoginInvalid' });
        // 弹出提示
        if (!mute) {
          if (Theme.isPhoneSizedWindow) {
            await new Promise((resolve)=>{
              Dialog.show({
                type: 'alert',
                title: i18n.map['cmn.tip_401'],
                rightBtnText: i18n.map['cmn.btn.ok'],
                onRightClick: resolve,
              });
            });
          }
          else {
            message.error(i18n.map['cmn.tip_401']);
          }
        }
        // PC端、手机浏览器打开，还需要跳到登录页
        if (!Theme.isWebInAPP) {
          const navigation = await getNavigation();
          navigation.dispatch(
            CommonActions.navigate({
              name: 'DashboardLogin',
            })
          );
        }
      } catch (error) {
        console.warn(error);
      }
      is401Handling = false;
      // / 401处理完毕
    }
  }
  else if (status === 502) {
    _Toast(i18n.map['cmn.service502'], toastOptions);
  }
  // 400 参数错误
  else if ( status === 400 ) {
    _Toast(errorMessage || defaultErrorMessage || '', toastOptions);
    kvlogService.postException({ error: 'HTTP 400', detail: res }, { errorType: 'API' });
  }
  else if ( errorMessage || defaultErrorMessage ) {
    _Toast(errorMessage || defaultErrorMessage, toastOptions);
    if (!errorMessage) {
      kvlogService.postException({ error: '500 No Message', detail: res }, { errorType: 'API' });
    }
  } else if ( errorType === 'timeout_error') {
    _Toast(i18n.map['cmn.service502'], toastOptions);
    kvlogService.postException({ error: 'Network timeout', detail: res }, { errorType: 'NETWORK' });
  } else if ( errorType === 'network_error') {
    _Toast(i18n.map['cmn.toast.network_error'], toastOptions);
    kvlogService.postException({ error: 'Network error', detail: res }, { errorType: 'NETWORK' });
  }
  else if ( request && status !== 404 ) {
    kvlogService.postException({ error: 'Unknown request error', detail: res }, { errorType: 'NETWORK' });
  }
}

// 不建议在 model 层做弹出 Toast 的逻辑，容易出现很多失控 Toast，最好集中在视图层做（React）。
export function* handleRequestErrorInModel(res, defaultErrorMessage, options){
  yield call(handleRequestError, res, defaultErrorMessage, options);
}
