import { request } from '../utils';

export const getSysConf = async (params) => request('/systemConfigs', {
  data: params,
});

// 暂时不用
export const getSecurityKey = async () => request('/systemConfigs/security/key', {
  json: false,
  headers: {
    'Content-Type': 'text/plain',
  },
});
