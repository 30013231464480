import React from 'react';
// import propTypes from 'prop-types'
import {Image, Text, StyleSheet} from 'react-native';
import {Touchable} from 'ui-m/rn';
import {Consumer} from './context';
import resources from '../../config/resources';

const availableIcons = ['back', 'close', 'add', 'submit', 'batch', 'more', 'share', 'history'];

function generateButtonIconMap(){
  const map = {};
  availableIcons.forEach((key)=>{
    const camelKey = key[0].toUpperCase() + key.slice(1);
    map[key] = {
      darkIcon: resources[`nav${camelKey}Black`] || resources[`nav${camelKey}`],
      lightIcon: resources[`nav${camelKey}White`] || resources[`nav${camelKey}`],
    };
  });
  return map;
}

class NavigationButton extends React.PureComponent {

  static iconMap = generateButtonIconMap()

  static defaultProps = {
    text: undefined,
    type: undefined,
    icon: undefined,
    darkIcon: undefined,
    lightIcon: undefined,
    disabled: false,
    style: undefined,
    textStyle: undefined,
  }

  render(){
    return <Consumer>
      {ctx=>{
        let { type, text, icon, darkIcon, lightIcon, disabled, style, textStyle, forwardedRef, ...rest } = this.props;
        const finalType = (type || ctx.type);
        if (typeof icon === 'string') {
          let insetIcon = NavigationButton.iconMap[icon] || {};
          darkIcon = insetIcon.darkIcon;
          lightIcon = insetIcon.lightIcon;
        }
        const finalIcon = (finalType === 'primary' ? lightIcon : darkIcon) || icon || darkIcon || lightIcon;
        const contentType = text === undefined ? 'icon' : 'text';
        // 按钮禁用时，自动剔除 onPress 方法
        if ( disabled ) {
          delete rest.onPress;
        }
        return <Touchable
          ref={forwardedRef}
          style={[
            styles[`style_contentType_${contentType}`],
            styles[`style_contentType_${contentType}_type_${finalType}`],
            style,
          ]}
          disabled={disabled}
          {...rest}
        >
          {contentType === 'icon' ? <Image source={finalIcon} style={{width: 40, height: 40}} /> : <Text style={[
            styles.textStyle,
            styles[`textStyle_type_${finalType}`],
            styles[`textStyle_disabled_${disabled}`],
            textStyle,
          ]}>{text}</Text>}
        </Touchable>;
      }}
    </Consumer>;
  }
}


const styles = StyleSheet.create({
  style_contentType_icon: {
    width: 40,
    height: 40,
  },
  style_contentType_text: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 9,
  },
  style_contentType_text_type_primary: {
    height: 40,
    // lineHeight: 33,
    paddingTop: 7,
  },

  textStyle: {
    fontSize: 15,
  },
  textStyle_type_primary: {
    fontSize: 15,
    color: '#008AFF',
    letterSpacing: 0.44,
    fontWeight: 'bold',
  },
  textStyle_type_normal: {
    fontSize: 15,
    color: '#008AFF',
    letterSpacing: 0.44,
    fontWeight: 'bold',
  },
  textStyle_disabled_true: {
    color: '#A7A19A',
    fontWeight: 'normal',
  },
});


export default React.forwardRef((props, ref) => {
  return <NavigationButton {...props} forwardedRef={ref}/>;
});
