import React from "react";
import { Image } from "react-native";
import { Tooltip } from "antd";
import { resources } from "../../../config";
import { useSelector } from "react-redux";

const TipIcon = props => {
  const i18n = useSelector(state => state.i18n);
  const tip = props.tip;
  const i18nKey = props.i18nKey;
  const title = tip || i18n.map[i18nKey];
  const style = props.style;
  const tooltipOptions = props.tooltipOptions || {};

  return (
    <Tooltip title={title} placement="bottom" arrowPointAtCenter={true} {...tooltipOptions}>
      <Image style={[{width: 14,height: 14}, style]} source={{uri: resources.tipCircle}} />
    </Tooltip>
  );
};

export default TipIcon;
