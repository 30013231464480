import React, { useEffect, useCallback, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { StyleSheet, Text, View, Image } from "react-native";
import { TouchableWithPosition, SkeletonBlockSupplierOverview, Menu, Touchable, Dialog } from "../../../components";
import { resources } from "../../../config";
import moment from 'moment';
import { formatText } from "../../../utils";

const getTimeRangeByType = type => {
  let startTime, endTime;

  // 近 n 天 (近 1 天 === 今天)
  if (typeof type === "number" && Math.floor(type) > 0) {
    const days = Math.floor(type);
    startTime = moment().subtract(days - 1, "days").startOf("day");
    endTime = moment().endOf("day");
  }
  // 今天
  else if (type === "day") {
    startTime = moment().startOf("day");
    endTime = moment().endOf("day");
  }
  // 本周
  else if (type === "week") {
    // 统一每周的第一天为 iso周一
    startTime = moment().isoWeekday(1).startOf("day");
    endTime = moment().isoWeekday(7).endOf("day");
  }
  // 本月
  else if (type === "month") {
    startTime = moment().startOf("month").startOf("day");
    endTime = moment().endOf("month").endOf("day");
  }

  return [+startTime, +endTime];
};

const useSupplierCard = props => {
  const { supplier, canUpdateOverview, timeRange, requestOverview } = props;
  const { supplierId } = supplier;
  const [showOverview, setShowOverview] = useState(false);
  const [overview, setOverview] = useState(null);
  const [isLoadingOverview, setIsLoadingOverview] = useState(false);
  const overviewStatus = useRef({
    needUpdate: false,
    updateTime: 0,
    requestTimes: [],
  });

  const updateOverView = useCallback(async () => {
    if (!showOverview) {
      return;
    }
    overviewStatus.current.needUpdate = false;
    const requestTime = Date.now();
    overviewStatus.current.requestTimes.push(requestTime);
    setIsLoadingOverview(true);
    const [startTime, endTime] = Array.isArray(timeRange) ? timeRange : getTimeRangeByType(timeRange);
    const _overview = await requestOverview({ supplierId, startTime, endTime });
    if (_overview && requestTime > overviewStatus.current.updateTime) {
      overviewStatus.current.updateTime = requestTime;
      setOverview(_overview);
    }
    overviewStatus.current.requestTimes = overviewStatus.current.requestTimes.filter(i => i !== requestTime);
    setIsLoadingOverview(!!overviewStatus.current.requestTimes.length);
  }, [requestOverview, supplierId, timeRange, showOverview]);

  useEffect(() => {
    overviewStatus.current.needUpdate = true;
    canUpdateOverview && updateOverView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRange]);

  useEffect(() => {
    canUpdateOverview && showOverview && overviewStatus.current.needUpdate && updateOverView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUpdateOverview, showOverview]);

  return [overview, isLoadingOverview, showOverview, setShowOverview];
};

const SupplierCard = forwardRef((props, ref) => {
  const {
    i18n,
    permission,
  } = useSelector(state => ({
    i18n: state.i18n,
    permission: state.dashboard.permission,
  }));
  const { supplier } = props;
  const { supplierName, activatorPhoneNumber, viewerCompanyName, activatorId, lastViewedTime } = props.supplier;
  const [overview, isLoadingOverview, showOverview, setShowOverview] = useSupplierCard(props);

  const handleDeleteDashboardViewer = () => props.handleDeleteDashboardViewer(supplier);
  const handleSendInviteLink = () => props.handleSendInviteLink(supplier);
  const handlePreviewUpstreamDashboard = () => props.handlePreviewUpstreamDashboard(supplier);
  const showMoreMenu = useCallback(position => (
    Menu.show(
      position,
      [
        {
          icon: resources.ashbin,
          title: <Text style={{ color: "#E8363B", fontSize: 14, lineHeight: 20 }}>{i18n.map["rt.btn.remove"]}</Text>,
          onPress: handleDeleteDashboardViewer,
        },
      ],
      { align: 'end', showArrow: true, shadow: true }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);
  const showTip = useCallback(content => Dialog.show({
    title: i18n.map["cmn.tips"],
    content,
    contentStyle: { textAlign: "left" },
    rightBtnText: i18n.map["cmn.btn.ok"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);
  const renderOverviewValueText = useCallback(key => {
    const value = overview?.[key];

    if (value === null || value === undefined) {
      return null;
    }

    // 整数型
    if (["netSales", "totalStock"].includes(key)) {
      return formatText.int(value);
    }

    // 金额型
    if (["payable", "totalStockValue", "arrears", "turnover", "margin"].includes(key)) {
      const currencyText = overview?.currency?.symbol ? ` ${overview?.currency.symbol}` : "";
      const text = formatText.amount(value);
      return text ? `${text}${currencyText}` : null;
    }
  }, [overview]);

  useImperativeHandle(ref, () => ({
    isLoading: () => isLoadingOverview,
  }), [isLoadingOverview]);

  return (
    <View style={styles.card}>
      <Text style={styles.name}>{supplierName}</Text>
      {
        !activatorId ? ( // 未激活
          <>
            <Text style={styles.pending}>{i18n.map["stream.dashboardViewer.status.pending"]}</Text>
            {
              permission.isAdmin && (
                <Touchable
                  style={styles.sendLink}
                  onPress={handleSendInviteLink}
                >
                  <Text style={styles.sendLinkText}>{i18n.map["stream.dashboardViewer.sendActivation"]}</Text>
                </Touchable>
              )
            }
          </>
        ) : ( // 已激活
          <>
            <View style={styles.activated}>
              <Text style={styles.activatedText}>{i18n.map["stream.dashboardViewer.status.active"]}</Text>
            </View>
            {!!lastViewedTime &&
              <Text style={styles.activeTime}>{i18n.map["stream.dashboardViewer.lastUsed"]}: {moment(lastViewedTime).format("L LTS")}</Text>
            }
            <Text style={styles.activeTime}>{i18n.map["stream.dashboardViewer.activatedBy"].replace("%s", `${activatorPhoneNumber} (${viewerCompanyName})`)}</Text>
          </>
        )
      }
      {
        !showOverview ? (
          <Touchable
            style={styles.previewWrap}
            onPress={() => setShowOverview(true)}
          >
            <Image source={{ uri: resources.expaneBlue }} style={styles.previewIcon} />
            <Text style={styles.previewText}>{i18n.map.view_performance__21}</Text>
          </Touchable>
        ) : (
          <>
            <View style={{ paddingVertical: 12, backgroundColor: "white", width: "100%" }} >
              <View style={{ height: 0.5, backgroundColor: "rgba(0,0,0,.15)" }} />
            </View>
            {
              isLoadingOverview ? (
                <SkeletonBlockSupplierOverview
                  containerStyle={{
                    marginBottom: permission.showSupplierDebt ? 24 : 0,
                  }}
                />
              ) : (
                <>
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldLeft}>
                      <Text style={styles.fieldTitle}>{i18n.map["dataSource.goodsInventorySales.netSales"]}</Text>
                    </View>
                    <Text style={styles.fieldValue}>{renderOverviewValueText("netSales")}</Text>
                  </View>
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldLeft}>
                      <Text style={styles.fieldTitle}>{i18n.map["dataSource.goodsInventorySales.payable"]}</Text>
                      <Touchable onPress={() => showTip(i18n.map["dataSource.goodsInventorySales.payable.tip"])}>
                        <Image style={styles.fieldTip} source={{ uri: resources.tipCircle }} />
                      </Touchable>
                    </View>
                    <Text style={styles.fieldValue}>{renderOverviewValueText("payable")}</Text>
                  </View>
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldLeft}>
                      <Text style={styles.fieldTitle}>{i18n.map["dataSource.overview.totalStockValue"]}</Text>
                      <Touchable onPress={() => showTip(i18n.map["dataSource.overview.totalStockValue.tip"])}>
                        <Image style={styles.fieldTip} source={{ uri: resources.tipCircle }} />
                      </Touchable>
                    </View>
                    <Text style={styles.fieldValue}>{renderOverviewValueText("totalStockValue")}</Text>
                  </View>
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldLeft}>
                      <Text style={styles.fieldTitle}>{i18n.map["dataSource.overview.totalStock"]}</Text>
                    </View>
                    <Text style={styles.fieldValue}>{renderOverviewValueText("totalStock")}</Text>
                  </View>
                  {
                    permission.showSupplierDebt && (
                      <View style={styles.fieldRow}>
                        <View style={styles.fieldLeft}>
                          <Text style={styles.fieldTitle}>{i18n.map["dataSource.overview.arrears"]}</Text>
                          <Touchable onPress={() => showTip(i18n.map["dataSource.overview.arrears.tip"])}>
                            <Image style={styles.fieldTip} source={{ uri: resources.tipCircle }} />
                          </Touchable>
                        </View>
                        <Text style={styles.fieldValue}>{renderOverviewValueText("arrears")}</Text>
                      </View>
                    )
                  }
                  <View style={styles.fieldRow}>
                    <View style={styles.fieldLeft}>
                      <Text style={styles.fieldTitle}>{i18n.map["cmn.turnover"]}</Text>
                      <Touchable onPress={() => showTip(i18n.map["cmn.turnover.tip"])}>
                        <Image style={styles.fieldTip} source={{ uri: resources.tipCircle }} />
                      </Touchable>
                    </View>
                    <Text style={styles.fieldValue}>{renderOverviewValueText("turnover")}</Text>
                  </View>
                  <View style={[styles.fieldRow, { marginBottom: 0 }]}>
                    <View style={styles.fieldLeft}>
                      <Text style={styles.fieldTitle}>{i18n.map["cmn.margin"]}</Text>
                      <Touchable onPress={() => showTip(i18n.map["cmn.margin.tip"])}>
                        <Image style={styles.fieldTip} source={{ uri: resources.tipCircle }} />
                      </Touchable>
                    </View>
                    <Text style={styles.fieldValue}>{renderOverviewValueText("margin")}</Text>
                  </View>
                </>
              )
            }
          </>
        )
      }
      <Touchable
        style={styles.previewWrap}
        onPress={handlePreviewUpstreamDashboard}
      >
        <Image source={{ uri: resources.eyeBlue }} style={styles.previewIcon} />
        <Text style={styles.previewText}>{i18n.map[`stream.dashboardViewer.preview.target`]}</Text>
      </Touchable>
      {
        permission.isAdmin && (
          <TouchableWithPosition
            style={styles.more}
            onPress={showMoreMenu}
          >
            <Image
              source={{ uri: resources.navMore }}
              style={styles.moreIcon}
            />
          </TouchableWithPosition>
        )
      }
    </View>
  );
});

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 4,
    overflow: "hidden",
    alignItems: "flex-start",
  },
  name: {
    fontSize: 15,
    lineHeight: 19,
    fontWeight: "600",
    color: "#333333",
  },
  pending: {
    fontSize: 12,
    lineHeight: 15,
    color: "#9B9B9B",
    marginTop: 4,
  },
  sendLink: {
    borderRadius: 4,
    backgroundColor: "#0076FF",
    paddingHorizontal: 8,
    paddingVertical: 3,
    marginTop: 10,
  },
  sendLinkText: {
    fontSize: 14,
    lineHeight: 20,
    color: "#fff",
  },
  activated: {
    borderColor: "#2BBC1B",
    borderWidth: 0.8,
    borderRadius: 2,
    paddingHorizontal: 5,
    paddingVertical: 2.5,
    marginTop: 6,
    marginBottom: 4,
  },
  activatedText: {
    fontSize: 10,
    lineHeight: 12,
    color: "#27B518",
  },
  activeTime: {
    fontSize: 11,
    lineHeight: 13,
    color: "#9B9B9B",
    marginTop: 4,
  },
  previewWrap: {
    flexDirection: "row",
    marginTop: 10,
  },
  previewIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
  previewText: {
    color: "#0076FF",
    fontSize: 14,
    lineHeight: 16,
  },
  more: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  moreIcon: {
    width: 30,
    height: 30,
  },
  fieldRow: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  fieldLeft: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  fieldTitle: {
    fontSize: 13,
    lineHeight: 16,
    color: "#767676",
  },
  fieldTip: {
    width: 15,
    height: 15,
    marginLeft: 4,
    marginTop: 1,
  },
  fieldValue: {
    fontSize: 13,
    lineHeight: 16,
    color: "#333",
  },

});

export default SupplierCard;
