const colorList = ["#FF9136","#FFC501","#F09199","#BF730F","#446BFF","#47A4F9","#29D797","#55BD09","#7B55FF","#B84EB5","#3DC7F4","#6D7696","#12B46A" ];
let len = colorList.length;
/**
 * 默认头像背景颜色
 * @param {Number} id
 * @returns :String
 */
export function getCustomerDefaultAvatorColor(id){
  return colorList[id % len || 0];
}
