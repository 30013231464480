import React from "react";
import { View, Dimensions, Image as RNImage } from "react-native";
import { Theme, Teaset } from "ui-m/rn";
// import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm";
import PhotoSwipe from "photoswipe/dist/photoswipe.esm";
import { getState } from "../utils/globalUtils";
import ScreenLoading from "./ScreenLoading";

const getImageDimension_RN = url => new Promise(resolve => (
  RNImage.getSize(
    url,
    (width, height) => resolve([width, height]),
    () => resolve([0, 0])
  )
));

// const getImageDimension_Web = url => new Promise(resolve => {
//   const img = new Image();
//   img.src = url;
//   img.onload = function () {
//     resolve([img.width, img.height]);
//   };
// });

const getPcImageView = url => {
  const { width, height } = Dimensions.get('window');

  const style = [
    "object-fit: contain;",
    "position: absolute;",
    "top: 50%;",
    "left: 50%;",
    "transform: translate(-50%, -50%);",
    "width: auto;",
    "height: auto;",
    `max-width: ${Math.min(1200, width - 272)}px;`,
    `max-height: ${Math.min(850, height - 200)}px;`,
  ];

  return (
    `<img src="${url}" style="${style.join(" ")}" />`
  );
};


const getLightBoxImageOptions = async image => {

  const src = typeof image === "string" ? image : image.src;
  const options = {};

  if (Theme.isPhoneSizedWindow) {
    options.src = src;
    const [width, height] = await getImageDimension_RN(src);
    options.width = width;
    options.height = height;
    options.tapAction = "close";
  } else {
    // pc无需缩放
    options.html = getPcImageView(src);
  }

  return options;
};

const pcDefaultOptions = {};

const phoneDefaultOptions = {
  tapAction: "close",
};

const show = async (images = [], index = 0, options = {}) => {

  const isPhone = Theme.isPhoneSizedWindow;
  options = {
    ...(isPhone ? phoneDefaultOptions : pcDefaultOptions),
    ...options,
  };

  isPhone && ScreenLoading.show();
  const dataSource = await Promise.all(images.map(getLightBoxImageOptions));
  isPhone && ScreenLoading.hide();

  const pswp = new PhotoSwipe({
    ...options,
    dataSource,
    index,
  });

  pswp.init();
};

const previewDashboard = () => {
  const { i18n } = getState();
  const imagesText = i18n.map["stream.dashboard.preview.images"] || "";
  show(imagesText ? imagesText.split("#") : []);
};

const LightBox = {
  show,
  previewDashboard,
};

export default LightBox;
