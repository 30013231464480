import _ from 'lodash';
import React from 'react';
import { BackHandler, Dimensions, View, Text, Image, Linking } from 'react-native';
import { Theme, WindowSizeResponsive } from 'ui-m/rn';
import Footer from './Footer';

const setVh = () => document.documentElement.style.setProperty("--vh", `${window.innerHeight / 100}px`);

setVh();

WindowSizeResponsive.addListener(setVh);

export default (props)=>{
  const {
    siblingsHeightTotal = 0,
    forceDisplayFooter = false,
    style,
    contentStyle,
  } = props;

  return <View style={contentStyle}>
    <View style={[{
      padding: Theme.isPhoneSizedWindow ? 0 : 24,
      minHeight: `calc(var(--vh) * 100 - ${siblingsHeightTotal + Footer.height}px)`,
      background: "transparent",
    }, style]}>
      {props.children}
    </View>
    {
      // 手机默认不展示 Footer，只有 PC 有需求。登录页等处强制展示 Footer
      (Theme.isPhoneSizedWindow && !forceDisplayFooter) ? null : (
        <Footer/>
      )
    }
  </View>;
};
